import createAction from "../helpers/createAction";
import SiteOverrideAPI from "./api/siteOverride";
import wrapper from "../helpers/wrapper";

export enum Types {
    MAINTENANCE_RECEIVED = "MAINTENANCE_RECEIVED"
}

export const setMaintenanceReceived = createAction(Types.MAINTENANCE_RECEIVED);

export const getMaintenance = wrapper(SiteOverrideAPI.getMaintenance, {
    action: setMaintenanceReceived
})

export const startMaintenance = wrapper(SiteOverrideAPI.startMaintenance, {
    action: setMaintenanceReceived
})

export const stopMaintenance = wrapper(SiteOverrideAPI.stopMaintenance, {
    action: setMaintenanceReceived
})
