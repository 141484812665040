import React, { Ref, useState } from "react";
import Dialog, { DialogRef, DialogProps } from "components/widgets/dialog/Dialog";
import Button from "components/widgets/button/Button";
import styles from "./createUserDialog.module.scss";
import * as Yup from "yup";
import { Formik } from "formik";
import FormikInput from "components/widgets/input/FormikInput";

import { UserCreateUpdateRequest, USER_ROLES } from "../../../../types/SafeswimUser";
import SafeswimUser from "../../../../types/SafeswimUser";
import { createUser, updateUser } from "../../../../redux/actions/users";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../redux/actions/snackbars";
import FormikCheckboxGroup from "components/widgets/formikFields/formikCheckboxGroup/FormikCheckboxGroup";

type Props = {
    title: string;
    loading?: boolean;
    user?: SafeswimUser | null;
    availableRegionBased?: string[] | null
    onClose?: () => void;
} & DialogProps;

const permissionOptions: { label: string, value: string }[] = [
    { value: USER_ROLES.SUPER_ADMIN, label: "Super Admin" },
    { value: USER_ROLES.REGIONAL_ADMIN, label: "Regional Admin" },
    { value: USER_ROLES.SLS, label: "Surf Lifesaving" },
    { value: USER_ROLES.WATERCARE, label: "Watercare" },
    { value: USER_ROLES.NO_LOGIN, label: "Built-in (no login)" },
    { value: USER_ROLES.DS_ADMIN, label: "Digital-sign Admin" },
    { value: USER_ROLES.USER, label: "User" },
];

function CreateUserDialog(props: Props, ref: Ref<DialogRef>) {
    const {
        title,
        positiveText,
        onPositivePress,
        positiveButtonProps,
        negativeText,
        onNegativePress,
        negativeButtonProps,
        user,
        availableRegionBased,
        onClose,
        ...otherProps
    } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const initialValues: UserCreateUpdateRequest = {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        password: "",
        role: user?.role,
        assignedRegions: user?.assignedRegions,
    };
    
    const REGION_BASED_OPTS = availableRegionBased?.map(v => ({
        label: v,
        value: v
    }))??[]

    const onSubmit = async (values: UserCreateUpdateRequest) => {
        setLoading(true);
        try {
            if (user) {
                await dispatch(updateUser(user.id, values));
                dispatch(showSuccess("User successfully updated"));
            } else {
                await dispatch(createUser(values));
                dispatch(showSuccess("User successfully created"));
            }
        } catch (e:any) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
            onClose?.();
        }
    };


    return (
        <Dialog {...otherProps}
                disableBackdropClick={loading}
                disableEscapeKeyDown={loading}
                ref={ref}
                className={styles.dialog}>
            <h2 className={styles.title}>{title}</h2>
            <hr />
            <div className={styles.dialog_content}>

                <Formik<UserCreateUpdateRequest> initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={Yup.object({
                            firstName: Yup.string().required("Required"),
                            lastName: Yup.string().required("Required"),
                            email: Yup.string().required("Required"),
                            role: Yup.string().required("Required"),
                        })}
                        enableReinitialize>
                    {({ handleSubmit, values }) => {
                        const isRegionalAdmin = values.role === USER_ROLES.REGIONAL_ADMIN;
                        return (
                            <>
                                <FormikInput name={"firstName"} label={"First Name"} className={styles.full_input} />
                                <FormikInput name={"lastName"} label={"Last Name"} className={styles.full_input} />
                                <FormikInput name={"email"} label={"Email address"}
                                             className={styles.full_input} />
                                <FormikInput name={"role"} type={"select"} label={"Permission role"}
                                             className={styles.full_input} options={permissionOptions} />
                                {isRegionalAdmin && <FormikCheckboxGroup    name="assignedRegions"
                                                        label="Assigned regions"
                                                        type={"checkbox"}
                                                        containerClassName={styles.full_input}
                                                        values={REGION_BASED_OPTS}
                                />}
                                <Button variant={"contained"} loading={loading} disabled={loading}
                                        onClick={(e: any) => handleSubmit(e)}
                                        {...positiveButtonProps}>{positiveText}</Button>
                                <Button variant={"text"} disabled={loading}
                                        onClick={(e: any) => {
                                            onNegativePress?.(e);
                                        }}
                                        {...negativeButtonProps}>
                                    {negativeText}
                                </Button>
                            </>
                        );
                    }}

                </Formik>
            </div>

        </Dialog>
    );
}

export default React.forwardRef(CreateUserDialog);
