import createAction from "../helpers/createAction";
import LocationAPI from "./api/Locations";
import wrapper from "../helpers/wrapper";
import H2Location from "../../types/H2Location";
import DigitalSignDTO from "../../types/DigitalSignDTO";
import SafeswimLocation from "../../types/SafeswimLocation";

export enum Types {
    LOCATION_RECEIVED = "LOCATION_RECEIVED",
    LOCATIONS_RECEIVED = "LOCATIONS_RECEIVED",
    H2_LOCATIONS_RECEIVED = "H2_LOCATIONS_RECEIVED",
    SIGNS_RECEIVED = "SIGNS_RECEIVED",
    REGION_BASED_RECEIVED = 'REGION_BASED_RECEIVED',
}

export const setLocationReceived = createAction<SafeswimLocation>(Types.LOCATION_RECEIVED);
export const setLocationsReceived = createAction<SafeswimLocation[]>(Types.LOCATIONS_RECEIVED);
export const setH2LocationsReceived = createAction<H2Location[]>(Types.H2_LOCATIONS_RECEIVED);
export const setDigitalSignsReceived = createAction<DigitalSignDTO[]>(Types.SIGNS_RECEIVED);
export const setRegionBasedReceived = createAction<string[]>(Types.REGION_BASED_RECEIVED);

const actions = {
    [Types.LOCATION_RECEIVED]: setLocationReceived,
    [Types.LOCATIONS_RECEIVED]: setLocationsReceived,
    [Types.H2_LOCATIONS_RECEIVED]: setH2LocationsReceived,
    [Types.SIGNS_RECEIVED]: setDigitalSignsReceived,
    [Types.REGION_BASED_RECEIVED]: setRegionBasedReceived,
}

export type Actions = typeof actions;

export const getLocation = wrapper(LocationAPI.getLocation, {
    action: setLocationReceived
})

export const getLocations = wrapper(LocationAPI.getLocations, {
    action: setLocationsReceived
})

export const createLocation = wrapper(LocationAPI.createLocation, {
    action: setLocationsReceived
})

export const updateLocation = wrapper(LocationAPI.updateLocation, {
    action: setLocationsReceived
})

export const updateLocations = wrapper(LocationAPI.updateLocations, {
    action: setLocationsReceived
})

export const deleteLocation = wrapper(LocationAPI.deleteLocation, {
    action: setLocationsReceived
})

export const setLocationAvailable = wrapper(LocationAPI.setLocationAvailable, {
    action: setLocationsReceived
})

export const setLocationUnavailable = wrapper(LocationAPI.setLocationUnavailable, {
    action: setLocationsReceived
})

export const getH2Locations = wrapper(LocationAPI.getH2Locations, {
    action: setH2LocationsReceived
})

export const getDigitalSigns = wrapper(LocationAPI.getDigitalSigns, {
    action: setDigitalSignsReceived
})

export const createOrUpdateDigitalSign = wrapper(LocationAPI.createOrUpdateDigitalSign, {
    action: setDigitalSignsReceived
})

export const deleteDigitalSign = wrapper(LocationAPI.deleteDigitalSign, {
    action: setDigitalSignsReceived
})

export const updateSLSPatrolHours = wrapper(LocationAPI.updateSLSPatrolHours, {
    action: setLocationsReceived
})

export const getAvailableRegionBased = wrapper(LocationAPI.getAvailableRegionBased, {
    action: setRegionBasedReceived
})
