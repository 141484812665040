import { Types } from "../actions/siteOverride";

const initialState = {};

export default function siteOverride(state = initialState, action: any) {
    switch (action.type) {
        case Types.MAINTENANCE_RECEIVED: {
            return action.payload;
        }

        default: {
            return state;
        }
    }
}