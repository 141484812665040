import createAction from "../helpers/createAction";
import wrapper from "../helpers/wrapper";
import UsersAPI from "./api/Users";

export enum Types {
    LOCAL_USER_RECEIVED = "LOCAL_USER_RECEIVED",
    LOG_OUT = "LOG_OUT",
}

const setLocalUserReceived = createAction(Types.LOCAL_USER_RECEIVED);

export const login = wrapper(UsersAPI.getUser, {
    action: setLocalUserReceived
})

export const logout = createAction(Types.LOG_OUT);