import get from "./util/get";
import post from "./util/post";
import del from "./util/del";
import put from "./util/put";
import AdminAPIKey from "../../../types/AdminAPIKey";

function getApiKeys(): Promise<AdminAPIKey[]> {
    return get("v1/api");
}

function getUserApiKeys(userId: number): Promise<AdminAPIKey[]> {
    return get(`v1/api/${userId}`);
}

function generateApiKey(): Promise<AdminAPIKey[]> {
    return post("v1/api");
}

function generateApiKeyForUser(userId: number): Promise<AdminAPIKey[]> {
    return post(`/v1/api/user/${userId}`);
}

function deleteApiKey(keyId: number): Promise<AdminAPIKey[]> {
    return del(`v1/api/${keyId}`);
}

function blockApiKey(keyId: number): Promise<AdminAPIKey> {
    return put(`v1/api/${keyId}`);
}

const ApiKeysAPI = {
    getApiKeys,
    getUserApiKeys,
    generateApiKey,
    generateApiKeyForUser,
    deleteApiKey,
    blockApiKey
}

export default ApiKeysAPI;