import get from "./util/get";
import { PatrolHour } from "../../../types/PatrolHour";
import put from "./util/put";
import del from "./util/del";
import post from "./util/post";

export async function getPatrolHours(locationId: number) {
    return get(`v1/admin/locations/${locationId}/patrols`);
}

export async function uploadPatrolHours(locationId: number, formData: FormData) {
    return post(`v1/admin/locations/${locationId}/patrols/import`, formData);
}

export async function updatePatrolHours(locationId: number, patrols: PatrolHour[]) {
    return put(`v1/admin/locations/${locationId}/patrols`, patrols);
}

export async function deletePatrolHours(locationId: number, patrolId: number) {
    return del(`v1/admin/locations/${locationId}/patrols/${patrolId}`);
}

export async function deleteAllPatrols(locationId: number) {
    return del(`v1/admin/locations/${locationId}/patrols`);
}
