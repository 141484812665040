export default interface SafeswimUser {
    id: string;
    email: string;
    status: USER_STATUS;
    role: USER_ROLES;
    lastName: string;
    firstName: string;
    favouriteBeaches: number[];
    notifications: any[];
    messagingTokens: any[];
    canSaveActivity: boolean;
    canSendNotifications: boolean;
    canSendEmailNotifications: boolean;
    password: string;
    assignedRegions?: string[];
}

export enum USER_STATUS {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    DELETED = "DELETED",
}

export enum USER_ROLES {
    USER = "USER",
    NO_LOGIN = "NO_LOGIN",
    SLS = "SLS",
    WATERCARE = "WATERCARE",
    SUPER_ADMIN = "SUPER_ADMIN",
    REGIONAL_ADMIN = "REGIONAL_ADMIN",
    DS_ADMIN = 'DS_ADMIN',
}

export interface UserCreateUpdateRequest {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    role?: USER_ROLES;
    assignedRegions?: string[];
}
