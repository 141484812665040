
export const $BLUE_DARK = "#00304b";
export const $BLUE_LIGHT = "#027CBA";

export const $GREEN_LIME = "#70b016";
export const $GREEN_DARK = "#5F9612";

export const $RED = "#E81B1B";
export const $RED_DARK = "#CF1919";

export const $GREY = "#E0E0E0";
export const $GREY_LIGHT = "#EEEEEE";
export const $GREY_DARK = "#666666";

export const $WHITE = "#FFFFFF";