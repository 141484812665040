import MuiDialog, { DialogProps as MuiDialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import React, { ReactNode, Ref, useCallback, useImperativeHandle, useState } from "react";
import Button, { ButtonProps } from "../button/Button";
import styles from "./Dialog.module.scss";

export type DialogProps = {
    children: ReactNode;

    className?: string;
    containerClassName?: string;

    initialShown?: boolean;
    onClose?: () => void;

    title?: string;

    closeOnPositive?: boolean;
    closeOnNegative?: boolean;
    closeOnNeutral?: boolean;

    positiveText?: string;
    positiveButtonProps?: ButtonProps;
    onPositivePress?: (e: React.MouseEvent<HTMLButtonElement>) => void;

    negativeText?: string;
    negativeButtonProps?: ButtonProps;
    onNegativePress?: (e: React.MouseEvent<HTMLButtonElement>) => void;

    neutralText?: string;
    neutralButtonProps?: ButtonProps;
    onNeutralPress?: (e: React.MouseEvent<HTMLButtonElement>) => void;

} & Omit<MuiDialogProps, "open">;

export type DialogRef = {
    show: () => void;
    hide: () => void;
};

function Dialog(props: DialogProps, ref: Ref<DialogRef>) {
    const {
        children,
        title,

        closeOnPositive,
        closeOnNegative,
        closeOnNeutral,

        positiveText,
        positiveButtonProps,

        negativeText,
        negativeButtonProps,

        neutralText,
        neutralButtonProps,

        initialShown,
        onPositivePress: propsOnPositivePress,
        onNegativePress: propsOnNegativePress,
        onNeutralPress: propsOnNeutralPress,
        onClose: propsOnClose,
    } = props;

    const [shown, setShown] = useState(initialShown || false);

    const onPositivePress = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        if (closeOnPositive) {
            setShown(false);
        }
        propsOnPositivePress?.(e);
    }, [closeOnPositive, propsOnPositivePress]);

    const onNegativePress = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        if (closeOnNegative) {
            setShown(false);
        }
        propsOnNegativePress?.(e);
    }, [closeOnNegative, propsOnNegativePress]);

    const onNeutralPress = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        if (closeOnNeutral) {
            setShown(false);
        }
        propsOnNeutralPress?.(e);
    }, [closeOnNeutral, propsOnNeutralPress]);

    const onClose = useCallback(() => {
        setShown(false);
        propsOnClose?.();
    }, [propsOnClose]);

    useImperativeHandle(ref, () => ({
        show: () => {
            setShown(true);
        },
        hide: () => {
            setShown(false);
        },
    }));

    const container = classNames(styles.dialog_container, props.containerClassName);
    const className = classNames(styles.dialog, props.className);
    return (
        <MuiDialog
            className={container}
            classes={{
                paper: className,
            }}
            open={shown}
            onClose={onClose}>
            {title && <DialogTitle className={styles.title}>{title}</DialogTitle>}
            {(children) && (
                <DialogContent className={styles.content}>
                    {children}
                </DialogContent>
            )}
            {(positiveText || negativeText || neutralText) && (
                <DialogActions className={styles.buttons}>
                    {neutralText ? (
                        <Button {...neutralButtonProps} onClick={onNeutralPress} plain>
                            {neutralText}
                        </Button>
                    ) : (
                        <div />
                    )}
                    {(positiveText || negativeText) && (
                        <div className={styles.buttonsRight}>
                            {negativeText && (
                                <Button {...negativeButtonProps} onClick={onNegativePress}>
                                    {negativeText}
                                </Button>
                            )}
                            {positiveText && (
                                <Button {...positiveButtonProps} onClick={onPositivePress}>
                                    {positiveText}
                                </Button>
                            )}
                        </div>
                    )}
                </DialogActions>
            )}
        </MuiDialog>
    );
}

export default React.forwardRef(Dialog);
