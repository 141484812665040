import React, { useState } from "react";
import FileUpload, { FileUploadProps } from "../../fileUpload/FileUpload";
import { useField, useFormikContext } from "formik";
import styles from "./formikFileUpload.module.scss";
import Button from "components/widgets/button/Button";
import firebase from 'firebase/app';
import 'firebase/storage';
import { v4 as uuid } from "uuid";

type Props = {
    name: string;
    multiple?: boolean;
} & FileUploadProps;

export default function FormikFileUpload(props: Props) {
    const { name, multiple, ...otherProps } = props;
    const [loading, setLoading] = useState<boolean>();

    const [field, meta, helpers] = useField(name);
    const { isSubmitting } = useFormikContext();

    const fieldError = meta.error;
    const displayError = meta.touched && !!fieldError;
    const disabled = props.disabled || isSubmitting;

    const storage = firebase.storage().ref();

    const onDelete = (url: string) => () => {
        if (!multiple) {
            return helpers.setValue("");
        }
        const index = field.value?.indexOf(url);
        const values = [...field.value];
        values.splice(index, 1);
        helpers.setValue(values);
    };

    const onChange = async (files: File[] = []) => {
        try {
            setLoading(true);
            const uploadTasks: Array<firebase.storage.UploadTask> = [];
            for (let i = 0; i < files.length; i++) {
                const fileRef = storage.child(`images/${uuid()}`);
                const file = fileRef.put(files[i]);
                uploadTasks.push(file);
            }
            const urls: string[] = [];
            for (const task of uploadTasks) {
                const url = await upload(task);
                urls.push(url);
            }
            if (!multiple) {
                helpers.setValue(urls[0]);
            } else if (field?.value?.length) {
                helpers.setValue([...field?.value, ...urls]);
            } else {
                helpers.setValue(urls);
            }
        } catch (err) {
            console.log("err", err);
        } finally {
            setLoading(false);
        }

        props.onChange?.(files);
    };

    const renderPreview = () => {
        if (!field.value) {
            return null;
        }

        if (multiple) {
            return (
                <div className={styles.preview_container}>
                    {field.value?.map((url: string) => (
                        <div key={url} style={{ backgroundImage: `url(${url})` }} className={styles.preview_image}>
                            <Button red className={styles.remove_button} onClick={onDelete(url)}>Remove</Button>
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div className={styles.preview_container}>
                <div key={field.value} style={{ backgroundImage: `url(${field.value})` }}
                    className={styles.preview_image}>
                    <Button red className={styles.remove_button} onClick={onDelete(field.value)}>Remove</Button>
                </div>
            </div>
        );
    };

    return (
        <FileUpload {...otherProps}
            {...field}
            loading={loading}
            disabled={disabled}
            helperText={fieldError}
            showError={displayError}
            preview={renderPreview()}
            onChange={onChange}
        />
    );
}

function upload(task: firebase.storage.UploadTask): Promise<string> {
    return new Promise((res, rej) => {
        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            null,
            rej,
            () => task.snapshot.ref.getDownloadURL().then(res).catch(rej)
        )
    });
}
