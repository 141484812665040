import createAction from "../helpers/createAction";
import Hazard from "../../types/Hazard";
import ListResult from "../../types/ListResult";
import wrapper from "../helpers/wrapper";
import HazardsAPI from "./api/Hazards";

export enum Types {
    HAZARD_RECEIVED = "HAZARD_RECEIVED",
    HAZARD_DELETED = "HAZARD_DELETED",
    HAZARDS_RECEIVED = "HAZARDS_RECEIVED",
}

export const setHazardReceived = createAction<Hazard>(Types.HAZARD_RECEIVED);
export const setNewHazardsReceived = createAction<ListResult<Hazard>>(Types.HAZARD_DELETED);
export const setHazardsReceived = createAction<ListResult<Hazard>>(Types.HAZARDS_RECEIVED);

export const getHazard = wrapper(HazardsAPI.getHazard, {
    action: setHazardReceived
})

export const getHazards = wrapper(HazardsAPI.getHazards, {
    action: setHazardsReceived
})

export const createHazard = wrapper(HazardsAPI.createHazard, {
    action: setHazardReceived
})

export const updateHazard = wrapper(HazardsAPI.updateHazard, {
    action: setHazardReceived
})

export const deleteHazard = wrapper(HazardsAPI.deleteHazard, {
    action: setNewHazardsReceived
})
