import React from "react";
import classNames from "classnames";
import councilLogo from "../../../resources/images/council-logo.jpg";
import firebase from "firebase/app";
import "firebase/auth";
import { useHistory } from "react-router-dom";
import styles from "./navBar.module.scss";
import { MenuItem } from "@material-ui/core";
import routes from "../../../constants/routes";
import { NavItem } from "../pageContainer/PageContainer";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/actions/auth";
import useSelector from "../../../hooks/useSelector";

export default function NavBar() {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const menuItems = [
        {
            text: "Locations",
            path: routes.locations.root,
            permissions: routes.locations.permissions,
        },
        {
            text: "Alerts",
            path: routes.alerts.root,
            permissions: routes.alerts.permissions,
        },
        // { text: "Events", path: routes.events.root, permissions: routes.events.permissions },
        {
            text: "Surf Lifesaving",
            path: routes.sls.root,
            permissions: routes.sls.permissions,
        },
        {
            text: "Admin Settings",
            path: routes.admin.root,
            permissions: routes.admin.permissions,
        },
        {
            text: "Events",
            path: routes.events.root,
            permissions: routes.admin.permissions,
        },
    ];

    const handleLogOut = async () => {
        try {
            await firebase.auth().signOut();
            dispatch(logout({}));
            history.push("/");
        } catch (e) {
            console.log("sign out error", e);
        }
    };

    const navigateTo = (path: string) => () => {
        history.push(path);
    };

    const renderMenuItem = (item: NavItem) => {
        if (user && !item?.permissions?.includes(user.role)) {
            return null;
        }

        return (
            <MenuItem
                classes={{ root: styles.link }}
                onClick={navigateTo(item.path)}
                key={item.path}
            >
                {item.text}
            </MenuItem>
        );
    };

    return (
        <div className={styles.container}>
            <a href={"https://safeswim.org.nz"}>
                <img src={councilLogo} alt={""} />
            </a>
            <nav
                role={"navigation"}
                className={classNames(styles.nav_menu, styles.w_nav_menu)}
            >
                {firebase.auth().currentUser && user && (
                    <>
                        {menuItems.map(renderMenuItem)}
                        <MenuItem
                            classes={{ root: styles.link }}
                            onClick={handleLogOut}
                        >
                            Log out
                        </MenuItem>
                    </>
                )}
            </nav>
        </div>
    );
}
