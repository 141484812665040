import { FormControlLabel, FormControlLabelProps } from "@material-ui/core";
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@material-ui/core/Checkbox";
import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./Checkbox.module.scss";

export type CheckboxProps = {
    label?: ReactNode;
    labelClassName?: string;
    labelProps?: Omit<FormControlLabelProps, "control" | "label">;
} & Omit<MuiCheckboxProps, "name" | "error">;

export default function Checkbox(props: CheckboxProps) {
    const { label, labelProps, disabled, ...otherProps } = props;
    const className = classNames(styles.check_box, props.className);
    const labelClassName = classNames(styles.check_box_container, props.labelClassName);
    return (
        <FormControlLabel
            {...(labelProps || {})}
            className={labelClassName}
            control={<MuiCheckbox {...otherProps} className={className} />}
            label={label}
            disabled={disabled}
        />
    );
}
