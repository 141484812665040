import React from 'react';
import PageContainer from "../widgets/pageContainer/PageContainer";
import routes from "../../constants/routes";
import { Redirect, Route, Switch } from 'react-router-dom';
import EventsOverview from "./EventsOverview";
import CreateUpdateEvent from "./CreateUpdateEvent";

export default function Events() {
    return (
        <PageContainer navItems={[
            { text: "Events Overview", path: routes.events.overview },
            {text: "Past & deleted events", path: routes.events.deleted}
        ]}>
            <Switch>
                <Route exact path={routes.events.overview} component={EventsOverview} />
                <Route exact path={routes.events.deleted} component={EventsOverview} />
                <Route exact path={routes.events.create} component={CreateUpdateEvent} />
                <Redirect from={routes.events.root} to={routes.events.overview} />
            </Switch>
        </PageContainer>
    );
}
