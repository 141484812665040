import ListResult from "types/ListResult";
import SafeswimChange from "types/SafeswimChange";
import get from "./util/get";

function getChangesByEntity(entity: string, cursor?: string, limit?: number): Promise<ListResult<SafeswimChange>> {
    return get(`v1/admin/changes/${entity}`, { params: { limit, cursor } });
}

const ChangesAPI = {
    getChangesByEntity
}

export default ChangesAPI;