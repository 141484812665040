import ListResult from "types/ListResult";
import SafeswimChange from "types/SafeswimChange"
import { Types } from "../actions/changes";

type ChangeType = {
    items: { [ entity: string ] : SafeswimChange[] },
    cursor?: string | null
    
}
const initialState: ChangeType = {
    items: {},
};

export default function changes(state = initialState, action: {type: Types, payload: any}) {
    switch (action.type) {
        case Types.SET_CHANGES: {
            const payload = action.payload as ListResult<SafeswimChange>;
            const {items, cursor} = payload;
            const newState = { ...state };
            items.forEach(c => {
                const stateItems = newState.items[c.entity];
                if (!stateItems) {
                    newState.items[c.entity] = [c];
                } else {
                    if (!stateItems.map(item => item.id).includes(c.id)) {
                        newState.items[c.entity].push(c);
                    }
                }
            });
            newState.cursor = cursor;
            return newState;
        }
        default:
            return state;
    }
}