import MuiPopover, { PopoverProps } from "@material-ui/core/Popover";
import React, { Ref, useCallback, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import styles from "./Popover.module.scss";

type Props = {
    popoverRef: Ref<PopoverRef>;
    initialShown?: boolean;
} & Omit<PopoverProps, "open">;

type AnchorElement = null | Element | ((element: Element) => Element);

export type PopoverRef = {
    show: (element?: AnchorElement) => void;
    hide: () => void;
}

export default function Popover(props: Props) {
    const { popoverRef, anchorEl, ...otherProps } = props;

    const [state, setState] = useState<{
        shown: boolean;
        element?: AnchorElement | null;
    }>({
        shown: false,
        element: null,
    });

    const show = useCallback((element?: AnchorElement) => {
        setState({
            shown: true,
            element,
        });
    }, [setState]);

    const hide = useCallback(() => {
        setState({
            shown: false,
            element: null,
        });
    }, [setState]);

    const onClose = useCallback(() => {
        hide();
    }, [hide]);

    useImperativeHandle(popoverRef, () => ({
        show,
        hide,
    }));

    const { shown, element } = state;

    const className = classNames(styles.popover, props.className);

    return (
        <MuiPopover
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            {...otherProps}
            classes={{
                ...otherProps.classes,
                paper: className,
            }}
            open={shown}
            anchorEl={anchorEl || element}
            onClose={onClose}
        />
    );
}
