import { Provider } from "react-redux";
import ThemeProvider from "components/widgets/provider/Provider";
import Main from "./Main";
import { PersistGate } from "redux-persist/integration/react";
import Spinner from "components/widgets/spinner/Spinner";
import configureStore from "../redux/store";
import SnackbarContainer from "./widgets/snackbar/SnackbarContainer";

const { store, persistor } = configureStore();

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={<Spinner center/>} persistor={persistor}>
                <ThemeProvider>
                    <SnackbarContainer>
                        <Main/>
                    </SnackbarContainer>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    )
}
