import useFormikField, { FormikFieldExtraProps } from "hooks/useFormikField";
import React from "react";
import Checkbox, { CheckboxProps } from "./Checkbox";

export type FormikCheckboxProps = {
    name: string;
} & Omit<CheckboxProps, "name" | "value" | "error"> & FormikFieldExtraProps;

export default function FormikCheckbox(props: FormikCheckboxProps) {
    const { name, ...otherProps } = props;
    const { value, disabled, field } = useFormikField(props);

    const checked = !!value;
    const checkedValue = checked ? "checked" : "";

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, checkedVal: boolean) => {
        props.onChange?.(e, checkedVal);
        field.onChange(e);
    };

    return (
        <Checkbox {...otherProps}
                  {...field}
                  onChange={onChange}
                  checked={checked}
                  value={checkedValue}
                  disabled={disabled} />
    );
}
