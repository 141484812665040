import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useMountEffect from "../../../hooks/useMountEffect";
import { arrayMove, SortableContainer, SortableElement, SortEnd } from "react-sortable-hoc";
import styles from "../hazardOrdering/hazardOrdering.module.scss";
import arrowMovable from "../../../resources/images/arrowMoveable@2x.png";
import AlertType from "../../../types/AlertType";
import { Button, Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import { getAlertTypes, updateAllAlertTypes } from "../../../redux/actions/alerts";
import Spinner from "components/widgets/spinner/Spinner";
import { orderBy } from "lodash";

export default function HazardOrdering() {
    const dispatch = useDispatch();
    const [editable, setEditable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<AlertType[]>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [publicAlerts, setPublicAlerts] = useState<AlertType[]>([]);
    const [snapshot, setSnapshot] = useState<AlertType[]>([]);

    useMountEffect(async () => {
        setLoading(true);
        const alertTypes: any = await dispatch(getAlertTypes());
        const hazardTypes = alertTypes.filter((a: any) => a.alertType === "hazards");
        setAlerts(orderBy(hazardTypes, ["ordinal"]));
        setPublicAlerts(orderBy(hazardTypes, ["publicOrdinal"]));
        setLoading(false);
    });

    const SortableItem = SortableElement(((alert: AlertType) => <li
            className={(editable ? styles.sortable_row_editable : styles.sortable_row)}>
            {editable && (
                <img src={arrowMovable}
                     width={20}
                     style={{ marginRight: 10, alignSelf: "center" }}
                     alt={"Sorting element"} />
            )}
            <span className={styles.column_sml}>{alert.title}</span>
            <span className={styles.column_lrg}>{alert.description}</span>
        </li>
    ));

    const SortableList = SortableContainer(({ items }: { items: AlertType[] }) => {
        return (
            <ul className={styles.sortable_container}>
                {(items).map((alertType: AlertType, index: number) => {
                    return (
                        <SortableItem disabled={!editable}
                                      index={index} {...alertType}
                                      key={`${selectedTab === 0 ? "admin-" : "public-"}item-${alertType.id}`} />
                    );
                })}
            </ul>
        );
    });

    const onSortEnd = ((sort: SortEnd) => {
        if (selectedTab === 0) {
            const newAlerts = arrayMove(alerts, sort.oldIndex, sort.newIndex);
            setAlerts(newAlerts);
        } else {
            const newPublicAlerts = arrayMove(publicAlerts, sort.oldIndex, sort.newIndex);
            setPublicAlerts(newPublicAlerts);
        }
    });

    const onEditPress = () => {
        setEditable(true);
        if (selectedTab === 0) {
            setSnapshot(alerts);
        } else {
            setSnapshot(publicAlerts);
        }
    };

    const onSaveOrderPress = () => {
        if (selectedTab === 0) {
            const saveAlerts: any[] = alerts.map((alert: AlertType, i: number) => ({
                id: alert.id,
                ordinal: i,
                publicOrdinal: alert.publicOrdinal
            }));
            dispatch(updateAllAlertTypes(saveAlerts));
        } else {
            const savePublicAlerts: any[] = publicAlerts.map((alert: AlertType, i: number) => ({
                id: alert.id,
                ordinal: alert.ordinal,
                publicOrdinal: i
            }));
            dispatch(updateAllAlertTypes(savePublicAlerts));
        }
        setEditable(false);
    };

    const onCancelPress = () => {
        setEditable(false);
        if (selectedTab === 0) {
            setAlerts(snapshot);
        } else {
            setPublicAlerts(snapshot);
        }
    };

    if (loading || !alerts.length || !publicAlerts.length) {
        return <Spinner center />;
    }

    console.log(selectedTab);

    return (
        <div>
            <div className={styles.page_header}>
                <h1>Custom Hazard Alert Ordering</h1>

            </div>
            <Tabs className={styles.tabs}>
                <Tab label={"Admin panel"} textColor={"#00304b"} onClick={() => setSelectedTab(0)}
                     className={(selectedTab === 0 ? styles.tab_selected : styles.tab)} />
                <Tab label={"Front-end website"} textColor={"#00304b"} onClick={() => setSelectedTab(1)}
                     className={(selectedTab === 0 ? styles.tab_selected : styles.tab)} />
                <div style={{
                    width: 360,
                    borderBottomColor: "#E0E0E0",
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    alignSelf: "left"
                }} />
            </Tabs>

            <div className={styles.table_header}>
                <div className={styles.table_header_items}>
                    <span className={styles.column_header}>Title</span>
                    <span className={styles.column_header}>Description</span>
                </div>
                <div className={styles.table_buttons}>
                    {!editable && (
                        <Button onClick={onEditPress} style={{ marginLeft: 10, zIndex: 3 }} variant="contained">
                            Edit order
                        </Button>
                    )}
                    {editable && (
                        <div>
                            <Button variant={"text"} onClick={onCancelPress} style={{ margin: 10 }}>Cancel</Button>
                            <Button onClick={onSaveOrderPress} style={{ marginLeft: 10, }} variant="contained">
                                Save order
                            </Button>
                        </div>
                    )}
                </div>
            </div>

            {selectedTab === 0 && (
                <SortableList onSortEnd={((sort, event) => onSortEnd(sort))} items={alerts} />
            )}

            {selectedTab === 1 && (
                <SortableList onSortEnd={((sort, event) => onSortEnd(sort))} items={publicAlerts} />
            )}
        </div>
    );
}
