import { DataItem, Forecast } from "./Forecast";
import { Trace } from "./Trace";

export default interface SafeswimLocation {
    id: number;
    name: string;
    maoriName: string;
    description: string;
    region: string;
    address: string;
    lat: number;
    lng: number;
    tags: string[];
    hazards: string[];
    features: string[];
    isPermanent: boolean;
    permanentWaterQuality: number;
    images: string[];
    forecast: Forecast;
    traces: Trace[];
    unavailable: boolean;
    currentWaterQuality: DataItem;
    dogWalkingIds: string[];
    ordinal: number;
    regionBased: string;
    featuredImage?: string;
    creativeImage?: string;
    creativeImageTheme?: Theme;
}

export enum Theme {
    DARK = 'DARK',
    LIGHT = 'LIGHT',
}
