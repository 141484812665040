
import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import classNames from "classnames";
import React, { Ref } from "react";
import Spinner from "../spinner/Spinner";
import styles from "./Button.module.scss";

export type ButtonProps = {
    primary?: boolean;
    secondary?: boolean;
    red?: boolean;
    small?: boolean;
    large?: boolean;
    border?: boolean;
    plain?: boolean;
    loading?: boolean;
} & MuiButtonProps;


function Button(props: ButtonProps, ref: Ref<HTMLButtonElement | HTMLAnchorElement>) {
    const {
        className,
        primary,
        secondary,
        red,
        small,
        large,
        border,
        plain,
        loading,

        endIcon,
        ...otherProps
    } = props;
    let { size } = props;

    const buttonClassName = classNames(styles.button, {
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.red]: red,
        [styles.small]: small,
        [styles.large]: large,
        [styles.border]: border,
        [styles.plain]: plain,
        [styles.loading]: loading,
    }, className);

    if (small) {
        size = "small";
    }

    return (
        <MuiButton {...otherProps}
                   size={size}
                   buttonRef={ref}
                   endIcon={loading ? <Spinner small white={!plain} /> : endIcon}
                   className={buttonClassName} />
    );
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button);
