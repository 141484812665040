import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import qs from "qs";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "comma" });
    },
});

axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        const newConfig = { ...config };
        const token = await firebase.auth().currentUser?.getIdToken();
        if (token) {
            newConfig.headers.Authorization = token;
        }

        return config;
    },
    (e) => {
        return Promise.reject(e);
    }
);

export default axiosInstance;
