import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import useMountEffect from "../hooks/useMountEffect";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Login from "./login/Login";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import Locations from "./locations/Locations";
import { useDispatch } from "react-redux";
import NavBar from "./widgets/navBar/NavBar";
import Alerts from "./alerts/Alerts";
import Events from "./events/Events";
import SurfLifesaving from "./surfLifesaving/SurfLifesaving";
import Admin from "./admin/Admin";
import routes from "../constants/routes";
import { login, logout } from "../redux/actions/auth";
import useSelector from "../hooks/useSelector";
import Spinner from "components/widgets/spinner/Spinner";
import SafeswimUser, { USER_ROLES } from "../types/SafeswimUser";

export default function Main() {
    const dispatch = useDispatch();
    const user:SafeswimUser = useSelector(state => state.auth.user);

    useMountEffect(async () => {
        firebase.auth().onAuthStateChanged(onAuthStateChanged);
    });

    useEffect(() => {
        if (user && user.role === USER_ROLES.USER) {
            firebase.auth().signOut();
            dispatch(logout({}));
        }
    }, [user, dispatch]);

    const onAuthStateChanged = async (user: firebase.User | null) => {
        if (user) {
            try {
                await dispatch(login(user?.uid));
            } catch (e) {
                console.log("user error", e);
            }
        } else {
            dispatch(logout(null));
        }
    };

    const renderAnonRoutes = () => (
        <Switch>
            <Route path={routes.login} component={Login} />
            <Route path={routes.forgotPassword} component={ForgotPassword} />
            <Redirect from={"*"} to={routes.login} />
        </Switch>
    );

    const renderAdminRoutes = () => (
        <Switch>
            <Route path={routes.locations.root} component={Locations} />
            <Route path={routes.alerts.root} component={Alerts} />
            <Route path={routes.events.root} component={Events} />
            <Route path={routes.sls.root} component={SurfLifesaving} />
            <Route path={routes.admin.root} component={Admin} />
            <Redirect from={"*"} to={routes.locations.root} />
        </Switch>
    );

    const renderRegionalAdminRoutes = () => (
        <Switch>
            <Route path={routes.locations.root} component={Locations} />
            <Route path={routes.alerts.root} component={Alerts} />
            <Redirect from={"*"} to={routes.locations.root} />
        </Switch>
    );

    const renderSLSRoutes = () => (
        <Switch>
            <Route path={routes.alerts.root} component={Alerts} />
            <Route path={routes.sls.root} component={SurfLifesaving} />
            <Route path={routes.admin.root} component={Admin} />
            <Redirect from={"*"} to={routes.alerts.root} />
        </Switch>
    );

    const renderDSAdminRoutes = () => (
        <Switch>
            <Route path={routes.admin.root} component={Admin} />
            <Redirect from={"*"} to={routes.admin.root} />
        </Switch>
    )

    const renderWatercareRoutes = () => (
        <Switch>
            <Route path={routes.alerts.root} component={Alerts} />
            <Route path={routes.admin.root} component={Admin} />
            <Redirect from={"*"} to={routes.alerts.root} />
        </Switch>
    );

    const renderAuthedRoutes = () => {
        if (!firebase.auth().currentUser?.getIdToken) {
            return <Spinner large center />;
        }

        switch (user.role) {
            case USER_ROLES.SUPER_ADMIN:
                return renderAdminRoutes();
            case USER_ROLES.SLS:
                return renderSLSRoutes();
            case USER_ROLES.WATERCARE:
                return renderWatercareRoutes();
            case USER_ROLES.REGIONAL_ADMIN:
                return renderRegionalAdminRoutes();
            case USER_ROLES.DS_ADMIN:
                return renderDSAdminRoutes();
            default:
                return null;
        }
    };

    return (
        <Router>
            <NavBar />
            {!user
                ? renderAnonRoutes()
                : renderAuthedRoutes()
            }
        </Router>
    );
}
