import createAction from "../helpers/createAction";
import wrapper from "../helpers/wrapper";
import * as WaterQualityAPI from "./api/waterQuality";
import WaterQuality from "../../types/WaterQuality";

export enum Types {
    WATER_QUALITY_RECEIVED = "WATER_QUALITY_RECEIVED"
}

export const setWaterQualityReport = createAction<WaterQuality>(Types.WATER_QUALITY_RECEIVED);

export const getWaterQualityReport = wrapper(WaterQualityAPI.getWaterQualityReport, {
    action: setWaterQualityReport
});
