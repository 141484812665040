import createAction from "../helpers/createAction";
import ApiKeysAPI from "./api/apiKeys";
import wrapper from "../helpers/wrapper";

export enum Types {
    API_KEY_RECEIVED = "API_KEY_RECEIVED",
    API_KEYS_RECEIVED = "API_KEYS_RECEIVED",
}

const setKeyReceived = createAction(Types.API_KEY_RECEIVED);
const setKeysReceived = createAction(Types.API_KEYS_RECEIVED);

export const getApiKeys = wrapper(ApiKeysAPI.getApiKeys, {
    action: setKeysReceived
});

export const getUserApiKeys = wrapper(ApiKeysAPI.getUserApiKeys, {
    action: setKeysReceived
});

export const generateApiKey = wrapper(ApiKeysAPI.generateApiKey, {
    action: setKeysReceived
});

export const generateApiKeyForUser = wrapper(ApiKeysAPI.generateApiKeyForUser, {
    action: setKeysReceived
});

export const deleteApiKey = wrapper(ApiKeysAPI.deleteApiKey, {
    action: setKeysReceived
});

export const blockApiKey = wrapper(ApiKeysAPI.blockApiKey, {
    action: setKeyReceived
});
