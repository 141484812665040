import MenuItem from "@material-ui/core/MenuItem";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import InfoRounded from "@material-ui/icons/InfoRounded";
import classNames from "classnames";
import { FieldInputProps } from "formik";
import React, { ChangeEvent, ReactNode, useCallback, useRef } from "react";
import Popover, { PopoverRef } from "../popover/Popover";
import styles from "./Input.module.scss";

export type SelectOption = {
    label: string;
    value: string | null;
    disabled?: boolean;
};

export type SelectOptions = SelectOption[];

export type InputProps = {
    options?: SelectOptions;
    value: string | number | undefined | null;
    info?: ReactNode;
    regex?: RegExp;
    convert?: (
        value: string | number | undefined | null
    ) => string | number | undefined | null;
    field?: FieldInputProps<string | number>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
} & Omit<TextFieldProps, "name" | "value" | "variant" | "onChange">;

export default function Input(props: InputProps) {
    const {
        options,
        info,
        regex,
        placeholder,
        convert,
        field,
        InputProps,
        InputLabelProps,
        SelectProps,
        onChange: propsOnChange,
        ...otherProps
    } = props;
    let { value } = props;

    const isSelect = !!options?.length;

    const popoverRef = useRef<PopoverRef>(null);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (regex) {
                e.target.value = (e.target.value || "").replace(regex, "");
            }

            propsOnChange?.(e);
        },
        [propsOnChange, regex]
    );

    const onInfoPress = useCallback(
        (e: React.MouseEvent<HTMLSpanElement>) => {
            popoverRef.current?.show(e.currentTarget);
        },
        [popoverRef]
    );

    const label = (props.label || info) && (
        <span className={styles.label_inner}>
            {props.label && (
                <span className={styles.label_label}>{props.label}</span>
            )}
            {info && (
                <span
                    className={styles.info}
                    onClick={onInfoPress}
                    role={"button"}
                >
                    <InfoRounded />
                </span>
            )}
        </span>
    );

    value = value ?? "";

    if (convert) {
        value = convert(value);
    }

    const className = classNames(styles.input, props.className);

    return (
        <>
            <TextField
                {...field}
                {...otherProps}
                label={label}
                className={className}
                InputLabelProps={{
                    ...InputLabelProps,
                    classes: {
                        ...InputLabelProps?.classes,
                        root: classNames(
                            styles.label,
                            InputLabelProps?.classes?.root
                        ),
                    },
                }}
                InputProps={{
                    ...InputProps,
                    classes: {
                        ...InputProps?.classes,
                        root: classNames(
                            styles.input_root,
                            InputProps?.classes?.root
                        ),
                    },
                }}
                value={value}
                select={isSelect}
                onChange={onChange}
                SelectProps={{
                    ...SelectProps,
                    classes: {
                        ...SelectProps?.classes,
                        root: classNames(
                            styles.select_root,
                            SelectProps?.classes?.root
                        ),
                    },
                    MenuProps: {
                        ...SelectProps?.MenuProps,
                        classes: {
                            paper: classNames(
                                styles.select_menu,
                                SelectProps?.MenuProps?.classes?.paper
                            ),
                        },
                    },
                }}
            >
                {isSelect &&
                    options!.map((option) => (
                        <MenuItem
                            key={option.value || "null"}
                            value={option.value || "null"}
                            disabled={!!option.disabled}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
            </TextField>

            {info && (
                <Popover
                    popoverRef={popoverRef}
                    className={styles.info_popover}
                >
                    {info}
                </Popover>
            )}
        </>
    );
}
