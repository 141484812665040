import React, { useEffect, useState } from "react";
import useSelector from "../../../../hooks/useSelector";
import useDispatch from "../../../../hooks/useDispatch";
import { getLocations } from "../../../../redux/actions/locations";
import Spinner from "components/widgets/spinner/Spinner";
import { Formik } from "formik";
import FormikInput from "components/widgets/input/FormikInput";

import SafeswimLocation from "../../../../types/SafeswimLocation";
import FormikDateTimePicker from "components/widgets/pickers/FormikDateTimePicker";

import Button from "components/widgets/button/Button";
import styles from "./waterQualityReport.module.scss";
import * as Yup from "yup";
import { format, roundToNearestMinutes, subMinutes } from "date-fns";
import { getWaterQualityReport } from "../../../../redux/actions/waterQuality";
import Table from "../../../widgets/table/Table";
import WaterQuality from "../../../../types/WaterQuality";
import AlertType from "../../../../types/AlertType";
import { getAlertTypes } from "../../../../redux/actions/alerts";

const validationSchema = Yup.object({
    location: Yup.string().matches(/^(?!\s*$).+/, "Required").required("Required"),
    date: Yup.string().required("Required")
});

type WaterQualityReportFormValues = { location: string, date: string };
const now = roundToNearestMinutes(subMinutes(new Date(), 5), { nearestTo: 15 });
now.setSeconds(0, 0);

function WaterQualityReport() {
    const [loading, setLoading] = useState(false);
    const [loadingReport, setLoadingReport] = useState(false);
    const locations = useSelector<SafeswimLocation[]>((state) => state.locations.locations);
    const report = useSelector<WaterQuality[]>((state) => state.waterQuality.report);
    const alertTypes = useSelector<{ [key: string]: AlertType }>((state) => state.alerts.alertTypes.allTypes);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchLocations = async () => {
            await dispatch(getLocations());
        };

        const fetchAlertTypes = async () => {
            await dispatch(getAlertTypes());
        };

        if (locations.length === 0) {
            fetchLocations();
        }

        if (Object.keys(alertTypes).length === 0) {
            fetchAlertTypes();
        }

        if (locations && alertTypes) {
            setLoading(false);
        }
    }, [locations, alertTypes, dispatch]);

    const onSubmit = async (values: WaterQualityReportFormValues) => {
        setLoadingReport(true);
        const date = new Date(values.date);
        await dispatch(getWaterQualityReport(Number(values.location), date.toISOString()));
        setLoadingReport(false);
    };

    if (loading || !locations.length) {
        return <Spinner center />;
    }

    const sortedLocations = locations.filter((l) => !l.isPermanent).map((loc) => ({
        label: loc.name,
        value: loc.id.toString(),
    })).sort((a, b) => a.label.localeCompare(b.label));
    const LOCATION_OPTS = [{ label: "Select location", value: "" }, ...sortedLocations];

    return (
        <div>
            <Formik<WaterQualityReportFormValues> initialValues={{ location: "", date: now.toISOString() }}
                                                  onSubmit={onSubmit}
                                                  validateOnMount={false}
                                                  validationSchema={validationSchema}>
                {(formikProps) => {
                    const { values } = formikProps;
                    let location: SafeswimLocation | null = null;

                    if (values.location !== "") {
                        location = locations.filter((l) => l.id === Number(location))[0];
                    }

                    const columns = [
                        {
                            title: "Moata forecast created at",
                            render: (w: WaterQuality) => format(new Date(w.createdAt), "dd/MM/yyyy hh:mm a")
                        },
                        {
                            title: "Daily forecast",
                            render: (w: WaterQuality) => format(new Date(w.recordedAt), "dd/MM/yyyy hh:mm a")
                        },
                        {
                            title: "Pin colour",
                            render: (w: WaterQuality) => {
                                const isCriteriaModel = location?.tags.includes("criteria_model");
                                const isFreshWater = location?.tags.includes("fresh_water");

                                if (location?.unavailable) {
                                    return "Unavailable";
                                }

                                if (!!w.alertObject) {
                                    const alertType = alertTypes[Number(w.alertObject.alertTypeId)];
                                    const recordedAtTime = new Date(w.recordedAt).toISOString();
                                    const alertFromTime = new Date(w.alertObject.from).toISOString();
                                    const alertToTime = new Date(w.alertObject.to).toISOString();

                                    if (recordedAtTime >= alertFromTime && recordedAtTime < alertToTime) {
                                        return alertType.value === "2" || alertType.value === 2 ? "Red" : "Black";
                                    }
                                }

                                if (!isCriteriaModel && !isFreshWater) {
                                    return w.value > 280 ? "Red" : "Green";
                                }

                                if (isCriteriaModel) {
                                    return w.value > 16 ? "Red" : "Green";
                                }

                                if (isFreshWater) {
                                    return w.value > 550 ? "Red" : "Green";
                                }

                                return "Grey";
                            }
                        },
                        {
                            title: "Alert",
                            render: (w: WaterQuality) => {
                                if (w.alertObject) {
                                    const recordedAtTime = new Date(w.recordedAt).toISOString();
                                    const alertFromTime = new Date(w.alertObject.from).toISOString();
                                    const alertToTime = new Date(w.alertObject.to).toISOString();

                                    if (recordedAtTime >= alertFromTime && recordedAtTime < alertToTime) {
                                        return `${alertTypes[Number(w.alertObject.alertTypeId)]?.title} - ${alertTypes[Number(w.alertObject.alertTypeId)]?.description}`;
                                    }
                                }

                                return "No alert";
                            }
                        }
                    ];

                    return (
                        <>
                            <div className={styles.water_quality_report_form}>
                                <FormikInput name={"location"} label={"Location"} options={LOCATION_OPTS} />
                                <FormikDateTimePicker name={"date"} label={"Date"} />
                                <Button variant={"contained"}
                                        type={"submit"}
                                        onClick={() => formikProps.handleSubmit()}
                                        disabled={loadingReport || !formikProps.isValid}>
                                    Search
                                </Button>
                            </div>
                            {loadingReport ? <Spinner center /> : (
                                <Table<WaterQuality> heading={"Website display of water quality"}
                                                     columns={columns}
                                                     data={report}
                                                     loading={loadingReport} />
                            )}
                        </>
                    );
                }}
            </Formik>
        </div>
    );
}

export default WaterQualityReport;
