import createAction from "../helpers/createAction";
import FacilitiesAPI from "./api/Facilities";
import wrapper from "../helpers/wrapper";

export enum Types {
    FACILITY_RECEIVED = "FACILITY_RECEIVED",
    FACILITY_DELETED = "FACILITY_DELETED",
    FACILITIES_RECEIVED = "FACILITIES_RECEIVED",
}

const setFacility = createAction(Types.FACILITY_RECEIVED);
const setNewFacilities = createAction(Types.FACILITY_DELETED);
const setFacilities = createAction(Types.FACILITIES_RECEIVED);

export const getFacility = wrapper(FacilitiesAPI.getFacility, {
    action: setFacility
});

export const getFacilities = wrapper(FacilitiesAPI.getFacilities, {
    action: setFacilities
});

export const createFacility = wrapper(FacilitiesAPI.createFacility, {
    action: setFacilities
});

export const updateFacility = wrapper(FacilitiesAPI.updateFacility, {
    action: setFacilities
});

export const deleteFacility = wrapper(FacilitiesAPI.deleteFacility, {
    action: setNewFacilities
})
