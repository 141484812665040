import Tag from "../../types/Tag";
import { Types } from "../actions/tags";

const initialState: {
    items: { [key: string]: Tag },
    cursor: string | null,
} = {
    items: {},
    cursor: null
};

export default function tags(state = initialState, action: any) {
    switch (action.type) {
        case Types.TAGS_RECEIVED: {
            const { items, cursor } = action.payload;
            const tags: { [key: string]: Tag } = {};

            items.forEach((tag: Tag) => {
                tags[tag.id] = tag;
            });

            return {
                items: tags,
                cursor: cursor
            };
        }

        case Types.TAG_RECEIVED: {
            const tag: Tag = action.payload;
            const tags: { [key: string]: Tag } = { ...state.items };

            tags[tag.id] = tag;

            return {
                items: tags,
                cursor: state.cursor
            };
        }

        default:
            return state;
    }
};
