import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import Spinner from "components/widgets/spinner/Spinner";
import { v4 as uuid } from "uuid";
import styles from "./addressInput.module.scss";
import Input from "components/widgets/input/Input";

type Props = {
    inputClassName?: string,
    label?: string,
    tooltip?: string,
    placeholder?: string,
    disabled?: boolean,
    defaultValue?: string,
    onChange?: (a: string) => void,
    onBlur?: () => void,
    width?: number,
    height?: number,
    noPadding?: boolean,
    mr?: number,
    required?: boolean,
    value?: string,
}

export default function AddressInput(props: Props) {
    const [addressString, setAddressString] = useState<string>(props.value || "");

    const handleChange = (a: string) => {
        setAddressString(a);
    };

    const handleSelect = (a: string, placeId: string) => {
        setAddressString(a);
        props.onChange?.(a);
    };

    return (
        <PlacesAutocomplete value={addressString}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            searchOptions={{ componentRestrictions: { country: "NZ" } }}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className={styles.container}>
                    <Input {...getInputProps({
                        placeholder: props.placeholder,
                        disabled: props.disabled,
                    })}
                           defaultValue={props.defaultValue}
                           onBlur={props.onBlur}
                           fullWidth />
                    <div className={styles.autocomplete_dropdown}>
                        {loading && <Spinner />}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active ? styles.suggestion_item_active : styles.suggestion_item;
                            return (
                                <div {...getSuggestionItemProps(suggestion, {
                                    className
                                })}
                                     key={uuid()}>
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
}
