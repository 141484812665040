import put from "./util/put";
import post from "./util/post";
import get from "./util/get";
import del from "./util/del";
import ListResult from "../../../types/ListResult";
import Hazard from "../../../types/Hazard";

function getHazard(id: string): Promise<Hazard> {
    return get(`v1/hazards/${id}`);
}

function getHazards(cursor?: string, limit?: number): Promise<ListResult<Hazard>> {
    return get("v1/hazards", { params: { cursor, limit } })
}

function createHazard(hazard: Hazard): Promise<Hazard> {
    return post("v1/hazards", hazard);
}

function updateHazard(id: string, hazard: Hazard): Promise<Hazard> {
    return put(`v1/hazards/${id}`, hazard);
}

function deleteHazard(id: string, hard?: boolean): Promise<ListResult<Hazard>> {
    return del(`v1/hazards/${id}`, { params: { hard } });
}

const HazardsAPI =  {
    getHazard,
    getHazards,
    createHazard,
    updateHazard,
    deleteHazard
}

export default HazardsAPI;
