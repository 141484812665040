import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";
import styles from "./createUpdateEvent.module.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import Spinner from "components/widgets/spinner/Spinner";
import Button from "components/widgets/button/Button";
import { createEvent, getEvent, updateEvent } from "../../redux/actions/specialEvents";
import useMountEffect from "../../hooks/useMountEffect";
import FormikInput from "components/widgets/input/FormikInput";

import FormikTextArea from "../widgets/formikFields/formikTextArea/FormikTextArea";
import Map from "../widgets/map/Map";
import classNames from "classnames";
import FormikDateTimePicker from "components/widgets/pickers/FormikDateTimePicker";

import FormikFileUpload from "../widgets/formikFields/formikFileUpload/FormikFileUpload";

export default function CreateUpdateEvent() {
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [event, setEvent] = useState<any>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { editing } = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    const initialCenter = event?.latLng ? {
        lat: parseFloat(event.latLng.lat),
        lng: parseFloat(event.latLng.lng)
    } : undefined;

    const eventTypeOptions: { label: string, key: string, value: string }[] = [
        { key: "type1", value: "false", label: "Special Event" },
        { key: "type2", value: "true", label: "Rahui" },
    ];

    const eventStatusOptions: { label: string, key: string, value: string }[] = [
        { key: "status1", value: "ACTIVE", label: "Active" },
        { key: "status2", value: "SUBJECT_TO_CONDITIONS", label: "Subject to conditions" },
        { key: "status3", value: "CANCELLED", label: "Cancelled" },
        { key: "status4", value: "RESTRICTIONS_IN_PLACE", label: "Restrictions in place" }
    ];

    useMountEffect(async () => {
        if (editing) {
            try {
                setLoading(true);
                const event = await dispatch(getEvent(Number(editing)));
                setEvent(event);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        }
    });

    const onSubmit = async (values: any) => {
        try {
            setSubmitting(true);
            if (editing) {
                await dispatch(updateEvent(Number(editing), values));
            } else {
                await dispatch(createEvent({ ...values, event: "special_event" }));
            }
        } catch (e) {
            console.log("sumbit err", e);
        } finally {
            setSubmitting(false);
            history.goBack();
        }
    };

    const initialValues = {
        name: event?.name || "",
        description: event?.description || "",
        start: event?.start || "",
        end: event?.end || "",
        eventLink: event?.eventLink || "",
        latLng: event?.latLng || {},
        eventStatus: event?.eventStatus || "ACTIVE",
        logoUrl: event?.logoUrl || "",
        rahui: event?.rahui || "false"
    };

    return (
        <div className={styles.container}>
            {loading
                ? <Spinner center />
                : (
                    <Formik initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={Yup.object({
                                name: Yup.string().required("Required"),
                                description: Yup.string().required("Required"),
                                start: Yup.date().required("Required"),
                                end: Yup.date().required("Required"),
                                eventLink: Yup.string(),
                                latLng: Yup.object().required("Required"),
                                eventStatus: Yup.string(),
                                logoUrl: Yup.string(),
                                rahui: Yup.string()
                            })}
                            enableReinitialize>
                        {({ handleSubmit, isValid, isSubmitting, dirty, setFieldValue, values }) => {
                            return (
                                <>
                                    <div className={styles.header}>
                                        <h1>{editing ? "Edit Event " : "Create Event"}</h1>
                                        <div className={styles.header_actions}>
                                            <Button onClick={() => history.goBack()}>Cancel</Button>
                                            <Button variant={"contained"}
                                                    onClick={(values: any) => handleSubmit(values)}
                                                    loading={submitting}
                                                    disabled={!isValid}>Save
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={styles.map_intro}>
                                        <h5>LOCATION PIN</h5>
                                        <span>Please adjust the location marker to reflect the location of the beach. This is where the pin will sit on the map.</span>
                                    </div>
                                    <div className={styles.map}>
                                        <Map onLocationSet={(latLng) => {
                                            setFieldValue("latLng", latLng);
                                        }}

                                             initialCenter={initialCenter} />
                                    </div>
                                    <div className={styles.form_content}>
                                        <h4>Event details</h4>
                                        <FormikInput name={"name"} label={"Event Name"}
                                                     className={classNames(styles.half_input, styles.first)} />
                                        <FormikInput name={"rahui"} label={"Event Type"} options={eventTypeOptions}
                                                     className={styles.half_input} />
                                        <FormikTextArea name={"description"}
                                                        label={"Description"}
                                                        className={styles.text_area} />

                                        <FormikDateTimePicker format={"dd/MM/yyyy hh:mm a"} name={"start"}
                                                              label={"Event from (Date/Time)"}
                                                              className={classNames(styles.half_input, styles.first)} />
                                        <FormikDateTimePicker format={"dd/MM/yyyy hh:mm a"} name={"end"}
                                                              label={"Event To (Date/Time)"}
                                                              className={styles.half_input} />
                                        <FormikInput name={"eventLink"} label={"Event external link"}
                                                     className={classNames(styles.half_input, styles.first)} />
                                        <FormikInput name={"eventStatus"} label={"Event status"}
                                                     options={eventStatusOptions}
                                                     className={styles.half_input} />
                                        <FormikFileUpload name={"logoUrl"} label={"Event logo"} multiple={false}
                                                          containerClassName={styles.image_upload}
                                                          accept={["image/svg+xml", "image/png", "image/jpeg"]}
                                                          actionText={"Image upload"}
                                                          helperText={"Drop images here, or click to select images to upload"} />
                                    </div>
                                </>
                            );
                        }}
                    </Formik>
                )}

        </div>
    );
}
