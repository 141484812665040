import { Types } from "../actions/locations";
import H2Location from "../../types/H2Location";
import DigitalSignDTO from "../../types/DigitalSignDTO";
import SafeswimLocation from "../../types/SafeswimLocation";

const initialState: {
    locations: SafeswimLocation[],
    h2Locations: H2Location[],
    digitalSigns: DigitalSignDTO[],
    availableRegionBased: string[],
} = {
    locations: [],
    h2Locations: [],
    digitalSigns: [],
    availableRegionBased: []
};

export default function locations(state = initialState, action: any) {
    switch (action.type) {
        case Types.LOCATION_RECEIVED: {
            return {
                ...state,
                location: action.payload,
            };
        }

        case Types.LOCATIONS_RECEIVED: {
            const compareFunction = (a: SafeswimLocation, b: SafeswimLocation) => {
                if (a.ordinal < b.ordinal) {
                    return -1;
                }
                return 1;
            };
            action.payload.sort(compareFunction);
            return {
                ...state,
                locations: action.payload
            };
        }

        case Types.H2_LOCATIONS_RECEIVED: {
            return {
                ...state,
                h2Locations: action.payload
            };
        }

        case Types.SIGNS_RECEIVED: {
            return {
                ...state,
                digitalSigns: action.payload
            };
        }

        case Types.REGION_BASED_RECEIVED: {
            return {
                ...state,
                availableRegionBased: action.payload
            }
        }

        default:
            return state;
    }
};
