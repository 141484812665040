import { SnackbarConfig } from "../../components/widgets/snackbar/Snackbar";
import { Types } from "../actions/snackbars";

const initialState: SnackbarConfig[] = [];


export default function snackbars(state = initialState, action: any): SnackbarConfig[] {
    switch (action.type) {
        case Types.ADD_SNACKBAR: {
            const config = action.payload as SnackbarConfig;
            if (!config) {
                return state;
            }

            return [...state, config];
        }

        case Types.REMOVE_SNACKBAR: {
            return [...state].filter((snackbar) => {
                return snackbar.id !== action.payload;
            });
        }
        default: {
            return state;
        }
    }
}