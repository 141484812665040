import AdminAPIKey from "../../types/AdminAPIKey";
import { Types } from "../actions/apiKeys";

const initialState: { [key: string]: AdminAPIKey } = {};

export default function apiKeys(state = initialState, action: any) {
    switch (action.type) {
        case Types.API_KEY_RECEIVED: {
            const apiKeys: { [key: string]: AdminAPIKey } = { ...state };

            apiKeys[action.payload.id] = action.payload;

            return apiKeys;
        }

        case Types.API_KEYS_RECEIVED: {
            const apiKeys: { [key: string]: AdminAPIKey } = {};

            action.payload.forEach((key: AdminAPIKey) => {
                apiKeys[key.id] = key;
            });

            return apiKeys;
        }

        default:
            return state;
    }
}