import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";
import useMountEffect from "../../../hooks/useMountEffect";
import styles from "../../locations/facilities/createUpdateFacility.module.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { createAlertType, getAlertType, updateAlertType } from "../../../redux/actions/alerts";
import Spinner from "components/widgets/spinner/Spinner";
import Button from 'components/widgets/button/Button';
import FormikInput from "components/widgets/input/FormikInput";

import { showError, showSuccess } from "../../../redux/actions/snackbars";

export default function CreateUpdateWaterQualityAlert() {
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [waterQualityAlertType, setWaterQualityAlertType] = useState<any>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { editing } = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    useMountEffect(async () => {
        if (editing) {
            try {
                setLoading(true);
                const alertType = await dispatch(getAlertType(Number(editing)));
                setWaterQualityAlertType(alertType);
            } catch (e: any) {
                dispatch(showError(e.message));
            } finally {
                setLoading(false);
            }
        }
    })

    const onSubmit = async (values: any) => {
        try {
            setSubmitting(true);
            if (editing) {
                await dispatch(updateAlertType({ ...values, id: editing, alertType: "water_quality" }))
                dispatch(showSuccess("Alert saved"));
            } else {
                await dispatch(createAlertType({ ...values, alertType: "water_quality" }));
                dispatch(showSuccess("Created alert"));
            }
            history.goBack();
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setSubmitting(false);
        }
    }

    const initialValues = {
        title: waterQualityAlertType?.title || "",
        value: waterQualityAlertType?.value || "",
        description: waterQualityAlertType?.description || "",
    };

    return (
        <div className={styles.container}>
            {loading
                ? <Spinner center/>
                : (
                    <Formik initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={Yup.object({
                                title: Yup.string().required("Required"),
                                value: Yup.number().min(1, "Invalid alert level").max(3, "Invalid alert level").required("Required"),
                                description: Yup.string().required("Required"),
                            })}
                            enableReinitialize>
                        {({ handleSubmit, isValid, isSubmitting, dirty }) => {
                            return (
                                <>
                                    <div className={styles.header}>
                                        <h1>{editing ? "Edit Water Quality Alert Type " : "Create Water Quality Alert Type "}</h1>
                                        <div className={styles.header_actions}>
                                            <Button onClick={() => history.goBack()}>Cancel</Button>
                                            <Button variant={"contained"}
                                                    onClick={() => handleSubmit()}
                                                    loading={submitting}
                                                    disabled={!dirty || !isValid || isSubmitting}>Save
                                            </Button>
                                        </div>
                                    </div>
                                    <FormikInput name={"title"} label={"Name"} className={styles.full_input}/>
                                    <FormikInput name={"value"} label={"Value"} className={styles.full_input}/>
                                    <FormikInput name={"description"} label={"Description"}
                                                 className={styles.full_input}/>
                                </>
                            )
                        }}
                    </Formik>
                )}

        </div>
    );
}
