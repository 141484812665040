import { combineReducers } from "redux";

import auth from "./auth";
import locations from "./locations";
import alerts from "./alerts";
import hazards from "./hazards";
import facilities from "./facilities";
import tags from "./tags";
import users from "./users";
import apiKeys from "./apiKeys";
import specialEvents from "./specialEvents";
import siteOverride from "./siteOverride";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import snackbars from "./snackbars";
import changes from "./changes";
import waterQuality from "./waterQuality";

const root = combineReducers({
    apiKeys,
    auth,
    alerts,
    facilities,
    hazards,
    locations,
    siteOverride,
    snackbars,
    specialEvents,
    tags,
    users,
    changes,
    waterQuality
});

const PERSIST_CONFIG = {
    key: "root",
    storage: storage,
    whitelist: ["auth"]
};

const persistedReducer = persistReducer(PERSIST_CONFIG, root);

export type ApplicationState = ReturnType<typeof root>;

export default persistedReducer;
