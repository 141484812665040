import React, { useState } from "react";
import styles from "./table.module.scss";
import MaterialTable, { MaterialTableProps } from "@material-table/core";
import Button from "components/widgets/button/Button";
import { ButtonProps } from "components/widgets/button/Button";

export type TableProps<T extends object> = {
    heading?: string;
    actionButton?: { text: string; action: (args: any) => void };
    loading?: boolean;
    defaultPageSize?: number;
    buttonProps?: Omit<ButtonProps, "variant" | "onClick">;
} & Omit<MaterialTableProps<T>, "isLoading">;

export default function Table<T extends object = any>(props: TableProps<T>) {
    const {
        heading,
        actionButton,
        buttonProps,
        loading,
        defaultPageSize,
        options,
        data,
        ...otherProps
    } = props;
    const [pageSize, setPageSize] = useState<number>(defaultPageSize || 50);

    return (
        <div className={styles.container}>
            {(heading || actionButton) && (
                <div className={styles.table_header}>
                    {heading && <h1>{heading}</h1>}
                    {!!actionButton && (
                        <Button
                            variant="contained"
                            onClick={actionButton.action}
                            {...buttonProps}
                        >
                            {actionButton.text}
                        </Button>
                    )}
                </div>
            )}
            <MaterialTable
                {...otherProps}
                data={data ?? []}
                isLoading={loading}
                onRowsPerPageChange={setPageSize}
                options={{
                    toolbar: false,
                    draggable: false,
                    search: false,
                    pageSizeOptions: [5, 10, 20, 25, 50, 100, 500],
                    pageSize,
                    paging: data.length > 0 && data.length > pageSize,
                    ...options,
                }}
            />
        </div>
    );
}
