import get from "./util/get";
import put from "./util/put";
import post from "./util/post";
import del from "./util/del";
import SafeswimUser, { UserCreateUpdateRequest, USER_ROLES } from "../../../types/SafeswimUser";
import SafeswimUsername from "../../../types/SafeswimUsername";

export function getUser(id: string): Promise<SafeswimUser> {
    return get(`v1/users/get/${id}`);
}

export function getUsernames(): Promise<SafeswimUsername[]> {
    return get("v1/users/get/names");
}

export function getUserByEmail(email: string): Promise<SafeswimUser> {
    return get(`v1/admin/users/email/${email}`);
}

export function getUsers(): Promise<SafeswimUser[]> {
    return get("v1/admin/users");
}

export function updateUser(id: string, user: UserCreateUpdateRequest): Promise<SafeswimUser> {
    return put(`v1/admin/users/${id}`, user);
}

export function getRoles(): Promise<USER_ROLES> {
    return get("v1/admin/users/roles");
}

export function createUser(user: UserCreateUpdateRequest): Promise<SafeswimUser> {
    return post("v1/admin/users", user);
}

export function deleteUser(id: string): Promise<SafeswimUser[]> {
    return del(`v1/admin/users/${id}`);
}

export function enableUser(id: string): Promise<SafeswimUser[]> {
    return put(`v1/admin/users/enable/${id}`);
}

const UsersAPI = {
    getUser,
    getUsernames,
    getUserByEmail,
    getUsers,
    updateUser,
    getRoles,
    createUser,
    deleteUser,
    enableUser,
}

export default UsersAPI;
