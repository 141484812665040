import React, { useState } from "react";
import Button from "components/widgets/button/Button";
import { Form, Formik } from "formik";
import FormikInput from "components/widgets/input/FormikInput";

import * as Yup from "yup";
import FormikFileUpload from "../../widgets/formikFields/formikFileUpload/FormikFileUpload";
import { useDispatch } from "react-redux";
import { createFacility, getFacility, updateFacility } from "../../../redux/actions/facilities";
import { useHistory } from "react-router-dom";
import qs from "qs";
import useMountEffect from "../../../hooks/useMountEffect";
import styles from "./createUpdateFacility.module.scss";
import Spinner from "components/widgets/spinner/Spinner";
import { showError, showSuccess } from "../../../redux/actions/snackbars";

export default function CreateUpdateFacility() {
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [facility, setFacility] = useState<any>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { editing } = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    useMountEffect(async () => {
        if (editing) {
            try {
                setLoading(true);
                const facility = await dispatch(getFacility(editing as string));
                setFacility(facility);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        }
    });

    const onSubmit = async (values: any) => {
        //todo remove description once backed is refactored
        const payload = { description: values.title, ...values };
        try {
            setSubmitting(true);
            if (editing) {
                await dispatch(updateFacility(editing as string, payload));
            } else {
                await dispatch(createFacility(payload));
            }
            dispatch(showSuccess("Facility saved"));
            history.goBack();
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        id: facility?.id || "",
        title: facility?.title || "",
        icon: facility?.icon || "",
    };

    return (
        <div className={styles.container}>
            {loading
                ? <Spinner center />
                : (
                    <Formik initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={Yup.object({
                            title: Yup.string().required("Required"),
                            icon: Yup.string().required("Required")
                        })}
                        enableReinitialize>
                        {({ values, errors, handleSubmit, isValid, isSubmitting, dirty }) => {
                            return (
                                <Form>
                                    <div className={styles.header}>
                                        <h1>{editing ? "Edit Facility " : "Create Facility"}</h1>
                                        <div className={styles.header_actions}>
                                            <Button onClick={() => history.goBack()}>Cancel</Button>
                                            <Button variant={"contained"}
                                                onClick={() => handleSubmit()}
                                                loading={submitting}
                                                disabled={!dirty || !isValid || isSubmitting}>Save
                                                facility</Button>
                                        </div>
                                    </div>
                                    <FormikInput name={"title"} label={"Facility name"} className={styles.full_input} />
                                    <FormikFileUpload name={"icon"}
                                        label={"Facility icon"}
                                        accept={["image/*"]}
                                        actionText={"Image upload \n SVG format 1:1 ratio"} />
                                </Form>
                            );
                        }}
                    </Formik>
                )}

        </div>
    );
}
