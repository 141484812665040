import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";
import useMountEffect from "../../../hooks/useMountEffect";
import { createHazard, getHazard, updateHazard } from "../../../redux/actions/hazards";
import Spinner from "components/widgets/spinner/Spinner";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./createUpdateHazard.module.scss";
import Button from "components/widgets/button/Button";
import FormikInput from "components/widgets/input/FormikInput";

import FormikFileUpload from "../../widgets/formikFields/formikFileUpload/FormikFileUpload";
import { showError, showSuccess } from "../../../redux/actions/snackbars";

export default function CreateUpdateHazard() {
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [hazard, setHazard] = useState<any>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { editing } = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    useMountEffect(async () => {
        if (editing && typeof editing === "string") {
            try {
                setLoading(true);
                const hazard = await dispatch(getHazard(editing));
                setHazard(hazard);
            } catch (e) {
                dispatch(showError("Error retrieving hazards"));
            } finally {
                setLoading(false);
            }
        }
    });

    const onSubmit = async (values: any) => {
        //todo remove description once backed is refactored
        const payload = { ...values };
        try {
            setSubmitting(true);
            if (editing && typeof editing === "string") {
                // payload.description = hazard.description;
                await dispatch(updateHazard(editing, payload));
            } else {
                await dispatch(createHazard(payload));
            }
            dispatch(showSuccess("Saved hazard"));
            history.goBack();
        } catch (e) {
            dispatch(showError("Error saving hazard"));
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues = {
        id: hazard?.id || "",
        title: hazard?.title || "",
        icon: hazard?.icon || "",
        description: hazard?.description || ""
    };

    return (
        <div className={styles.container}>
            {loading
                ? <Spinner center />
                : (
                    <Formik initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={Yup.object({
                                title: Yup.string().required("Required"),
                                icon: Yup.string().required("Required")
                            })}>
                        {({ handleSubmit, dirty, isValid, isSubmitting }) => {
                            return (
                                <>
                                    <div className={styles.header}>
                                        <h1>{editing ? "Edit Hazard " : "Create Hazard"}</h1>
                                        <div className={styles.header_actions}>
                                            <Button onClick={() => history.goBack()}>Cancel</Button>
                                            <Button variant={"contained"}
                                                    onClick={() => handleSubmit()}
                                                    loading={submitting}
                                                    disabled={!dirty || !isValid || isSubmitting}>Save
                                                hazard</Button>
                                        </div>
                                    </div>
                                    <FormikInput name={"title"} label={"Hazard name"} className={styles.full_input} />
                                    <FormikFileUpload name={"icon"} label={"Hazard icon"}
                                                      accept={"image/*"}
                                                      actionText={"Image upload \n SVG format 1:1 ratio"} />
                                </>
                            );
                        }}
                    </Formik>
                )}
        </div>
    );
}
