export default interface AlertType {
    id: number;
    alertType: ALERT_TYPE;
    title: string;
    description: string;
    canSwim: boolean;
    value: any;
    ordinal: number;
    publicOrdinal: number;
    slsId?: string;
    imageUrl?: string;
}

export enum ALERT_TYPE {
    WATER_QUALITY = "water_quality",
    HAZARDS = "hazards",
}

export interface AlertTypeDTO {
    id: number;
    value: any;
    title: string;
    description: string;
    alertType: string;
    parent: number;
    canSwim: boolean;
    ordinal: number;
    publicOrdinal: number;
    slsId?: string;
    imageUrl?: string;
}

export type AlertTypeCreateUpdateRequest = {
    title: string;
    value: string;
    description: string;
    slsId?: string;
    imageUrl?: string;
};
