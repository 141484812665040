export const REGION_OPTS: { label: string, value: string }[] = [
    {value: "westernBays", label: "Waitematā / Western Bays"},
    {value: "easternBays", label: "Waitematā / Eastern Bays"},
    {value: "east", label: "Eastern Beaches"},
    {value: "southEast", label: "South Eastern Beaches"},
    {value: "northShore", label: "North Shore"},
    {value: "whangaparoa", label: "Whangaparaoa and Northern Beaches"},
    {value: "southManukau", label: "Southern Manukau"},
    {value: "northEastManukau", label: "North Eastern Manukau"},
    {value: "northWestManukau", label: "North West Manukau"},
    {value: "west", label: "West Coast"},
    {value: "hauraki", label: "Hauraki Gulf Islands"},
];