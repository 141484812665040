import SpecialEvent from "../../types/SpecialEvent";
import { Types } from "../actions/specialEvents";

const initialState: {
    specialEvents: { [key: string]: SpecialEvent }
} = {
    specialEvents: {}
};

export default function specialEvents(state = initialState, action: any) {
    switch (action.type) {
        case Types.EVENTS_RECEIVED: {
            const events: { [key: string]: SpecialEvent } = {};

            action.payload.forEach((event: SpecialEvent) => {
                events[event.id] = event;
            });

            return events;
        }

        case Types.EVENT_RECEIVED: {
            let event: { [key: string]: SpecialEvent } = {};
            event = action.payload;
            return event;
        }

        default:
            return {
                ...state
            };
    }
}