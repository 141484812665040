import put from "./util/put";
import post from "./util/post";
import get from "./util/get";
import del from "./util/del";
import ListResult from "../../../types/ListResult";
import Facility from "../../../types/Facility";

function getFacility(id: string): Promise<Facility> {
    return get(`v1/features/${id}`);
}

function getFacilities(cursor?: string, limit?: number): Promise<ListResult<Facility>> {
    return get("v1/features", { params: { cursor: cursor, limit: limit } });
}

function createFacility(facility: Pick<Facility, "id" | "title" | "icon">): Promise<ListResult<Facility>> {
    return post("v1/features", facility);
}

function updateFacility(id: string, facility: Pick<Facility, "id" | "title" | "icon">): Promise<ListResult<Facility>> {
    return put(`v1/features/${id}`, facility);
}

function deleteFacility(id: string, hard?: boolean): Promise<ListResult<Facility>> {
    return del(`v1/features/${id}`, { params: { hard: hard } });
}

const FacilitiesAPI = {
    getFacility,
    getFacilities,
    createFacility,
    updateFacility,
    deleteFacility
};

export default FacilitiesAPI;