import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import root from "./reducers/root";

export default function configureStore() {
    const store = createStore(root, applyMiddleware(thunk));
    const persistor = persistStore(store);

    return {
        store,
        persistor
    }
}
