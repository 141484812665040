import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import Spinner from "components/widgets/spinner/Spinner";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import Button from "components/widgets/button/Button";
import styles from "./createUpdateAlert.module.scss";
import FormikCheckboxGroup from "../../widgets/formikFields/formikCheckboxGroup/FormikCheckboxGroup";
import useMountEffect from "../../../hooks/useMountEffect";
import {
    createAlert,
    getAlert,
    getAlertTypes,
    updateAlert,
} from "../../../redux/actions/alerts";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import FormikDateTimePicker from "components/widgets/pickers/FormikDateTimePicker";

import FormikInput from "components/widgets/input/FormikInput";

import useSelector from "../../../hooks/useSelector";
import { REGION_OPTS } from "../../../constants/regions";
import { getLocations } from "../../../redux/actions/locations";
import { AlertUpdateRequest } from "types/Alert";
import useDispatch from "hooks/useDispatch";
import SafeswimLocation from "types/SafeswimLocation";
import { ALERT_TYPE } from "types/AlertType";
import FormikTextArea from "../../widgets/formikFields/formikTextArea/FormikTextArea";
import FormikFileUpload from "../../widgets/formikFields/formikFileUpload/FormikFileUpload";
import FormikCheckbox from "../../widgets/checkbox/FormikCheckbox";
import useAdminState from "hooks/useAdminState";

const ValidationSchema = Yup.object({
    alertType: Yup.string().required("Required"),
    alertTypeId: Yup.string().required("Required"),
    locations: Yup.array(),
    regions: Yup.array(),
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
});

type FormValue = {
    regions: string[];
    selectionType: string;
    updateFeed?: {
        description: string;
        images?: string[];
        createdAt?: string;
    }[];
    state?: string;
    showUpdates?: boolean;
    textOverride?: string;
} & AlertUpdateRequest;

export default function CreateUpdateAlert() {
    const history = useHistory();
    const alertId = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
    }).editing as string | undefined;
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { isWatercare } = useAdminState();
    const alert = useSelector(
        (state) => state.alerts.alerts.items[alertId ?? ""]
    );

    const { hazardTypes, waterTypes } = useSelector(
        (state) => state.alerts.alertTypes
    );
    const locations = useSelector<SafeswimLocation[]>(
        (state) => state.locations.locations
    );
    const dispatch = useDispatch();

    useMountEffect(async () => {
        try {
            setLoading(true);
            if (alertId && !alert) {
                await dispatch(getAlert(Number(alertId)));
            }
            if (
                !Object.values(hazardTypes).length ||
                !Object.values(waterTypes).length
            ) {
                await dispatch(getAlertTypes());
            }
            if (!locations || !locations.length) {
                await dispatch(getLocations());
            }
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    });

    const onSubmit = async (
        values: FormValue,
        helper: FormikHelpers<FormValue>
    ) => {
        const { regions, selectionType, ...payload } = values;
        if (regions.length) {
            payload.locations = locations
                .filter((l) => regions.includes(l.region))
                .map((l) => l.id.toString());
        }

        if (payload.regionBasedList.length) {
            payload.locations = locations
                .filter((l) => payload.regionBasedList.includes(l.regionBased))
                .map((l) => l.id.toString());
        }

        if (!payload.locations.length) {
            helper.setFieldError(
                "selectionType",
                "You need to select at least one location"
            );
            return;
        }

        payload.regionBasedList = locations
            .filter((l) => payload.locations.includes(l.id.toString()))
            .map((l) => l.regionBased);

        if (payload.showUpdates && (!payload.state || payload.state === "")) {
            helper.setFieldError(
                "state",
                "You need to select a state if you wish to display the updates section"
            );
            return;
        }

        if (payload.showUpdates && payload.updateFeed?.length) {
            payload.updateFeed = payload.updateFeed.filter(
                (v) =>
                    (v.description && v.description !== "") ||
                    (v.images && v.images.length > 0)
            );
        }

        try {
            setSubmitting(true);
            if (alertId) {
                await dispatch(updateAlert(Number(alertId), payload));
                dispatch(showSuccess("Alert saved"));
            } else {
                await dispatch(createAlert(payload));
                dispatch(showSuccess("Alert created"));
            }
            history.goBack();
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setSubmitting(false);
        }
    };

    const initialLocations = alert?.locations?.map((loc: any) =>
        loc.locationId.toString()
    );

    const initialValues: FormValue = {
        alertType: alert?.alertType || ALERT_TYPE.WATER_QUALITY,
        regions: [],
        locations: initialLocations || [],
        from: alert?.from ?? "",
        to: alert?.to ?? "",
        alertTypeId: alert?.alertTypeId?.toString() ?? "",
        selectionType: "location",
        regionBasedList: [],
        updateFeed: alert?.updateFeed ?? [],
        state: alert?.state ?? undefined,
        showUpdates: !!alert?.updateFeed,
        textOverride: alert?.textOverride ?? undefined,
    };

    return (
        <div className={styles.container}>
            {loading ? (
                <Spinner center />
            ) : (
                <Formik<FormValue>
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={ValidationSchema}
                >
                    {({
                        values,
                        handleSubmit,
                        isValid,
                        dirty,
                        isSubmitting,
                        setFieldValue,
                    }) => {
                        const handleTypeChange = () => {
                            setFieldValue("alertTypeId", null);
                        };

                        const handleSelectionTypeChange = () => {
                            setFieldValue("regions", []);
                            setFieldValue("locations", []);
                        };

                        const hazards = Object.values(hazardTypes || {}).sort(
                            (a, b) => {
                                if (a.ordinal < b.ordinal) {
                                    return -1;
                                }
                                if (a.ordinal > b.ordinal) {
                                    return 1;
                                }
                                return 0;
                            }
                        );

                        const HAZARD_OPTS = hazards.map((type: any) => ({
                            label: type.title + "\n",
                            value: type.id.toString(),
                            description: type.description,
                        }));
                        const WATER_OPTS = Object.values(waterTypes || {})?.map(
                            (type: any) => ({
                                label: type.title,
                                value: type.id.toString(),
                                riskValue: type.value,
                                description: type.description,
                            })
                        );

                        let LOCATION_OPTS = locations
                            .map((loc) => ({
                                label: loc.name,
                                value: loc.id.toString(),
                                region: loc.region,
                                regionBased: loc.regionBased,
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label));
                        if (isWatercare) {
                            LOCATION_OPTS = LOCATION_OPTS.filter(
                                (l) =>
                                    l.regionBased ===
                                    "Tāmaki-makau-rau - Auckland"
                            );
                        }
                        const regionBased: string[] = [];
                        locations.forEach((l) => {
                            if (!regionBased.includes(l.regionBased)) {
                                regionBased.push(l.regionBased);
                            }
                        });
                        const regionBasedOptions = regionBased.map((r) => ({
                            label: r,
                            value: r,
                        }));
                        const MY_REGION_OPTS = REGION_OPTS.filter((r) =>
                            locations.find((l) => l.region === r.value)
                        ).sort((a, b) => a.label.localeCompare(b.label));

                        const MY_REGION_BASED_OPTS = regionBasedOptions
                            .filter(
                                (r) =>
                                    locations.find(
                                        (l) => l.regionBased === r.value
                                    ) && r.value
                            )
                            .sort((a, b) => a.label.localeCompare(b.label));

                        const SELECTION_TYPES = [
                            { label: "Region (Auckland)", value: "region" },
                            {
                                label: "Region Based (NZ)",
                                value: "regionBased",
                            },
                            { label: "Location", value: "location" },
                        ];

                        const OVERFLOW_ALERT_STATES = [
                            {
                                label: "Overflow reported",
                                value: "OVERFLOW_REPORTED",
                            },
                            { label: "Investigating", value: "INVESTIGATING" },
                            { label: "Work underway", value: "WORK_UNDERWAY" },
                            { label: "Work completed", value: "WORK_COMPLETE" },
                        ];

                        const alertTypes =
                            values.alertType === "water_quality"
                                ? WATER_OPTS
                                : HAZARD_OPTS;
                        // const isOverflow =
                        //     values.alertType === ALERT_TYPE.WATER_QUALITY &&
                        //     waterTypes[values.alertTypeId]?.title
                        //         .toLowerCase()
                        //         .includes("overflow");
                        return (
                            <Form>
                                <div className={styles.header}>
                                    <h1>
                                        {alertId
                                            ? "Edit Alert "
                                            : "Create Alert"}
                                    </h1>
                                    <div className={styles.header_actions}>
                                        <Button
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant={"contained"}
                                            type="submit"
                                            loading={submitting}
                                            disabled={!isValid || isSubmitting}
                                        >
                                            Save alert
                                        </Button>
                                    </div>
                                </div>
                                <FormikInput
                                    name={"alertType"}
                                    label={"Alert type"}
                                    options={ALERT_TYPE_OPTS}
                                    onChange={handleTypeChange}
                                    className={styles.full_input}
                                />
                                {!alertTypes?.length && <Spinner />}
                                <FormikCheckboxGroup
                                    name={"alertTypeId"}
                                    type={"radio"}
                                    values={alertTypes}
                                    containerClassName={styles.checkboxes}
                                />

                                <>
                                    <FormikTextArea
                                        name={"textOverride"}
                                        label={"Text override"}
                                        rowsMin={3}
                                        style={{ width: "100%" }}
                                    />
                                    <FormikCheckbox
                                        name={"showUpdates"}
                                        disabled={!!alert?.updateFeed}
                                        label={"Show updates"}
                                    />
                                    {values.showUpdates && (
                                        <div className={styles.container}>
                                            <h2 className={styles.header}>
                                                Overflow updates:
                                            </h2>
                                            <div>
                                                <FormikInput
                                                    name={"state"}
                                                    label={"Current State"}
                                                    options={
                                                        OVERFLOW_ALERT_STATES
                                                    }
                                                />
                                            </div>
                                            <FieldArray
                                                name="updateFeed"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {values.updateFeed?.map(
                                                            (v, i) => (
                                                                <div key={i}>
                                                                    <FormikTextArea
                                                                        name={`updateFeed.${i}.description`}
                                                                        label={
                                                                            "Description"
                                                                        }
                                                                        className={
                                                                            styles.text_area
                                                                        }
                                                                    />
                                                                    <FormikFileUpload
                                                                        name={`updateFeed.${i}.images`}
                                                                        label={
                                                                            "Images"
                                                                        }
                                                                        containerClassName={
                                                                            styles.image_upload
                                                                        }
                                                                        multiple
                                                                        accept={[
                                                                            "image/svg+xml",
                                                                            "image/png",
                                                                            "image/jpeg",
                                                                        ]}
                                                                        actionText={
                                                                            "Drag images here or click to upload"
                                                                        }
                                                                    />
                                                                    <div
                                                                        className={
                                                                            styles.remove_btn
                                                                        }
                                                                    >
                                                                        <Button
                                                                            plain
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    i
                                                                                )
                                                                            }
                                                                        >
                                                                            -
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                        <Button
                                                            plain
                                                            onClick={() =>
                                                                arrayHelpers.push(
                                                                    {}
                                                                )
                                                            }
                                                        >
                                                            + Add
                                                        </Button>
                                                    </>
                                                )}
                                            />
                                            <hr />
                                        </div>
                                    )}
                                </>

                                <span className={styles.label}>Select by:</span>
                                <FormikInput
                                    className={styles.full_input}
                                    name={"selectionType"}
                                    onChange={handleSelectionTypeChange}
                                    options={SELECTION_TYPES}
                                />
                                {values.selectionType === "region" ? (
                                    <FormikCheckboxGroup
                                        name={"regions"}
                                        type={"checkbox"}
                                        values={MY_REGION_OPTS}
                                        containerClassName={styles.checkboxes}
                                    />
                                ) : values.selectionType === "regionBased" ? (
                                    <FormikCheckboxGroup
                                        name={"regionBasedList"}
                                        type={"checkbox"}
                                        values={MY_REGION_BASED_OPTS}
                                        containerClassName={styles.checkboxes}
                                    />
                                ) : locations ? (
                                    <FormikCheckboxGroup
                                        name={"locations"}
                                        type={"checkbox"}
                                        values={LOCATION_OPTS}
                                        containerClassName={styles.checkboxes}
                                    />
                                ) : (
                                    <Spinner center />
                                )}
                                <FormikDateTimePicker
                                    name={"from"}
                                    label={"Alert From (Date/Time):"}
                                    className={styles.half_input}
                                />
                                <FormikDateTimePicker
                                    name={"to"}
                                    label={"Alert To (Date/Time):"}
                                    className={styles.half_input}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </div>
    );
}
const ALERT_TYPE_OPTS = [
    { value: "water_quality", label: "Water Quality" },
    { value: "hazards", label: "Water Safety" },
];
