import React, { ReactNode, Ref } from 'react';
import Dialog, { DialogRef, DialogProps } from "components/widgets/dialog/Dialog";
import Button from "components/widgets/button/Button";
import styles from "./warningDialog.module.scss";

type Props = {
    title: string;
    children: ReactNode;
    loading?: boolean;
} & DialogProps;

function WarningDialog(props: Props, ref: Ref<DialogRef>) {
    const { title, children, loading, positiveText, onPositivePress, positiveButtonProps, negativeText, onNegativePress, negativeButtonProps, ...otherProps } = props;

    return (
        <Dialog {...otherProps}
                disableBackdropClick={loading}
                disableEscapeKeyDown={loading}
                ref={ref}
                className={styles.dialog}>
            <h2 className={styles.title}>{title}</h2>
            <hr/>
            <div className={styles.dialog_content}>
                {children}
            </div>
            <Button variant={"contained"} loading={loading} disabled={loading} red onClick={onPositivePress} {...positiveButtonProps}>{positiveText}</Button>
            <Button variant={"text"} disabled={loading} onClick={onNegativePress} {...negativeButtonProps}>{negativeText}</Button>
        </Dialog>
    );
}

export default React.forwardRef(WarningDialog);