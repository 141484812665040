import { USER_ROLES } from "../types/SafeswimUser";

const routes = {
    login: "/login",
    forgotPassword: "/forgot-password",
    locations: {
        permissions: [USER_ROLES.SUPER_ADMIN, USER_ROLES.REGIONAL_ADMIN],
        root: "/locations",
        overview: "/locations/overview",
        createLocation: "/locations/create",
        facilities: "/locations/facilities",
        createFacility: "/locations/facilities/create",
        hazards: "/locations/hazards",
        createHazard: "/locations/hazards/create",
        traces: "/locations/traces/:locationId"
    },
    alerts: {
        permissions: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SLS, USER_ROLES.WATERCARE, USER_ROLES.REGIONAL_ADMIN],
        root: "/alerts",
        current: "/alerts/current",
        past: "/alerts/past",
        createCurrentAlert: "/alerts/current/create"

    },
    events: {
        permissions: [USER_ROLES.SUPER_ADMIN],
        root: "/events",
        overview: "/events/overview",
        deleted: "/events/deleted",
        create: "/events/create",
    },
    sls: {
        permissions: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SLS],
        root: "/surf-lifesaving",
        locations: "/surf-lifesaving/locations",
        hazards: "/surf-lifesaving/hazards",
        patrol: "/surf-lifesaving/patrol",
        createPatrol: "/surf-lifesaving/patrol/create",
        offShore: "/surf-lifesaving/off-shore",
    },
    admin: {
        permissions: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SLS, USER_ROLES.WATERCARE],
        root: "/admin",
        override: "/admin/override",
        users: "/admin/users",
        apiKeys: "/admin/api",
        tags: "/admin/tags",
        digitalSigns: "/admin/digital-signs",
        offShore: "/admin/off-shore-alerts",
        logs: "/admin/logs",
        waterQualityReport: "/admin/reports/water-quality",
        digitalSignStatus: '/admin/digital-sign-status',
        quality: "/admin/quality",
        safety: "/admin/safety",
        createWaterQualityAlertType: "/admin/alerts/quality/create",
        createWaterSafetyAlertType: "/admin/alerts/safety/create",
    }
};

export default routes;
