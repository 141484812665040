import useMountEffect from "hooks/useMountEffect";
import { Ref, useImperativeHandle, useState } from "react";
import ListResult from "types/ListResult";
import Table, { TableProps } from "./Table";
import styles from "./table.module.scss";
import { isWithinFourHours } from "redux/actions/api/util/utls";
import Alert from "types/Alert";

type PagingTableProps<T extends object> = {
    tableRef?: Ref<PagingTableRef>;
    getData: (filter: PagingTableFilter) => Promise<ListResult<T>>;
} & Omit<TableProps<T>, "data">;

const initState: State<any> = {
    pages: [],
};

export default function PagingTable<T extends object>(
    props: PagingTableProps<T>
) {
    const { options, tableRef, title, getData, components, ...otherProps } =
        props;
    const defaultPageSize = props.defaultPageSize ?? 500;

    const [loading, setLoading] = useState<boolean>(false);
    const [state, setState] = useState<State<T>>(initState);
    const [filter, setFilter] = useState<PagingTableFilter>({
        limit: defaultPageSize,
    });
    const [data, setData] = useState<T[]>([]);
    const [page, setPage] = useState<number>(0);

    useMountEffect(async () => {
        setState({
            pages: [
                {
                    data: [] as T[],
                    limit: filter.limit,
                    nextCursor: undefined,
                },
            ],
        });
        await fetchData(0, filter);
    });

    const fetchData = async (
        page: number,
        filter: PagingTableFilter,
        refresh?: boolean
    ) => {
        try {
            if (page !== 0 && (!filter.cursor || loading)) {
                // invalid query, i.e. not first page and no next cursor, or page number is less than 1
                return;
            }
            setPage(page);
            setLoading(true);
            const newData = await getData({ ...filter });
            const { items, cursor: nextCursor } = newData;
            if (refresh) {
                setState({
                    pages: [
                        {
                            data: items,
                            limit: filter.limit,
                            nextCursor,
                        },
                    ],
                });
            } else {
                setState((state) => {
                    state.pages[page] = {
                        data: items,
                        limit: filter.limit,
                        nextCursor,
                    };
                    return state;
                });
            }
            let allData: T[] = [];
            if (!refresh) {
                for (let p = 0; p < page; p++) {
                    const d = state.pages[p].data ?? [];
                    if (d.length > 0) {
                        allData = allData.concat(d);
                    }
                }
            }
            allData = allData.concat(items);
            if (nextCursor) {
                allData.push({} as T);
            }
            setData(allData);
        } catch (error) {
            console.log("[Table] - error", error);
        } finally {
            setLoading(false);
        }
    };

    const onChangePage = (newPage: number) => {
        console.log("[Table] - onChangePage", newPage);
        setPage(newPage);
        if (newPage > 0 && page < newPage && !state.pages[newPage]) {
            const cursor = state.pages[newPage - 1].nextCursor;
            fetchData(newPage, { ...filter, cursor });
        }
    };

    const onChangeRowsPerPage = (limit: number) => {
        setFilter((filter) => ({
            ...filter,
            limit,
        }));
        fetchData(0, { limit }, true);
    };

    const refresh = async () => {
        console.log("[Table] - refresh");
        await fetchData(0, { limit: filter.limit }, true);
    };

    useImperativeHandle(tableRef, () => ({
        refresh,
    }));

    return (
        <Table<T>
            {...otherProps}
            components={{
                ...components,
                Container: (ContainerProps: any) => (
                    <div {...ContainerProps} className={styles.table} />
                ),
            }}
            title={title}
            defaultPageSize={defaultPageSize}
            data={data}
            loading={loading}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            page={page}
            options={{
                initialPage: page,
                showTitle: !!title,
                pageSize: filter.limit,
                emptyRowsWhenPaging: false,
                rowStyle: (transaction: Alert | any): React.CSSProperties => {
                    if (!!transaction) {
                        if (
                            !!transaction.to &&
                            !transaction.expired &&
                            !transaction.deleted &&
                            isWithinFourHours(transaction.to)
                        ) {
                            return {
                                backgroundColor: "#CF1919",
                                color: "white",
                            };
                        } else {
                            return {};
                        }
                    }
                    return { display: "none" };
                },
                paginationType: "stepped",
                showFirstLastPageButtons: false,
                paging: true,
                ...options,
            }}
        />
    );
}

export type PagingTableRef = {
    refresh: () => void;
};

export type PagingTableFilter = {
    limit: number;
    cursor?: string;
};

type State<T> = {
    pages: Array<{
        data: T[];
        limit: number;
        nextCursor?: string;
    }>;
};
