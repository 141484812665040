import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useMountEffect from "../../../../hooks/useMountEffect";
import { showError } from "../../../../redux/actions/snackbars";
import useDispatch from "../../../../hooks/useDispatch";
import Spinner from "components/widgets/spinner/Spinner";
import { FieldArrayRenderProps, FieldArray, Formik, useFormikContext, Form } from "formik";
import { SafeswimTrace } from "../../../../types/Trace";
import FormikInput from "components/widgets/input/FormikInput";
import styles from "./Traces.module.scss";
import Button from "components/widgets/button/Button";
import routes from "../../../../constants/routes";
import LocationAPI from "../../../../redux/actions/api/Locations";
import Dialog, { DialogRef } from "../../../widgets/dialog/Dialog";
import useSelector from "../../../../hooks/useSelector";
import { getH2Locations } from "../../../../redux/actions/locations";
import H2Location from "../../../../types/H2Location";
import orderBy from "lodash/orderBy";

const TraceFieldArray = (props: {
    locationTraces: SafeswimTrace[],
    moataTraces: SafeswimTrace[],
    locationId: number,
    onMoataLocationChange: (locationId: number) => void,
} & FieldArrayRenderProps) => {
    const { locationTraces, moataTraces, locationId, onMoataLocationChange, ...otherProps } = props;
    const { values: { traces } } = useFormikContext<{ traces: SafeswimTrace[] }>();
    const traceDialog = useRef<DialogRef>(null);
    const locations = useSelector((state) => state.locations.h2Locations);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const handleDelete = (index: number) => {
        otherProps.remove(index);
    };

    const onTraceSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const traceId = Number(e.target.value);
        const trace = moataTraces.filter((t) => t.id === traceId)[0];
        const existingTrace = traces.filter((t) => t.id === traceId);
        if (trace && !existingTrace.length) {
            otherProps.push(trace);
        }
    };

    const onLocationSelect = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            setLoading(true);
            await onMoataLocationChange(Number(e.target.value));
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {traces.map((trace, index) => (
                <div key={`traces[${index}]`} className={styles.trace}>
                    <FormikInput name={`traces[${index}].id`} label={`${trace.name} - ${trace.description}`} />
                    <Button onClick={() => handleDelete(index)} className={styles.button}>Delete</Button>
                </div>
            ))}
            <Button onClick={() => traceDialog.current?.show()}>+ Add</Button>
            <Dialog ref={traceDialog} containerClassName={styles.trace_dialog}>
                <div>
                    <label>
                        Select moata location to get traces from
                    </label>
                    <select defaultValue={locationId} onChange={onLocationSelect} style={{ width: "100%" }}>
                        {orderBy(locations, ["name"]).map((l: H2Location) => (
                            <option key={l.id} value={l.id}>{l.name} - {l.id}</option>
                        ))}
                    </select>
                    <label>
                        Select moata trace to add
                    </label>
                    <select onChange={onTraceSelect} disabled={loading}>
                        <option></option>
                        {moataTraces.map((t) => (
                            <option key={t.id} value={t.id}>{t.id} - {t.name}</option>
                        ))}
                    </select>
                </div>
                <Button red onClick={() => traceDialog.current?.hide()}>Close</Button>
            </Dialog>
        </div>
    );
};

export default function Traces() {
    const { locationId } = useParams<{ locationId: string }>();
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [traces, setTraces] = useState<SafeswimTrace[]>([]);
    const [moataTraces, setMoataTraces] = useState<SafeswimTrace[]>([]);

    useMountEffect(async () => {
        try {
            const [traces, moataTraces] = await Promise.all([
                LocationAPI.getLocationTraces(Number(locationId)),
                LocationAPI.getMoataLocationTraces(Number(locationId)),
                dispatch(getH2Locations())
            ]);
            setTraces(traces);
            setMoataTraces(moataTraces);
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    });

    const onMoataLocationChange = async (locationId: number) => {
        const traces = await LocationAPI.getMoataLocationTraces(Number(locationId));
        setMoataTraces(traces);
    };

    if (loading) {
        return <Spinner center />;
    }

    const handleSubmit = async (values: { traces: SafeswimTrace[] }) => {
        setLoading(true);
        try {
            const traces = await LocationAPI.updateLocationTraces(Number(locationId), values.traces);
            setTraces(traces);
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    };

    const handleCancelClick = () => {
        history.push(routes.locations.overview);
    };

    return (
        <div className={styles.traces}>
            <Formik initialValues={{ traces }} onSubmit={handleSubmit}>
                {() => (
                    <Form>
                        <FieldArray name={"traces"}
                                    render={(props) => (
                                        <TraceFieldArray {...props}
                                                         locationId={Number(locationId)}
                                                         locationTraces={traces}
                                                         moataTraces={moataTraces}
                                                         onMoataLocationChange={onMoataLocationChange}
                                        />
                                    )} />
                        <Button type={"submit"} className={styles.submit_button}>Save</Button>
                        <Button type={"button"} plain onClick={handleCancelClick}>Cancel</Button>
                    </Form>
                )}
            </Formik>

        </div>
    );
}
