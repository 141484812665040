import React, { useEffect, useRef, useState } from "react";
import useSelector from "../../../hooks/useSelector";
import useMountEffect from "../../../hooks/useMountEffect";
import { showError } from "../../../redux/actions/snackbars";
import Alert from "../../../types/Alert";
import { getUsernames } from "../../../redux/actions/users";
import useDispatch from "hooks/useDispatch";
import PagingTable, {
    PagingTableFilter,
    PagingTableRef,
} from "components/widgets/table/PagingTable";
import { Column } from "@material-table/core";
import styles from "../current/currentAlerts.module.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AlertsAPI from "../../../redux/actions/api/Alerts";
import { compareDates, formatDate } from "../../../redux/actions/api/util/utls";

function AlertTable(props: { type: string; show: boolean }) {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const usernames = useSelector((state) => state.users.usernames);
    const ref = useRef<PagingTableRef>(null);

    useMountEffect(async () => {
        if (!Object.values(usernames || {}).length) {
            setLoading(true);
            try {
                await dispatch(getUsernames());
            } catch (e: any) {
                dispatch(showError(e.message));
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        if (ref.current) {
            ref.current.refresh();
        }
    }, []);

    const getData = async (filter: PagingTableFilter) => {
        const { limit, cursor } = filter;
        return await AlertsAPI.getAllAlerts(cursor, limit, props.type);
    };

    const columns: Column<Alert>[] = [
        {
            title: "Title",
            field: "title",
        },
        {
            title: "Locations",
            render: ({ locations }: Alert) =>
                locations?.map((l) => l.locationName).join(","),
            customSort: (a: Alert, b: Alert) => {
                if (!a.locations || !b.locations) {
                    return 0;
                }
                return a.locations[0].locationName.localeCompare(
                    b.locations[0].locationName
                );
            },
        },
        {
            title: "From",
            render: ({ from }: Alert) => formatDate(from),
            customSort: (a, b) =>
                compareDates(new Date(a.from), new Date(b.from)),
        },
        {
            title: "To",
            render: ({ to }: Alert) => formatDate(to),
            customSort: (a, b) =>
                compareDates(new Date(a.from), new Date(b.from)),
        },
        {
            title: "Deleted by",
            field: "deletedBy",
            render: ({ deletedBy, expired }: Alert) => {
                if (deletedBy) {
                    return <span>{usernames[deletedBy]?.firstName}</span>;
                } else if (expired) {
                    return <span>Expired</span>;
                } else {
                    return null;
                }
            },
        },
        {
            title: "Reason",
            field: "deleteReason",
        },
    ];

    return (
        <PagingTable<Alert>
            tableRef={ref}
            columns={columns}
            loading={loading}
            getData={getData}
            defaultPageSize={100}
            totalCount={20000}
        />
    );
}

export default function PastAlerts() {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <>
            <div className={styles.header}>
                <h1>Past Alerts</h1>
            </div>
            <Tabs
                className={"tabs"}
                onSelect={(value: any) => {
                    setSelectedTab(value);
                }}
            >
                <TabList className={"tab_list"} value={selectedTab}>
                    <Tab
                        selectedClassName={"active"}
                        selected={selectedTab === 0}
                    >
                        Water Quality
                    </Tab>
                    <Tab
                        selectedClassName={"active"}
                        selected={selectedTab === 1}
                    >
                        Water Safety
                    </Tab>
                </TabList>
                <TabPanel>
                    <AlertTable
                        type={"water_quality"}
                        show={selectedTab === 0}
                    />
                </TabPanel>
                <TabPanel>
                    <AlertTable type={"hazards"} show={selectedTab === 1} />
                </TabPanel>
            </Tabs>
        </>
    );
}
