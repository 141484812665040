import { useCallback, useRef, useState } from "react";
import useSelector from "../../../hooks/useSelector";
import useMountEffect from "../../../hooks/useMountEffect";
import { deleteAlert, getAlerts } from "../../../redux/actions/alerts";
import Alert from "../../../types/Alert";
import { DialogRef } from "components/widgets/dialog/Dialog";
import FormikInput from "components/widgets/input/FormikInput";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import WarningDialog from "../../widgets/dialog/warningDialog/WarningDialog";
import Options from "../../widgets/options/Options";
import { showError } from "../../../redux/actions/snackbars";
import routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { getUsernames } from "../../../redux/actions/users";
import useAdminState from "hooks/useAdminState";
import { Column } from "@material-table/core";
import {
    compareDates,
    formatDate,
    isWithinFourHours,
} from "redux/actions/api/util/utls";
import PagingTable, {
    PagingTableFilter,
} from "components/widgets/table/PagingTable";
import useDispatch from "hooks/useDispatch";
import { ALERT_TYPE } from "types/AlertType";
import Button from "../../widgets/button/Button";
import styles from "./currentAlerts.module.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

type DeleteForm = {
    reason: string;
    typedReason: string;
};

//TODO Add delete Reasons here
const deleteReasons = [
    "Sensor malfunction",
    "Error in data from Moata",
    "Site visit complete - confirmed minor health risk",
    "Site visit complete - confirmed contamination event over",
    "Operator judgment - minor health risk",
    "Opertor judgment - contamination event over",
    "Safety warning no longer needed",
    "Rahui has been lifted",
    "False alarm. No [wastewater] overflow",
    "Site visit complete – confirmed no [wastewater] overflow",
];

function AlertTable(props: { type: string }) {
    const [loading, setLoading] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const alerts: any = useSelector((state) => state.alerts);
    const [alert, setAlert] = useState<Alert>();
    const formRef = useRef<any>(null);
    const { usernames } = useSelector((state) => state.users);
    const isWaterSafety = alert?.alertType === ALERT_TYPE.WATER_QUALITY;
    const deleteDialog = useRef<DialogRef>(null);
    const deleteSchema = isWaterSafety
        ? Yup.object().shape({
            reason: Yup.string().when(["typedReason"], {
                is: (typedReason: string) => typedReason === "",
                then: Yup.string().required("Required"),
                otherwise: Yup.string(),
            }),
            typedReason: Yup.string(),
        })
        : Yup.object({ reason: Yup.string(), typedReason: Yup.string() });
    const { isRegionalAdmin, assignedRegions } = useAdminState();
    useMountEffect(async () => {
        if (!alerts?.items?.length) {
            setLoading(true);
            try {
                await dispatch(
                    getAlerts(false, undefined, undefined, props.type)
                );
                if (!Object.values(usernames || {}).length) {
                    await dispatch(getUsernames());
                }
            } catch (e: any) {
                dispatch(showError(e.message));
            } finally {
                setLoading(false);
            }
        }
    });

    const onEditPress = useCallback(
        (alert: Alert) => {
            setAlert(alert);
            history.push(
                `${routes.alerts.createCurrentAlert}?editing=${alert.id}`
            );
        },
        [history]
    );

    const onDeletePress = useCallback(
        (alert: Alert) => {
            setAlert(alert);
            deleteDialog.current?.show();
        },
        [deleteDialog]
    );

    const onDeleteSubmit = useCallback(
        async (values: DeleteForm, helper: FormikHelpers<DeleteForm>) => {
            helper.setSubmitting(false);
            if (!alert) {
                return;
            }

            if (alert.alertType === ALERT_TYPE.HAZARDS) {
                setDeleting(true);
                await dispatch(deleteAlert(alert.id, ""));
                formRef?.current?.resetForm();
                setAlert(undefined);
                deleteDialog.current?.hide();
                setDeleting(false);
            } else {
                if (values.reason) {
                    try {
                        setDeleting(true);
                        await dispatch(deleteAlert(alert.id, values.reason));
                        formRef?.current?.resetForm();
                    } catch (e: any) {
                        dispatch(showError(`${e.message}`));
                    } finally {
                        setDeleting(false);
                        setAlert(undefined);
                        deleteDialog.current?.hide();
                    }
                } else if (values.typedReason) {
                    try {
                        setDeleting(true);
                        await dispatch(deleteAlert(alert.id, values.typedReason));
                        formRef?.current?.resetForm();
                    } catch (e: any) {
                        dispatch(showError(`${e.message}`));
                    } finally {
                        setDeleting(false);
                        setAlert(undefined);
                        deleteDialog.current?.hide();
                    }
                }
                if (!values.reason || !values.typedReason) {
                    helper.setFieldError("reason", "Required one field");
                    helper.setFieldError("typedReason", "Required one field");
                }
            }



        },
        [alert, deleteDialog, dispatch]
    );

    const columns: Column<Alert>[] = [
        {
            title: "Title",
            field: "title",
            render: ({ title }: Alert) => {
                return <div>{title}</div>;
            },
        },
        {
            title: "Locations",
            render: ({ locations }: Alert) => {
                return (
                    <>
                        <div>
                            {locations?.map((l) => l.locationName).join(",")}
                        </div>
                    </>
                );
            },
            customSort: (a: Alert, b: Alert) =>
                a.locations[0].locationName.localeCompare(
                    b.locations[0].locationName
                ),
        },
        {
            title: "From",
            render: ({ from }: Alert) => {
                return <div>{formatDate(from)}</div>;
            },
            customSort: (a, b) =>
                compareDates(new Date(a.from), new Date(b.from)),
        },
        {
            title: "To",
            render: ({ to }: Alert) => {
                return <div>{formatDate(to)}</div>;
            },
            customSort: (a, b) =>
                compareDates(new Date(a.from), new Date(b.from)),
        },
        {
            title: "Created by",
            field: "createdBy",
            render: ({ createdBy }: Alert) => {
                if (createdBy) {
                    return (
                        <div>
                            <span>{usernames[createdBy]?.firstName}</span>
                        </div>
                    );
                }
            },
        },
        {
            title: "Updated by",
            field: "updatedBy",
            render: ({ updatedBy }: Alert) => {
                if (updatedBy) {
                    return (
                        <div>
                            <span>{usernames[updatedBy]?.firstName}</span>
                        </div>
                    );
                }
            },
        },
        {
            render: (alert: Alert) => {
                let controlEnabled = true;
                if (isRegionalAdmin) {
                    let enabledForRA = false;
                    for (let r of assignedRegions) {
                        if (alert.regionBasedList?.includes(r)) {
                            enabledForRA = true;
                            break;
                        }
                    }
                    controlEnabled = enabledForRA;
                }
                return (
                    controlEnabled && (
                        <Options
                            data={alert}
                            toRight
                            primaryButtonText={"View/Edit"}
                            onPrimaryPress={onEditPress}
                            secondaryButtonText={"Delete"}
                            onSecondaryPress={onDeletePress}
                        />
                    )
                );
            },
        },
    ];

    const getData = ({ limit, cursor }: PagingTableFilter) => {
        return dispatch(getAlerts(false, cursor, limit, props.type));
    };

    return (
        <>
            <PagingTable<Alert>
                getData={getData}
                columns={columns}
                loading={loading}
            />
            <Formik
                initialValues={{ reason: "", typedReason: "" }}
                onSubmit={onDeleteSubmit}
                validationSchema={deleteSchema}
                innerRef={formRef}
            >
                {({ isValid, dirty, handleSubmit, resetForm }) => {
                    return (
                        <Form>
                            <WarningDialog
                                title={"Delete current alert"}
                                positiveText={"Delete alert"}
                                onPositivePress={() => handleSubmit()}
                                positiveButtonProps={{
                                    disabled: !isValid || deleting,
                                }}
                                negativeText={"Cancel"}
                                onNegativePress={() => {
                                    deleteDialog.current?.hide();
                                    resetForm();
                                }}
                                loading={deleting}
                                ref={deleteDialog}
                            >
                                <span>{`Are you sure you would like to delete ${alert?.title}`}</span>
                                <span style={{ fontWeight: "bold" }}>
                                    This action can not be undone and will be
                                    removed from all locations using this
                                    hazard.
                                </span>
                                {isWaterSafety && (
                                    <>
                                        <FormikInput
                                            select
                                            label="Please choose a reason"
                                            placeholder="Please chose a reason"
                                            name="reason"
                                            options={deleteReasons.map((s) => ({
                                                value: s,
                                                label: s,
                                            }))}
                                        />
                                        <FormikInput
                                            label="Or write a reason below..."
                                            name="typedReason"
                                        />
                                    </>
                                )}
                            </WarningDialog>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export default function CurrentAlerts() {
    const [selectedTab, setSelectedTab] = useState(0);
    const history = useHistory();

    const handleCreateAlert = () => {
        history.push(routes.alerts.createCurrentAlert);
    };

    return (
        <>
            <div className={styles.header}>
                <h1>Current Alerts</h1>
                <Button variant="contained" onClick={handleCreateAlert}>
                    Create alert
                </Button>
            </div>
            <Tabs className={"tabs"}>
                <TabList
                    className={"tab_list"}
                    value={selectedTab}
                    onSelect={(value: any) => {
                        setSelectedTab(value);
                    }}
                >
                    <Tab
                        selectedClassName={"active"}
                        selected={selectedTab === 0}
                    >
                        Water Quality
                    </Tab>
                    <Tab
                        selectedClassName={"active"}
                        selected={selectedTab === 1}
                    >
                        Water Safety
                    </Tab>
                </TabList>
                <TabPanel>
                    <AlertTable type={"water_quality"} />
                </TabPanel>
                <TabPanel>
                    <AlertTable type={"hazards"} />
                </TabPanel>
            </Tabs>
        </>
    );
}
