import React, { useRef } from "react";
import classNames from "classnames";
import useMountEffect from "../../../hooks/useMountEffect";
import styles from "./map.module.scss";
import { v4 } from "uuid";

type Props = {
    name?: string;
    onLocationSet?: (latLng: google.maps.LatLngLiteral) => void;
    mapOptions?: google.maps.MapOptions;
    mapStyle?: string;
    markers?: any,
    initialCenter?: google.maps.LatLngLiteral
};

const auckland = { lat: -36.8483079, lng: 174.761712 };

export default function Map(props: Props) {
    const mapRef = useRef<HTMLDivElement>(null);
    let marker: google.maps.Marker = new google.maps.Marker();
    const searchboxId = v4();
    if (props.onLocationSet && props.initialCenter) {
        // set initial value for marker set by user
        const pos = props.initialCenter;
        marker.setPosition(pos);
        marker.setLabel(`lat: ${pos.lat.toFixed(3)}, lng: ${pos.lng.toFixed(3)}`);
    }

    useMountEffect(() => {
        if (mapRef.current) {
            const map = new google.maps.Map(
                mapRef.current,
                {
                    zoom: 14,
                    center: props.initialCenter || auckland,
                    mapTypeControl: false,
                    scaleControl: false,
                    fullscreenControl: false,
                    streetViewControl: false
                }
            );

            if (props.initialCenter) {
                marker.setMap(map);
            }

            if (props.onLocationSet !== undefined) {
                // add listener to set marker on user click
                map.addListener("click", (mapsMouseEvent: any) => {
                    const pos = mapsMouseEvent.latLng;
                    // @ts-ignore
                    props.onLocationSet({ lat: pos.lat(), lng: pos.lng() });
                    marker.setMap(null);
                    marker = new google.maps.Marker({
                        position: pos,
                    });
                    marker?.setLabel?.(`lat: ${pos.lat().toFixed(3)}, lng: ${pos.lng().toFixed(3)}`);
                    marker?.setMap(map);
                });
            }

            // Create the search box and link it to the UI element.
            const input = document.getElementById(searchboxId) as HTMLInputElement;
            const searchBox = new google.maps.places.SearchBox(input);
            map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
            });

            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
            });

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places?.length === 0) {
                    return;
                }

                // Clear out the old markers.
                marker.setMap(null);

                // For first place, get the name and location.
                const bounds = new google.maps.LatLngBounds();
                const placeResult = places![0];

                if (!placeResult.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                // Create a marker for each place.
                const pos = placeResult.geometry.location;
                marker = new google.maps.Marker({
                    map,
                    title: placeResult.name,
                    label: `lat: ${pos?.lat().toFixed(3)}, lng: ${pos?.lng().toFixed(3)}`,
                    position: pos,
                });

                if (props.onLocationSet) {
                    props.onLocationSet({ lat: pos!.lat(), lng: pos!.lng() });
                }

                if (placeResult.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(placeResult.geometry.viewport);
                } else {
                    bounds.extend(placeResult.geometry.location!);
                }
                map.fitBounds(bounds);
            });
        }
    });

    return (
        <>
            <div id={v4()}
                 ref={mapRef}
                 className={classNames(styles.map, props.mapStyle)} />
            <input id={searchboxId} type={"text"} placeholder={"Search for a location..."}
                   className={styles.search_input} />
        </>
    );
}

