import React, { useMemo, useRef, useState } from "react";
import Table from "../../widgets/table/Table";
import useMountEffect from "../../../hooks/useMountEffect";
import {
    deleteLocation,
    getLocations,
    setLocationAvailable,
    setLocationUnavailable,
} from "../../../redux/actions/locations";
import { useDispatch } from "react-redux";
import useSelector from "../../../hooks/useSelector";
import SafeswimLocation from "../../../types/SafeswimLocation";
import Popover, { PopoverRef } from "components/widgets/popover/Popover";
import { DialogRef } from "components/widgets/dialog/Dialog";
import { useHistory } from "react-router-dom";
import routes from "../../../constants/routes";
import Button from "components/widgets/button/Button";
import Options from "../../../resources/images/svg/options.svg";
import WarningDialog from "../../widgets/dialog/warningDialog/WarningDialog";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import styles from "./locationOverview.module.scss";
import { Column } from "@material-table/core";
import useAdminState from "hooks/useAdminState";

export default function LocationOverview() {
    const [loading, setLoading] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [editingLocation, setEditingLocation] =
        useState<SafeswimLocation | null>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { isSuperAdmin, isRegionalAdmin } = useAdminState();
    const popover = useRef<PopoverRef>(null);
    const deleteDialog = useRef<DialogRef>(null);

    const locations = useSelector<SafeswimLocation[]>(
        (state) => state.locations.locations
    );

    useMountEffect(async () => {
        if (!locations?.length) {
            setLoading(true);
            try {
                await dispatch(getLocations());
            } catch (e) {
                console.log("locations err", e);
            } finally {
                setLoading(false);
            }
        }
    });

    const handleCreateLocation = () => {
        history.push(routes.locations.createLocation);
    };

    const handleEditLocation = (location: SafeswimLocation | null) => {
        location &&
            history.push(
                `${routes.locations.createLocation}?editing=${location.id}`
            );
    };

    const handleDeleteLocation = async () => {
        try {
            setDeleting(true);
            await dispatch(deleteLocation(editingLocation?.id || 0));
            dispatch(showSuccess("Location deleted"));
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setDeleting(false);
        }
        deleteDialog.current?.hide();
    };

    const onShowPopover = (data: SafeswimLocation) => (e: any) => {
        setEditingLocation(data);
        popover.current?.show(e.currentTarget);
    };

    const toggleLocationAvailability = (loc: SafeswimLocation) => async () => {
        try {
            setLoading(true);
            if (loc.unavailable) {
                await dispatch(setLocationAvailable(loc.id));
            } else {
                await dispatch(setLocationUnavailable(loc.id));
            }
        } catch (e) {
            console.log("err", e);
        } finally {
            setLoading(false);
        }
    };

    const columns: Column<SafeswimLocation>[] = [
        {
            title: "Location name",
            field: "name",
        },
        {
            title: "Region",
            field: "region",
        },
        {
            title: "Latitude",
            field: "lat",
        },
        {
            title: "Longitude",
            field: "lng",
        },
    ];

    if (isRegionalAdmin || isSuperAdmin) {
        columns.push({
            title: "",
            field: "",
            render: (data: SafeswimLocation) => (
                <span
                    className={styles.availability}
                    onClick={toggleLocationAvailability(data)}
                >
                    {data.unavailable ? "Set available" : "Set unavailable"}
                </span>
            ),
        });

        columns.push({
            title: "",
            field: "",
            render: (data: SafeswimLocation) => {
                return (
                    <div style={{ textAlign: "right" }}>
                        <Button plain onClick={onShowPopover(data)}>
                            <img src={Options} alt={"Options"} />
                        </Button>
                        <Popover
                            popoverRef={popover}
                            className={styles.popover}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "center",
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: "left",
                            }}
                        >
                            <Button
                                plain
                                onClick={() => {
                                    handleEditLocation(editingLocation);
                                    popover.current?.hide();
                                }}
                            >
                                View/Edit
                            </Button>
                            {isSuperAdmin && (
                                <Button
                                    plain
                                    onClick={() => {
                                        deleteDialog.current?.show();
                                        popover.current?.hide();
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                            {isSuperAdmin && (
                                <Button
                                    plain
                                    onClick={() => {
                                        if (editingLocation) {
                                            history.push(
                                                `/locations/traces/${editingLocation.id}`
                                            );
                                        }
                                        popover.current?.hide();
                                    }}
                                >
                                    Edit Traces
                                </Button>
                            )}
                        </Popover>
                    </div>
                );
            },
        });
    }

    const actionButton =
        isSuperAdmin || isRegionalAdmin
            ? {
                  text: "Create Location",
                  action: handleCreateLocation,
              }
            : undefined;

    const sortedAllLocations = useMemo(() => {
        return locations.sort((a, b) => a.name.localeCompare(b.name));
    }, [locations]);

    return (
        <>
            <Table<any>
                data={sortedAllLocations}
                columns={columns}
                loading={loading}
                heading={"All Locations"}
                actionButton={actionButton}
            />
            <WarningDialog
                title={"Delete location"}
                positiveText={"Delete location"}
                onPositivePress={handleDeleteLocation}
                negativeText={"Cancel"}
                onNegativePress={deleteDialog.current?.hide}
                loading={deleting}
                ref={deleteDialog}
            >
                <span>{`Are you sure you would like to delete ${editingLocation?.name}`}</span>
                <span style={{ fontWeight: "bold" }}>
                    This action can not be undone.
                </span>
            </WarningDialog>
        </>
    );
}
