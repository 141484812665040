import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useMountEffect from "../../../hooks/useMountEffect";
import useSelector from "../../../hooks/useSelector";
import Popover, { PopoverRef } from "components/widgets/popover/Popover";
import Button from "components/widgets/button/Button";
import Options from "../../../resources/images/svg/options.svg";
import styles from "../../alerts/current/currentAlerts.module.scss";
import Table from "../../widgets/table/Table";
import Hazard from "../../../types/Hazard";
import { deleteHazard, getHazards } from "../../../redux/actions/hazards";
import { useHistory } from "react-router-dom";
import routes from "../../../constants/routes";
import WarningDialog from "../../widgets/dialog/warningDialog/WarningDialog";
import { DialogRef } from "components/widgets/dialog/Dialog";
import { showError, showSuccess } from "../../../redux/actions/snackbars";

export default function LocationHazards() {
    const [loading, setLoading] = useState<boolean>(false);
    const [editingHazard, setEditingHazard] = useState<Hazard | null>(null);
    const [deleting, setDeleting] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const deleteDialog = useRef<DialogRef>(null);
    const { items }: any = useSelector((state) => state.hazards);
    const popover = useRef<PopoverRef>(null);

    useMountEffect(async () => {
        if (!items?.length) {
            setLoading(true);
            try {
                await dispatch(getHazards());
            } catch (e) {
                dispatch(showError("Error retrieving hazards"));
            } finally {
                setLoading(false);
            }
        }
    });

    const handleCreateHazard = () => {
        history.push(routes.locations.createHazard);
    };

    const handleEditHazard = (hazard: Hazard | null) => {
        hazard &&
            history.push(
                `${routes.locations.createHazard}?editing=${hazard.id}`
            );
    };

    const handleDeleteHazard = async () => {
        try {
            if (editingHazard) {
                setDeleting(true);
                await dispatch(deleteHazard(editingHazard.id));
                dispatch(showSuccess("Hazard successfully deleted"));
                deleteDialog.current?.hide();
            }
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setDeleting(false);
        }
    };

    const onShowPopover = (hazard: Hazard) => (e: any) => {
        setEditingHazard(hazard);
        popover.current?.show(e.currentTarget);
    };

    const columns = [
        {
            title: "Hazard name",
            field: "title",
        },
        {
            title: "Icon",
            render: (hazard: Hazard) =>
                hazard.icon ? (
                    <img src={hazard.icon} height={80} width={80} alt={""} />
                ) : null,
        },
        {
            field: "",
            render: (hazard: Hazard) => (
                <div style={{ textAlign: "right" }}>
                    <Button plain onClick={onShowPopover(hazard)}>
                        <img src={Options} alt={"Options"} />
                    </Button>
                    <Popover
                        popoverRef={popover}
                        className={styles.popover}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "center",
                        }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "left",
                        }}
                    >
                        <Button
                            plain
                            onClick={() => {
                                handleEditHazard(editingHazard);
                                popover.current?.hide();
                            }}
                        >
                            View/Edit
                        </Button>
                        <Button
                            plain
                            onClick={() => {
                                popover.current?.hide();
                                deleteDialog.current?.show();
                            }}
                        >
                            Delete
                        </Button>
                    </Popover>
                </div>
            ),
        },
    ];

    return (
        <>
            <Table
                data={Object.values(items) as any}
                columns={columns}
                loading={loading}
                heading={"Location Hazards"}
                actionButton={{
                    text: "Create hazard",
                    action: handleCreateHazard,
                }}
            />
            <WarningDialog
                title={"Delete hazard"}
                positiveText={"Delete hazard"}
                onPositivePress={handleDeleteHazard}
                negativeText={"Cancel"}
                onNegativePress={deleteDialog.current?.hide}
                loading={deleting}
                ref={deleteDialog}
            >
                <span>{`Are you sure you would like to delete ${editingHazard?.title}`}</span>
                <span style={{ fontWeight: "bold" }}>
                    This action can not be undone and will be removed from all
                    locations using this hazard.
                </span>
            </WarningDialog>
        </>
    );
}
