import { orderBy } from "lodash";
import WaterQuality from "../../types/WaterQuality";
import { Types } from "../actions/waterQuality";

const initialState: { report: WaterQuality[] } = {
    report: []
};

export default function waterQuality(state = initialState, action: any) {
    switch (action.type) {
        case Types.WATER_QUALITY_RECEIVED: {
            const ordered = orderBy(action.payload, ["recordedAt"]).filter((w: WaterQuality) => {
                const recordedHours = new Date(w.recordedAt).getHours();
                return recordedHours >= 5 && recordedHours < 23;
            });

            return {
                ...state,
                report: ordered
            };
        }
        default:
            return state;
    }
}
