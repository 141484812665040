import Button from "components/widgets/button/Button";
import Input from "components/widgets/input/Input";
import PagingTable, {
    PagingTableFilter,
    PagingTableRef,
} from "components/widgets/table/PagingTable";
import routes from "constants/routes";
import useDispatch from "hooks/useDispatch";
import useMountEffect from "hooks/useMountEffect";
import useSelector from "hooks/useSelector";
import { Column } from "@material-table/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getEntityChanges } from "redux/actions/changes";
import { getLocations } from "redux/actions/locations";
import SafeswimChange from "types/SafeswimChange";
import SafeswimLocation from "types/SafeswimLocation";
import { compareDates } from "redux/actions/api/util/utls";
// import styles from './ChangeLogs.module.scss';

const LoggableEntityTypes = ["Location"];

export default function ChangeLogs() {
    const [loading, setLoading] = useState<boolean>(false);
    const [entity, setEntity] = useState<string>(LoggableEntityTypes[0]);
    const locations = useSelector(
        (state) => state.locations.locations
    ) as SafeswimLocation[];
    const dispatch = useDispatch();
    const history = useHistory();
    const tableRef = useRef<PagingTableRef>(null);

    const selectable = false; //todo: make it selectable when we have more than one entity that need to be audited

    useMountEffect(async () => {
        setLoading(true);
        try {
            if (!locations || !locations.length) {
                await dispatch(getLocations());
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    });

    useEffect(() => {
        if (entity) {
            tableRef.current?.refresh();
        }
    }, [tableRef, entity]);

    const getData = useCallback(
        async (filter: PagingTableFilter) => {
            const { limit, cursor } = filter;
            return dispatch(getEntityChanges(entity, cursor, limit));
        },
        [entity, dispatch]
    );

    const columns: Column<SafeswimChange>[] = [
        {
            title: "Changed on",
            render: (c) => {
                //todo: need to check the selected entity type when more entity types are audited
                const location = locations?.find((l) => l.id === c.entityId);
                const onLocationPress = () => {
                    if (location?.id) {
                        history.push(
                            `${routes.locations.createLocation}?editing=${location.id}`
                        );
                    }
                };
                return (
                    <Button plain onClick={onLocationPress}>
                        {location?.name ?? "unknown location"}
                    </Button>
                );
            },
            customSort: (a: SafeswimChange, b: SafeswimChange) => {
                const locationA = locations?.find((l) => l.id === a.entityId);
                const locationB = locations?.find((l) => l.id === b.entityId);
                if (locationA && locationB)
                    return locationA.name.localeCompare(locationB.name);
                return 0;
            },
        },
        {
            title: "Changed by",
            render: (c) => `${c.changedBy?.firstName} ${c.changedBy?.lastName}`,
            customSort: (a: SafeswimChange, b: SafeswimChange) =>
                a.changedBy?.firstName.localeCompare(b.changedBy?.firstName),
        },
        {
            title: "Changed at",
            render: (c) =>
                new Date(c.changedAt).toLocaleTimeString("en-NZ", {
                    month: "numeric",
                    day: "numeric",
                    year: "numeric",
                    hour12: true,
                    hour: "2-digit",
                    minute: "2-digit",
                }),
            customSort: (a: SafeswimChange, b: SafeswimChange) =>
                compareDates(new Date(a.changedAt), new Date(b.changedAt)),
        },
        {
            title: "Changed fields",
            render: (c) => Object.keys(c.details ?? {}).join(", "),
        },
    ];

    return (
        <div>
            {selectable && (
                <Input
                    options={LoggableEntityTypes.map((v) => ({
                        value: v,
                        label: v,
                    }))}
                    value={entity}
                    onChange={(t) => setEntity(t.target.value)}
                />
            )}
            <PagingTable<SafeswimChange>
                columns={columns}
                heading={`Change Logs (${entity})`}
                getData={getData}
                loading={loading}
            />
        </div>
    );
}
