import React, { useRef, useCallback, MouseEvent, useMemo } from "react";
import styles from "./Options.module.scss";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover, { PopoverRef } from 'components/widgets/popover/Popover';
import Button from 'components/widgets/button/Button';
import classnames from 'classnames';

type Props<T> = {
    onPrimaryPress?: (data: T) => void,
    onSecondaryPress?: (data: T) => void,
    primaryButtonText?: string,
    secondaryButtonText?: string,
    data: T,
    toRight?: boolean
}
export default function Options<T>(props: Props<T>) {
    const { onPrimaryPress, onSecondaryPress, data, primaryButtonText, secondaryButtonText, toRight } = props;
    const ref = useRef<PopoverRef | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        ref.current?.show();
    };
    const onPrimaryPressHandler = useCallback(() => {
        ref.current?.hide();
        onPrimaryPress?.(data);
    }, [onPrimaryPress, data]);

    const onSecondaryPressHandler = useCallback(() => {
        ref.current?.hide();
        onSecondaryPress?.(data);
    }, [onSecondaryPress, data]);

    const containerClass = useMemo(() => classnames({
        [styles.to_right]: toRight
    }), [toRight]);

    return (
        <div className={containerClass}>
            <IconButton onClick={handleClick}> <MoreVertIcon /> </IconButton>
            <Popover popoverRef={ref}
                     className={styles.popover_options}
                     anchorEl={anchorEl}
                     anchorOrigin={{
                         vertical: "center",
                         horizontal: "right",
                     }}
                     transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                    }}>
                {onPrimaryPress && (
                    <Button className={styles.btns}
                            onClick={onPrimaryPressHandler}>
                        {primaryButtonText ?? `View/Edit`}
                    </Button>
                )}
                {onSecondaryPress && (
                    <Button className={styles.btns}
                            onClick={onSecondaryPressHandler}>
                        {secondaryButtonText ?? `Change status`}
                    </Button>
                )}
            </Popover>
        </div>
    );
}