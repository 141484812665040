import Facility from "../../types/Facility";
import { Types } from "../actions/facilities";

const initialState: {
    items: { [key: string]: Facility },
    cursor: string | null,
} = {
    items: {},
    cursor: null,
};

export default function facilities(state = initialState, action: any) {
    switch (action.type) {
        case Types.FACILITY_RECEIVED: {
            const newState = { ...state };
            const facility: Facility = action.payload;

            newState.items[facility.id] = facility;

            return newState;
        }

        case Types.FACILITIES_RECEIVED: {
            const { items, cursor } = action.payload;
            const newState = { ...state };

            items.forEach((facility: Facility) => {
                newState.items[facility.id] = facility;
            });
            newState.cursor = cursor;

            return newState;
        }

        case Types.FACILITY_DELETED: {
            const { items, cursor } = action.payload;
            const newState: {
                items: { [key: string]: Facility },
                cursor: string | null,
            } = {
                items: {},
                cursor: null,
            };

            items.forEach((facility: Facility) => {
                newState.items[facility.id] = facility;
            });
            newState.cursor = cursor;

            return newState;
        }

        default:
            return state;
    }
};
