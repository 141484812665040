import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useSelector from "../../../hooks/useSelector";
import Popover, { PopoverRef } from "components/widgets/popover/Popover";
import Button from "components/widgets/button/Button";
import useMountEffect from "../../../hooks/useMountEffect";
import { deleteAlertType, getAlertTypes } from "../../../redux/actions/alerts";
import AlertType from "../../../types/AlertType";
import Options from "../../../resources/images/svg/options.svg";
import styles from "../current/currentAlerts.module.scss";
import Table from "../../widgets/table/Table";
import routes from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { DialogRef } from "components/widgets/dialog/Dialog";
import WarningDialog from "../../widgets/dialog/warningDialog/WarningDialog";
import { USER_ROLES } from "../../../types/SafeswimUser";
import Alert from "../../../types/Alert";
import { getUsernames } from "../../../redux/actions/users";

export default function WaterSafetyAlerts() {

    const [loading, setLoading] = useState<boolean>(false);
    const [editingWaterSafetyAlertType, setEditingWaterSafetyType] = useState<AlertType | null>(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { usernames } = useSelector(state => state.users);
    const user = useSelector(state => state.auth.user);
    const { hazardTypes }: any = useSelector(state => state.alerts.alertTypes);

    const popover = useRef<PopoverRef>(null);
    const deleteDialog = useRef<DialogRef>(null);

    useMountEffect(async () => {
        if (!hazardTypes?.items?.length) {
            setLoading(true);
            try {
                await dispatch(getAlertTypes());
                if (!(Object.values(usernames || {}).length)) {
                    await dispatch(getUsernames());
                }
            } catch (e) {
                console.log("alerts err", e);
            } finally {
                setLoading(false);
            }
        }
    });
    const handleCreateAlertType = () => {
        history.push(routes.admin.createWaterSafetyAlertType);
    };

    const handleEditAlertType = (alertType: AlertType | null) => {
        alertType && history.push(`${routes.admin.createWaterSafetyAlertType}?editing=${alertType.id}`);
    };

    const onShowPopover = (alertType: AlertType) => (e: any) => {
        setEditingWaterSafetyType(alertType);
        popover.current?.show(e.currentTarget);
    };

    const handleDeleteWaterSafetyAlertType = async () => {
        try {
            setLoading(true);
            await dispatch(deleteAlertType(Number(editingWaterSafetyAlertType?.id)));
            deleteDialog.current?.hide();
        } catch (e) {
            console.log("delete alert error", e);
        } finally {
            setLoading(false);
        }
    };

    const onDeleteClick = (popover: any) => {
        popover.current?.hide();
        deleteDialog.current?.show();
    };

    const renderOptionsColumn = () => {
        switch (user.role) {
            case USER_ROLES.WATERCARE:
            case USER_ROLES.SLS:
                return () => null;
            default:
                return (alertType: AlertType) => (
                    <div key={alertType.id} style={{ textAlign: "right" }}>
                        <Button plain onClick={(e) => onShowPopover(alertType)(e)}><img src={Options}
                                                                                        alt={"Options"} /></Button>
                        <Popover popoverRef={popover} className={styles.popover}
                                 anchorOrigin={{ horizontal: "right", vertical: "center" }}
                                 transformOrigin={{
                                     vertical: "center",
                                     horizontal: "left",
                                 }}>
                            <Button plain onClick={() => {
                                popover.current?.hide();
                                handleEditAlertType(editingWaterSafetyAlertType);
                            }}>View/Edit</Button>
                            <Button plain onClick={() => onDeleteClick(popover)}>Delete</Button>
                        </Popover>
                    </div>
                );
        }
    };

    const columns = [
        {
            title: "Title",
            field: "title"
        },
        {
            title: "Description",
            field: "description"
        },
        {
            title: "Value",
            field: "value"
        },
        {
            title: "Created by",
            field: "createdBy",
            render: ({ createdBy }: Alert) => {
                if (createdBy) {
                    return <span>{usernames[createdBy]?.firstName}</span>;
                }
            }
        },
        {
            field: "",
            render: renderOptionsColumn()
        },
    ];

    const getHeaderButton = () => {
        switch (user.role) {
            case USER_ROLES.WATERCARE:
            case USER_ROLES.SLS:
                return undefined;
            default:
                return { text: "Create new", action: handleCreateAlertType };
        }
    };

    return (
        <>
            <Table data={Object.values(hazardTypes || {}) as any}
                   columns={columns as any}
                   loading={loading}
                   heading={"Water Safety Alert Types"}
                   actionButton={getHeaderButton()}
            />
            <WarningDialog title={"Delete alert type"}
                           positiveText={"Delete alert type"}
                           onPositivePress={handleDeleteWaterSafetyAlertType}
                           negativeText={"Cancel"}
                           onNegativePress={deleteDialog.current?.hide}
                           loading={loading}
                           ref={deleteDialog}>
                <span>{`Are you sure you would like to delete ${editingWaterSafetyAlertType?.title}`}</span>
                <span style={{ fontWeight: "bold" }}>This action can not be undone and will be removed from all locations using this alert type.</span>
            </WarningDialog>
        </>

    );
}
