import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useSelector from "../../../hooks/useSelector";
import useMountEffect from "../../../hooks/useMountEffect";
import Table from "../../widgets/table/Table";
import {
    getMaintenance,
    startMaintenance,
    stopMaintenance,
} from "../../../redux/actions/siteOverride";

export default function Override() {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const siteOverride: any = useSelector((state) => state.siteOverride);

    useMountEffect(async () => {
        if (!siteOverride?.items?.length) {
            setLoading(true);
            try {
                await dispatch(getMaintenance());
            } catch (e) {
                console.log("siteOverride err", e);
            } finally {
                setLoading(false);
            }
        }
    });

    const handleCreateMaintenance = async () => {
        try {
            setLoading(true);
            if (siteOverride.maintenance) {
                await dispatch(stopMaintenance());
            } else {
                await dispatch(startMaintenance());
            }
        } catch (e) {
            console.log("siteOverride err", e);
        } finally {
            setLoading(false);
        }
    };

    // const onShowPopover = (siteOverride: Maintenance) => (e: any) => {
    //     setEditingMaintenance(siteOverride);
    //     popover.current?.show(e.currentTarget);
    // };

    const columns = [
        {
            title: "Started at",
            field: "start",
        },
        {
            title: "Started by",
            field: "startedBy",
        },
        {
            title: "Stopped",
            field: "stop",
        },
        {
            title: "Stopped by",
            field: "stoppedBy",
        },
    ];

    console.log(columns);

    const parseMaintenance = () => {
        const l: any[] = [];

        if (!siteOverride) {
            return l;
        }

        l.push({
            ...siteOverride,
            start: new Date(siteOverride.start).toLocaleTimeString("en-NZ", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
            }),
            stop: siteOverride.stop
                ? new Date(siteOverride.stop).toLocaleTimeString("en-NZ", {
                      month: "numeric",
                      day: "numeric",
                      year: "numeric",
                      hour12: true,
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : "",
        });

        return l;
    };

    return (
        <Table
            data={parseMaintenance()}
            columns={columns}
            loading={loading}
            heading={"Site Maintenance"}
            buttonProps={{ red: siteOverride.maintenance, disabled: loading }}
            actionButton={{
                text: `${
                    siteOverride.maintenance ? "Stop" : "Start"
                } Maintenance`,
                action: handleCreateMaintenance,
            }}
        />
    );
}
