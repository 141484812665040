import LuxonUtils from "@date-io/luxon";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import React, { PropsWithChildren } from "react";
import { $BLUE_DARK, $BLUE_LIGHT, $GREEN_DARK, $GREEN_LIME, $GREY_DARK, $WHITE } from "styles/colors";
import { $FONT_STACK } from "styles/styles";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    typography: {
        fontFamily: $FONT_STACK,
    },
    spacing: 9,
    palette: {
        primary: {
            main: $GREEN_LIME,
            contrastText: $WHITE,
        },
        secondary: {
            main: $BLUE_LIGHT,
            contrastText: 'transparent',
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                color: $BLUE_DARK,
                fontSize: 14,
                border: '1px solid #E0E0E0',
                borderRadius: 3,
                height: 39,
                margin: 0,
            },
        },
        MuiButton: {
            root: {
                padding: 27,
                color: $BLUE_LIGHT,
                borderRadius: 3,
                fontSize: 16,
                backgroundColor: 'transparent',
                border: 'none',
                textDecoration: 'underline',
                '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                },
            },
            contained: {
                color: $WHITE,
                backgroundColor: $GREEN_LIME,
                padding: 10,
                boxShadow: '2px 2px 0 0 rgba(0, 0, 0, .3)',
                fontWeight: 900,
                textDecoration: 'none',
                '&:hover': {
                    backgroundColor: $GREEN_DARK,
                    boxShadow: '2px 2px 0 0 rgba(0, 0, 0, .3)',
                    textDecoration: 'none',
                },
            },
            text: {
                color: $GREY_DARK,
                background: 'transparent',
            },
        },
        MuiPopover: {
            paper: {
                boxShadow: '0 0 3px rgba(0, 0, 0, 0.12)',
            },

        },
        MuiTextField: {
            root: {
                marginBottom: 18,
            },
        },
        MuiFormLabel: {
            root: {
                color: $BLUE_DARK,
                fontSize: 14,
                fontWeight: 900,
                margin: 0,
                '&.Mui-focused': {
                    color: $BLUE_DARK,
                },
                '&.Mui-error': {
                    color: $BLUE_DARK,
                },
                '&.Mui-disabled': {
                    color: $GREY_DARK,
                },
            },
        },
    },
    props: {
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
        MuiButton: {
            variant: "outlined"
        },
    },
});

export default function Provider(props: PropsWithChildren<{}>) {
    const { children } = props;
    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    {children}
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StylesProvider>
    );
};

