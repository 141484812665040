import Button from "components/widgets/button/Button";
import FormikInput from "components/widgets/input/FormikInput";
import Map from "components/widgets/map/Map";
import { Form, Formik } from "formik";
import { useState } from "react";
import SafeswimLocation, { Theme } from "../../../types/SafeswimLocation";

import { InputLabel } from "@material-ui/core";
import classNames from "classnames";
import FormikCheckbox from "components/widgets/checkbox/FormikCheckbox";
import Spinner from "components/widgets/spinner/Spinner";
import useAdminState from "hooks/useAdminState";
import useDispatch from "hooks/useDispatch";
import orderBy from "lodash/orderBy";
import qs from "qs";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { REGION_OPTS } from "../../../constants/regions";
import useMountEffect from "../../../hooks/useMountEffect";
import useSelector from "../../../hooks/useSelector";
import { getFacilities } from "../../../redux/actions/facilities";
import { getHazards } from "../../../redux/actions/hazards";
import {
    createLocation,
    getAvailableRegionBased,
    getH2Locations,
    getLocation,
    updateLocation
} from "../../../redux/actions/locations";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import { getTags } from "../../../redux/actions/tags";
import H2Location from "../../../types/H2Location";
import SafeswimUser from "../../../types/SafeswimUser";
import FormikAddressInput from "../../widgets/formikFields/formikAddressInput/FormikAddressInput";
import FormikCheckboxGroup from "../../widgets/formikFields/formikCheckboxGroup/FormikCheckboxGroup";
import FormikFileUpload from "../../widgets/formikFields/formikFileUpload/FormikFileUpload";
import FormikTextArea from "../../widgets/formikFields/formikTextArea/FormikTextArea";
import styles from "./createUpdateLocation.module.scss";

type FormValues = Partial<SafeswimLocation> & { moataLocationId: string | null };

const ValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    maoriName: Yup.string(),
    description: Yup.string(),
    region: Yup.string(),
    address: Yup.string(),
    isPermanent: Yup.boolean(),
    tags: Yup.array().of(Yup.string()),
    features: Yup.array().of(Yup.string()),
    regionBased: Yup.string().required("Required")
    // dogWalkingIds: Yup.string(),
});

export default function CreateUpdateLocation() {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isMoata, setIsMoata] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [location, setLocation] = useState<SafeswimLocation | null>(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const facilities = useSelector(state => state.facilities);
    const moataLocations: H2Location[] = useSelector(state => state.locations.h2Locations);
    const availableRegionBased = useSelector<string[] | null>(state => state.locations.availableRegionBased);
    const hazards = useSelector(state => state.hazards);
    const tags = useSelector(state => state.tags);
    const { editing } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const { isSuperAdmin, isRegionalAdmin } = useAdminState();
    const user = useSelector<SafeswimUser>(state => state.auth.user);

    useMountEffect(async () => {
        if (editing) {
            try {
                setLoading(true);
                const location = await dispatch(getLocation(Number(editing)));
                setLocation(location);
                setIsMoata(location.traces && !!location.traces.length);
            } catch (e) {
                dispatch(showError("Error retrieving location"));
            }
        }
        if ((availableRegionBased ?? []).length === 0) {
            await dispatch(getAvailableRegionBased());
        }

        if (!tags || !tags.items.length) {
            await dispatch(getTags());
        }

        if (!hazards || !hazards.items.length) {
            await dispatch(getHazards());
        }

        if (!facilities || !facilities.items.length) {
            await dispatch(getFacilities());
        }

        if (!moataLocations || !moataLocations.length) {
            await dispatch(getH2Locations());
        }
        setLoading(false);
    });

    const onSubmit = async (values: FormValues) => {
        const payload: any = { ...values };
        if (values.moataLocationId) {
            const ml = moataLocations.filter((l) => l.id === Number(values.moataLocationId))[0];
            payload.id = Number(values.moataLocationId);
            if (!editing) {
                payload.traces = ml.traces;
                payload.lat = ml.lat;
                payload.lng = ml.lng;
            }
        }
        if (!values.isPermanent && !values.id && !values.moataLocationId) {
            dispatch(showError("Moata location is required if location is not permanent"));
            return;
        }
        // todo should multiple dog walking ids be possible? model expects an array but the form is a text input
        // if (values.dogWalkingIds) {
        //     payload.dogWalkingIds = [values.dogWalkingIds];
        // } else {
        //     payload.dogWalkingIds = [];
        // }

        try {
            setSubmitting(true);
            if (editing) {
                await dispatch(updateLocation(Number(editing), payload));
                dispatch(showSuccess("Location saved"));
            } else {
                await dispatch(createLocation(payload));
                dispatch(showSuccess("Location created"));
            }
            history.push("/locations/overview");
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues: FormValues = {
        name: location?.name || "",
        maoriName: location?.maoriName || "",
        description: location?.description || "",
        region: location?.region || "",
        address: location?.address || "",
        lat: location?.lat,
        lng: location?.lng,
        isPermanent: location?.isPermanent || false,
        tags: location?.tags || [],
        hazards: location?.hazards || [],
        features: location?.features || [],
        images: location?.images || [],
        featuredImage: location?.featuredImage || "",
        creativeImage: location?.creativeImage ?? '',
        creativeImageTheme: location?.creativeImageTheme ?? Theme.LIGHT,
        dogWalkingIds: location?.dogWalkingIds,
        moataLocationId: location?.id.toString() ?? null,
        traces: location?.traces || [],
        permanentWaterQuality: location?.permanentWaterQuality,
        regionBased: location?.regionBased
    };

    const initialCenter = location?.lat && location?.lng ? { lat: location.lat, lng: location.lng } : undefined;

    const TAG_OPTS = Object.values(tags?.items || {}).map(t => ({
        label: t.title,
        value: t.id.toString()
    }));

    const regions = isRegionalAdmin ? (availableRegionBased || []).filter((r) => (user.assignedRegions || []).indexOf(r) > -1) : (availableRegionBased || []);
    const hasAucklandRegion = isRegionalAdmin && regions.indexOf("Tāmaki-makau-rau - Auckland") > -1;

    const REGION_BASED_OPTS = regions.map(v => ({
        label: v,
        value: v
    })) ?? [];

    const FACILITY_OPTS = Object.values(facilities?.items || {}).map(f => ({
        label: f.title,
        value: f.id.toString()
    })) || [];

    const MOATA_OPTS = orderBy(Object.values(moataLocations || {}), ["name"]).map(m => ({
        label: `${m.name} - ${m.id}`,
        value: m.id.toString()
    })) || [];

    const HAZARD_OPTS = Object.values(hazards?.items || {}).map(h => ({
        label: h.title,
        value: h.id.toString()
    })) || [];

    return (
        <div className={styles.container}>
            {loading
                ? <Spinner center />
                : (
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
                        {({ values, errors, handleSubmit, dirty, isSubmitting, setFieldValue }) => {
                            const moataLocation = values.moataLocationId ? moataLocations.filter((l) => l.id === Number(values.moataLocationId))[0] : undefined;
                            // console.log({ values }, { errors });
                            return (
                                <Form>
                                    <div className={styles.header}>
                                        <h1>{editing ? "Update Location" : "Create Location"}</h1>
                                        <div className={styles.header_actions}>
                                            <Button onClick={() => history.goBack()}>Cancel</Button>
                                            <Button variant={"contained"}
                                                    onClick={() => handleSubmit()}
                                                    loading={submitting}
                                                    disabled={!dirty || isSubmitting}>Save location</Button>
                                        </div>
                                    </div>
                                    <div className={styles.map_intro}>
                                        <h5>LOCATION PIN</h5>
                                        <span>Please adjust the location marker to reflect the location of the beach. This is where the pin will sit on the map.</span>
                                    </div>
                                    <div className={styles.map}>
                                        <Map onLocationSet={(latLng) => {
                                            setFieldValue("lat", latLng.lat);
                                            setFieldValue("lng", latLng.lng);
                                        }}
                                             initialCenter={initialCenter} />
                                    </div>
                                    <div className={styles.form_content}>
                                        <FormikInput name={"name"} label={"Primary name"} className={styles.half_input} />
                                        <FormikInput name={"maoriName"} label={"Secondary name"}
                                                     className={styles.half_input} />
                                        <div className={classNames(styles.moata_buttons, styles.half_input)}>
                                            <InputLabel shrink htmlFor={""}>Is this location in moata?</InputLabel>
                                            <div className={styles.button_container}>
                                                <Button onClick={() => {
                                                    setIsMoata(false);
                                                    setFieldValue("moataLocationId", null);
                                                }}
                                                        disabled={isSubmitting}
                                                        className={classNames(styles.button, { [styles.selected]: !isMoata })}
                                                        variant={"text"}>No</Button>
                                                <Button onClick={() => {
                                                    setIsMoata(true);
                                                }}
                                                        disabled={isSubmitting}
                                                        className={classNames(styles.button, { [styles.selected]: isMoata })}
                                                        variant={"text"}>Yes</Button>
                                            </div>
                                        </div>
                                        <FormikInput name={"moataLocationId"}
                                                     label={"Select moata location"}
                                                     onChange={() => {
                                                         if (moataLocation) {
                                                             setFieldValue("lat", moataLocation.lat);
                                                             setFieldValue("lng", moataLocation.lng);
                                                         }
                                                     }}
                                                     options={MOATA_OPTS}
                                                     disabled={isSubmitting || !isMoata}
                                                     className={styles.half_input} />
                                        <FormikAddressInput name={"address"}
                                                            label={"Address"}
                                                            containerClassName={styles.half_input} />
                                        {(isSuperAdmin || hasAucklandRegion) && (
                                            <FormikInput name={"region"}
                                                         options={REGION_OPTS}
                                                         label={"Region"}
                                                         className={styles.half_input} />
                                        )}
                                        <FormikInput name={"regionBased"}
                                                     disabled={!isSuperAdmin && !isRegionalAdmin}
                                                     options={REGION_BASED_OPTS}
                                                     label={"Region based"}
                                                     className={styles.half_input} />
                                        <FormikCheckboxGroup name={"tags"} label={"Tags"} values={TAG_OPTS}
                                                             type={"checkbox"} />
                                        {/* <FormikInput name={"dogWalkingIds"} label={"Dog walking id"}
                                                     className={styles.half_input}/> */}
                                        <FormikCheckbox name={"isPermanent"} label={"This is a permanent location"} />
                                        {!!values.isPermanent &&
                                            <FormikInput name={"permanentWaterQuality"}
                                                         label={"Water quality level"} />}
                                    </div>
                                    <div className={styles.form_content}>
                                        <h3>Location details</h3>

                                        <FormikTextArea name={"description"}
                                                        label={"Description"}
                                                        className={styles.text_area} />
                                        <FormikCheckboxGroup name={"features"}
                                                             label={"Location facilities"}
                                                             type={"checkbox"}
                                                             values={FACILITY_OPTS}
                                                             containerClassName={styles.checkbox_group} />
                                        <FormikCheckboxGroup name={"hazards"}
                                                             label={"Location hazards"}
                                                             type={"checkbox"}
                                                             values={HAZARD_OPTS}
                                                             containerClassName={styles.checkbox_group} />
                                        <FormikFileUpload name={"featuredImage"}
                                                          label={"Feature image"}
                                                          containerClassName={styles.image_upload}
                                                          multiple={false}
                                                          accept={["image/svg+xml", "image/png", "image/jpeg"]}
                                                          actionText={"Drag images here or click to upload"} />
                                        <FormikFileUpload name={"creativeImage"}
                                                        label={"Creative image"}
                                                        containerClassName={styles.image_upload}
                                                        multiple={false}
                                                        accept={["image/svg+xml"]}
                                            actionText={"Drag images here or click to upload"} />
                                        <div className={classNames(styles.moata_buttons, styles.half_input)}>
                                            <InputLabel shrink htmlFor={""}>Creative image theme</InputLabel>
                                            <div className={styles.button_container}>
                                                <Button onClick={() => setFieldValue("creativeImageTheme", Theme.LIGHT)}
                                                        disabled={isSubmitting}
                                                        className={classNames(styles.button, { [styles.selected]: values.creativeImageTheme === Theme.LIGHT })}
                                                        variant={"text"}>Light</Button>
                                                <Button onClick={() => setFieldValue('creativeImageTheme', Theme.DARK)}
                                                        disabled={isSubmitting}
                                                        className={classNames(styles.button, { [styles.selected]: values.creativeImageTheme === Theme.DARK })}
                                                        variant={"text"}>Dark</Button>
                                            </div>
                                        </div>
                                        <FormikFileUpload name={"images"}
                                                          label={"Location images"}
                                                          containerClassName={styles.image_upload}
                                                          multiple
                                                          accept={["image/svg+xml", "image/png", "image/jpeg"]}
                                                          actionText={"Drag images here or click to upload"} />

                                        {/*<div>*/}
                                        {/*    <label>Lifeguard club coordinates</label>*/}
                                        {/*    <div className={styles.map}>*/}
                                        {/*        <Map onLocationSet={(latLng) => {*/}
                                        {/*            setFieldValue("lifeguardClubCoordinates", latLng);*/}
                                        {/*        }}*/}
                                        {/*             initialCenter={location?.lifeguardClubCoordinates} />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className={styles.bottom_buttons}>
                                            <Button variant={"contained"}
                                                    onClick={() => handleSubmit()}
                                                    loading={submitting}
                                                    disabled={!dirty || isSubmitting}>Save location</Button>
                                            <Button onClick={() => history.goBack()}>Cancel</Button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
        </div>
    );
}
