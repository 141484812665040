import React from 'react';
import PageContainer from "../widgets/pageContainer/PageContainer";
import routes from "../../constants/routes";
import { Redirect, Route, Switch } from 'react-router-dom';
import LocationOrdering from "./locationOrdering/LocationOrdering";
import HazardOrdering from "./hazardOrdering/HazardOrdering";
import PatrolHours from "./patrolHours/PatrolHours";

export default function SurfLifesaving() {
    return (
        <PageContainer navItems={[
            { text: "Locations Ordering", path: routes.sls.locations },
            { text: "Hazard Ordering", path: routes.sls.hazards },
            { text: "Patrol Hours", path: routes.sls.patrol },
        ]}>
            <Switch>
                <Route path={routes.sls.locations} component={LocationOrdering} />
                <Route path={routes.sls.hazards} component={HazardOrdering} />
                <Route exact path={routes.sls.patrol} component={PatrolHours} />
                <Redirect from={routes.sls.root} to={routes.sls.locations} />
            </Switch>
        </PageContainer>
    );
}
