import React, { ReactNode } from "react";
import classNames from "classnames";
import Spinner from "components/widgets/spinner/Spinner";
import styles from "./fileUpload.module.scss";
import { DropzoneOptions, useDropzone } from "react-dropzone";

export type FileUploadProps = {
    id?: string;
    label?: string;
    actionText?: string;
    containerClassName?: string;
    labelClassName?: string;
    onChange?: (files: File[]) => void;
    accept?: string | string[];
    disabled?: boolean;
    helperText?: string;
    preview?: ReactNode;
    showError?: boolean;
    loading?: boolean;
    multiple?: boolean;
}

function FileUpload(props: FileUploadProps) {
    const {
        label,
        actionText,
        containerClassName,
        labelClassName,
        onChange,
        accept,
        disabled,
        helperText,
        preview,
        showError,
        loading
    } = props;
    const disableInput = disabled || loading;

    const onDrop = (files: File[]) => {
        onChange?.(files);
    };

    const options: DropzoneOptions = {
        disabled: disableInput,
        accept,
        onDrop
    };

    const { getRootProps, getInputProps } = useDropzone(options);

    return (
        <div className={classNames(styles.file_upload, containerClassName)}>
            <div className={classNames(styles.label, labelClassName)}>{label}</div>
            {preview}
            <div {...getRootProps({ className: styles.dropzone })}>
                <input {...getInputProps()} />
                {loading ? <Spinner /> : actionText}
            </div>

            {showError && helperText && <div className={styles.error}>{helperText}</div>}
        </div>
    );
}

export default FileUpload;