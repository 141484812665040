import put from "./util/put";
import post from "./util/post";
import get from "./util/get";
import del from "./util/del";
import ListResult from "../../../types/ListResult";
import Alert, { AlertState, AlertStateRequest, AlertUpdateRequest } from "../../../types/Alert";
import AlertType, { AlertTypeDTO } from "../../../types/AlertType";

function getAlert(id: number): Promise<Alert> {
    return get(`v1/admin/alert/${id}`);
}

function getAlerts(showDeleted: boolean, cursor?: string, limit?: number, type?: string): Promise<ListResult<Alert>> {
    return get(`v1/admin/alerts/${showDeleted}`, { params: { cursor, limit: 1000, type } });
}

function getAllAlerts(cursor?: string, limit?: number, type?: string): Promise<ListResult<Alert>> {
    return get("v1/admin/alerts/true", { params: { cursor, limit, type }});
}

function getAlertCount(): Promise<number> {
    return get("v1/admin/count/alerts");
}

function createAlert(alert: AlertUpdateRequest): Promise<ListResult<Alert>> {
    return post("v1/admin/alerts/create", alert);
}

function updateAlert(id: number, alert: AlertUpdateRequest): Promise<ListResult<Alert>> {
    return put(`v1/admin/alerts/${id}`, alert);
}

function deleteAlert(id: number, reason?: string): Promise<ListResult<Alert>> {
    return put(`v1/admin/alerts/delete/${id}`, reason);
}
function getAlertType(id: number): Promise<AlertType> {
    return get(`v1/alert_types/${id}`);
}

function getAlertTypes(): Promise<AlertType[]> {
    return get("v1/alert_types");
}

function createAlertType(alertType: AlertTypeDTO): Promise<AlertType[]> {
    return post("v1/alert_types", alertType);
}

function updateAlertType(alertType: AlertTypeDTO): Promise<AlertType[]> {
    return put("v1/alert_types", alertType);
}

function updateAllAlertTypes(alertTypes: AlertTypeDTO[]){
    return put("v1/alert_types/all", alertTypes)
}

function deleteAlertType(id: number): Promise<AlertType[]> {
    return del(`v1/alert_types/${id}`);
}

function getAlertStates(alertId: number): Promise<AlertState[]> {
    return get(`v1/alerts/${alertId}/states`);
}

function updateAlertStates(alertId: number, request: AlertStateRequest[]): Promise<AlertState[]> {
    return put(`v1/admin/alerts/${alertId}/states/`, request);
}

const AlertsAPI = {
    getAlertType,
    getAlert,
    getAlerts,
    getAllAlerts,
    createAlert,
    updateAlert,
    deleteAlert,
    getAlertTypes,
    createAlertType,
    updateAlertType,
    updateAllAlertTypes,
    deleteAlertType,
    getAlertStates,
    updateAlertStates,
    getAlertCount,
};

export default AlertsAPI;
