import get from "./util/get";
import post from "./util/post";
import put from "./util/put";
import del from "./util/del";
import SpecialEvent from "../../../types/SpecialEvent";
import SpecialEventDTO from "../../../types/SpecialEventDTO";

function getEvents(): Promise<SpecialEvent[]> {
    return get("v1/admin/events/all");
}

function getEvent(id: number): Promise<SpecialEvent>{
    return get(`v1/admin/events/${id}`)
}

function createEvent(event: SpecialEventDTO): Promise<SpecialEvent[]> {
    return post("v1/admin/events", event)
}

function updateEvent(id: number, event: SpecialEventDTO): Promise<SpecialEvent[]> {
    return put(`v1/admin/events/${id}`, event);
}

function deleteEvent(id: number): Promise<SpecialEvent[]> {
    return del(`v1/admin/events/${id}`);
}

const SpecialEventsAPI = {
    getEvents,
    getEvent,
    createEvent,
    updateEvent,
    deleteEvent
}

export default SpecialEventsAPI;