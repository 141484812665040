import "@date-io/luxon";
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps as MuiDateTimePickerProps } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTime } from "luxon";
import React, { useCallback } from "react";
import Input, { InputProps } from "../input/Input";

export type DateTimePickerProps = {
    onChange?: (date: DateTime) => void;
    value?: string | DateTime;
    parseFormat?: string;
    displayFormat?: string;
} & Omit<InputProps, "onChange" | "value"> & Omit<MuiDateTimePickerProps, "onChange" | "value">;

export default function DateTimePicker(props: DateTimePickerProps) {
    const { field, value, format, parseFormat, displayFormat, onChange: propsOnChange, ...otherProps } = props;

    const onChange = useCallback((date: MaterialUiPickersDate) => {
        const dt = date as DateTime; //DateType is DateTime in lib
        propsOnChange?.(dt);
    }, [propsOnChange]);

    let dateValue: DateTime = DateTime.local();
    if (value) {
        if (typeof value === "string") {
            if (parseFormat) {
                dateValue = DateTime.fromFormat(value, parseFormat);
            } else {
                dateValue = DateTime.fromISO(value);
            }
        } else {
            dateValue = value;
        }
    }

    return (
        <MuiDateTimePicker {...field}
            {...otherProps}
            value={dateValue}
            format={displayFormat || format || "dd/MM/yyyy hh:mm:ss a"}
            onChange={onChange}
            TextFieldComponent={(props: any) => <Input { ...props } />}
        />
    );
}
