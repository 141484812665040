import React from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import { Field } from "formik";
import { InputLabel } from "@material-ui/core";
import classNames from "classnames";
import styles from "./FormikTextArea.module.scss";

type Props = {
    name: string;
    disabled?: boolean;
    label?: string;
    tooltip?: string;
} & TextareaAutosizeProps;

export default function FormikTextArea(props: Props) {
    const { name, disabled, label, tooltip, className, ...otherProps } = props;
    const inputClassName = classNames(styles.input, className);

    return (
        <Field name={name}>
            {({ field, form, meta }: any) => {
                const showError = meta.touched && !!meta.error;
                const error = showError ? meta.error : null;
                const dis = disabled || form.isSubmitting;

                return (
                    <>
                        {label && (
                            <InputLabel className={styles.label} shrink htmlFor={"input"}>
                                {label}
                            </InputLabel>
                        )}
                        <TextareaAutosize disabled={dis} className={inputClassName} {...otherProps} {...field} value={field.value || undefined} />
                        {showError && error && <div className={styles.error}>{error}</div>}
                    </>
                )
            }}
        </Field>
    )
}
