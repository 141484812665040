import React from 'react';
import { useField, useFormikContext } from "formik";
import { InputLabel } from "@material-ui/core";
import AddressInput from 'components/widgets/addressInput/AddressInput';
import styles from "./formikAddressInput.module.scss";
import classNames from "classnames";

type Props = {
    name: string;
    label?: string;
    containerClassName?: string;
    inputClassName?: string;
}

export default function FormikAddressInput(props: Props) {
    const { name, label, containerClassName,inputClassName, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);
    const [field, meta, helpers] = fieldProps;

    const showError = meta.touched && !!meta.error;
    const error = showError ? meta.error : null;
    const containerClass = classNames(styles.container, containerClassName);

    return (
        <div className={containerClass}>
            <InputLabel shrink htmlFor={"input"}>
                {label}
            </InputLabel>
            <AddressInput onChange={helpers.setValue}
                          value={field.value}
                          onBlur={() => helpers.setTouched(true)}
                          disabled={isSubmitting}
                          inputClassName={inputClassName}
                          {...otherProps} />
            {showError && error && <div className={styles.error}>{error}</div>}
        </div>
    );
}
