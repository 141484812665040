import createAction from "redux/helpers/createAction";
import wrapper from "redux/helpers/wrapper";
import ListResult from "types/ListResult";
import SafeswimChange from "types/SafeswimChange";
import ChangesAPI from "./api/Changes";

export enum Types {
    SET_CHANGES = 'SET_CHANGES',
}

export const setChanges = createAction<ListResult<SafeswimChange>>(Types.SET_CHANGES);

export const getEntityChanges = wrapper(ChangesAPI.getChangesByEntity, { action: setChanges });