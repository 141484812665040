import { AnyAction } from "redux";

export interface Action<T> extends AnyAction {
    payload?: T;
}

export default function createAction<T>(type: string): (payload: T) => Action<T> {
    return (payload: T): Action<T> => {
        return {
            type,
            payload,
        };
    };
}
