import React, { useEffect, useMemo } from "react";
import PageContainer, { NavItem } from "../widgets/pageContainer/PageContainer";
import routes from "../../constants/routes";
import { useDispatch } from "react-redux";
import { getUserRoles, getUsers } from "../../redux/actions/users";
import { Redirect, Route, Switch } from "react-router-dom";
import Override from "./override/Override";
import Users from "./users/Users";
import ChangeLogs from "./logs/ChangeLogs";
import WaterQualityReport from "./reports/waterQuality/WaterQualityReport";
import DigitalSignStatus from "./digitalSigns/DigitalSIgnStatus";
import useSelector from "hooks/useSelector";
import SafeswimUser, { USER_ROLES } from "types/SafeswimUser";
import WaterQualityAlerts from "../alerts/waterQuality/WaterQualityAlerts";
import WaterSafetyAlerts from "../alerts/waterSafety/WaterSafetyAlerts";
import CreateUpdateWaterQualityAlert from "../alerts/waterQuality/CreateUpdateWaterQualityAlert";
import CreateUpdateWaterSafetyAlert from "../alerts/waterSafety/CreateUpdateWaterSafetyAlert";

export default function Admin() {
    const dispatch = useDispatch();
    const user: SafeswimUser = useSelector(state => state.auth.user);

    const isSuperAdmin = useMemo(() => user.role === USER_ROLES.SUPER_ADMIN, [user]);
    const isDSAdmin = useMemo(() => user.role === USER_ROLES.DS_ADMIN, [user]);
    const isSLSAdmin = useMemo(() => user.role === USER_ROLES.SLS, [user]);
    const isWaterCareAdmin = useMemo(() => user.role === USER_ROLES.WATERCARE, [user]);

    useEffect(() => {
        if (isSuperAdmin) {
            Promise.all([
                dispatch(getUsers()),
                dispatch(getUserRoles())
            ]);
        }
    }, [isSuperAdmin, dispatch]);

    const navItems: NavItem[] | undefined = useMemo(() => {
        let _navItems = undefined;
        if (isSuperAdmin) {
            _navItems = [
                { text: "Site Override", path: routes.admin.override },
                { text: "Users", path: routes.admin.users },
                { text: "Change Logs", path: routes.admin.logs },
                { text: "Digital Sign Status", path: routes.admin.digitalSignStatus },
                { text: "Water Quality Report", path: routes.admin.waterQualityReport },
                { path: routes.admin.quality, exact: true, text: "Manage water quality alerts" },
                { path: routes.admin.safety, exact: true, text: "Manage water safety alerts" },
            ];
        } else if (isDSAdmin) {
            _navItems = [
                { text: "Digital Sign Status", path: routes.admin.digitalSignStatus },
            ];
        } else if (isSLSAdmin || isWaterCareAdmin) {
            _navItems = [
                { path: routes.admin.quality, exact: true, text: "Manage water quality alerts" },
                { path: routes.admin.safety, exact: true, text: "Manage water safety alerts" },
            ];
        }
        return _navItems;
    }, [isSuperAdmin, isDSAdmin, isSLSAdmin, isWaterCareAdmin]);

    const getDefaultPage = () => {
        if (isWaterCareAdmin) {
            return routes.admin.quality;
        }

        if (isSLSAdmin) {
            return routes.admin.safety;
        }

        return isSuperAdmin ? routes.admin.override : routes.admin.digitalSignStatus;
    };

    return (
        <PageContainer navItems={navItems}>
            <Switch>
                {isSuperAdmin && <Route path={routes.admin.override} component={Override} />}
                {isSuperAdmin && <Route path={routes.admin.users} component={Users} />}
                {isSuperAdmin && <Route path={routes.admin.logs} component={ChangeLogs} />}
                {(isSuperAdmin || isSLSAdmin || isWaterCareAdmin) &&
                    <Route path={routes.admin.quality} component={WaterQualityAlerts} />}
                {(isSuperAdmin || isSLSAdmin || isWaterCareAdmin) &&
                    <Route path={routes.admin.safety} component={WaterSafetyAlerts} />}
                {(isSuperAdmin || isSLSAdmin || isWaterCareAdmin) &&
                    <Route path={routes.admin.createWaterQualityAlertType} component={CreateUpdateWaterQualityAlert} />}
                {(isSuperAdmin || isSLSAdmin || isWaterCareAdmin) &&
                    <Route path={routes.admin.createWaterSafetyAlertType} component={CreateUpdateWaterSafetyAlert} />}
                {(isDSAdmin || isSuperAdmin) &&
                    <Route path={routes.admin.digitalSignStatus} component={DigitalSignStatus} />}
                {isSuperAdmin && <Route path={routes.admin.waterQualityReport} component={WaterQualityReport} />}

                <Redirect from={routes.admin.root} to={getDefaultPage()} />
            </Switch>
        </PageContainer>
    );
}
