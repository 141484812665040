import { DateTime } from "luxon";
import React from "react";
import useFormikField, { FormikFieldExtraProps } from "hooks/useFormikField";
import DateTimePicker, { DateTimePickerProps } from "./DateTimePicker";

export type FormikDateTimePickerProps = {
    name: string;
} & Omit<DateTimePickerProps, "name" | "value" | "error" | "variant"> & FormikFieldExtraProps;

export default function FormikDateTimePicker(props: FormikDateTimePickerProps) {

    const { parseFormat } = props;
    const { value, disabled, showError, helperText, field, formik } = useFormikField(props);

    const onChange = (date: DateTime) => {
        props.onChange?.(date);
        formik.setFieldValue(field?.name, parseFormat ? date.toFormat(parseFormat) : date.toISO());
    };

    return (
        <DateTimePicker {...props}
                        field={field}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        error={showError}
                        helperText={helperText} />
    );
}
