import React, { useRef, useState } from "react";
import useSelector from "../../../hooks/useSelector";
import Popover, { PopoverRef } from "components/widgets/popover/Popover";
import Button from "components/widgets/button/Button";
import useMountEffect from "../../../hooks/useMountEffect";
import Options from "../../../resources/images/svg/options.svg";
import styles from "../../alerts/current/currentAlerts.module.scss";
import Table from "../../widgets/table/Table";
import SafeswimUser from "../../../types/SafeswimUser";
import { deleteUser, getUsers } from "../../../redux/actions/users";
import firebase from "firebase/app";
import "firebase/auth";
import WarningDialog from "../../widgets/dialog/warningDialog/WarningDialog";
import { DialogRef } from "components/widgets/dialog/Dialog";
import CreateUserDialog from "../../widgets/dialog/createUserDialog/CreateUserDialog";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import { getAvailableRegionBased } from "redux/actions/locations";
import useDispatch from "hooks/useDispatch";

export default function Users() {
    const [loading, setLoading] = useState<boolean>(false);
    const [editingUser, setEditingUser] = useState<SafeswimUser | null>(null);
    const dispatch = useDispatch();
    const users: any = useSelector(state => state.users);
    const availableRegionBased = useSelector<string[] | null>(state => state.locations.availableRegionBased);
    const popover = useRef<PopoverRef>(null);
    const deleteDialog = useRef<DialogRef>(null);
    const createDialog = useRef<DialogRef>(null);


    useMountEffect(async () => {
        if (!users?.items?.length) {
            setLoading(true);
            try {
                await dispatch(getUsers());
            } catch (e: any) {
                dispatch(showError(e.message));
            } finally {
                setLoading(false);
            }
        }
        if ((availableRegionBased ?? []).length === 0) {
            await dispatch(getAvailableRegionBased());
        }
    });

    const handleCreateUser = () => {
        popover.current?.hide();
        createDialog.current?.show();
    };

    const onShowPopover = (user: SafeswimUser) => (e: any) => {
        setEditingUser(user);
        popover.current?.show(e.currentTarget);
    };

    const sendResetPassword = async (user: SafeswimUser) => {
        setLoading(true);
        try {
            await firebase.auth().sendPasswordResetEmail(user.email);
            dispatch(showSuccess("Password reset sent"));
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = async () => {
        try {
            setLoading(true);
            await dispatch(deleteUser(editingUser?.id || ""));
            dispatch(showSuccess("User successfully deleted"));
            deleteDialog.current?.hide();
            setEditingUser(null);
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    };
    const onDeleteClick = (popover: any) => {
        popover.current?.hide();
        deleteDialog.current?.show();
    };

    const columns = [
        {
            title: "First name",
            field: "firstName"
        },
        {
            title: "Last name",
            field: "lastName"
        },
        {
            title: "Email address",
            field: "email"
        },
        {
            title: "Role",
            field: "role"
        },
        {
            render: (user: SafeswimUser) => (
                <span className={styles.reset_password}
                      onClick={() => sendResetPassword(user)}>Reset Password</span>
            )
        },
        {
            field: "",
            render: (users: SafeswimUser) => (
                <div style={{ textAlign: "right" }}>
                    <Button plain onClick={onShowPopover(users)}><img src={Options} alt={"Options"}/></Button>
                    <Popover popoverRef={popover}
                             className={styles.popover}
                             anchorOrigin={{ horizontal: "right", vertical: "center" }}
                             transformOrigin={{
                                 vertical: "center",
                                 horizontal: "left",
                             }}>
                        <Button plain onClick={handleCreateUser}>View/Edit</Button>
                        <Button plain onClick={() => onDeleteClick(popover)}>Delete</Button>
                    </Popover>
                </div>
            )
        },
    ];


    const parseUsers = () => {
        const a: any[] = [];

        if (!users?.users) {
            return a;
        }

        Object.values(users?.users).map((lo: any) => {
            if (lo && lo.role !== "USER") {
                a.push({
                    ...lo,
                });
            }
            return null;
        });

        return a;
    };

    return (
        <>
            <Table data={parseUsers()}
                   columns={columns}
                   loading={loading}
                   heading={"Users"}
                   actionButton={{ text: "Create user", action: handleCreateUser }}
            />
            <WarningDialog title={"Delete user"}
                           positiveText={"Delete"}
                           onPositivePress={handleDeleteUser}
                           negativeText={"Cancel"}
                           onNegativePress={() => {
                               deleteDialog.current?.hide();
                               setEditingUser(null);
                           }}
                           loading={loading}
                           ref={deleteDialog}>
                <span>{`Are you sure you would like to delete ${editingUser?.firstName}`}?</span>
                <span style={{ fontWeight: "bold" }}>This action can not be undone and they will no longer be able to log in.</span>
            </WarningDialog>
            <CreateUserDialog
                title={editingUser ? "Edit User " : "Create User "}
                positiveText={editingUser ? "Update" : "Create"}
                onPositivePress={handleCreateUser}
                negativeText={"Cancel"}
                onNegativePress={() => {
                    createDialog.current?.hide();
                    setEditingUser(null);
                }}
                onClose={() => {
                    createDialog.current?.hide();
                    setEditingUser(null);
                }}
                user={editingUser}
                availableRegionBased={availableRegionBased}
                loading={loading}
                ref={createDialog}>
            </CreateUserDialog>
        </>
    );
}
