import { Types } from "../actions/hazards";
import Hazard from "../../types/Hazard";

const initialState = {
    items: {},
    cursor: null,
};

export default function hazards(state = initialState, action: any) {
    switch (action.type) {
        case Types.HAZARDS_RECEIVED: {
            const { items, cursor } = action.payload;
            const hazards: { [key: string]: Hazard } = { ...state.items };

            items.forEach((hazard: Hazard) => {
                hazards[hazard.id] = hazard
            })

            return {
                items: hazards,
                cursor: cursor,
            };
        }

        case Types.HAZARD_RECEIVED: {
            const hazard: Hazard = action.payload;
            const hazards: { [key: string]: Hazard } = { ...state.items };

            hazards[hazard.id] = hazard;

            return {
                items: hazards,
                cursor: state.cursor,
            }
        }

        case Types.HAZARD_DELETED: {
            const { items, cursor } = action.payload;
            const hazards: { [key: string]: Hazard } = {};

            items.forEach((hazard: Hazard) => {
                hazards[hazard.id] = hazard
            })

            return {
                items: hazards,
                cursor: cursor
            }
        }

        default:
            return state
    }
};
