import React, { useMemo } from "react";
import PageContainer from "../widgets/pageContainer/PageContainer";
import routes from "../../constants/routes";
import { Redirect, Route, Switch } from "react-router-dom";
import LocationOverview from "./overview/LocationOverview";
import LocationFacilities from "./facilities/LocationFacilities";
import LocationHazards from "./hazards/LocationHazards";
import CreateUpdateLocation from "./overview/CreateUpdateLocation";
import CreateUpdateHazard from "./hazards/CreateUpdateHazard";
import CreateUpdateFacility from "./facilities/CreateUpdateFacility";
import Traces from "./overview/traces/Traces";
import useAdminState from "hooks/useAdminState";

type Props = {}

export default function Locations(props: Props) {

    const { isSuperAdmin, isRegionalAdmin } = useAdminState();

    const navItems = useMemo(() => {
        let navs: Array<{text: string, path: string}> = [
            { text: "Locations Overview", path: routes.locations.overview },
        ];
        let rts: Array<{ path: string, exact?: boolean, component: any }> = [
            { path: routes.locations.overview, exact: true, component: LocationOverview },
            { path: routes.locations.createLocation, component: CreateUpdateLocation },
        ];
        if (isRegionalAdmin) {
            navs = [
                { text: "Locations Overview", path: routes.locations.overview },
            ];
            rts = [
                { path: routes.locations.overview, exact: true, component: LocationOverview },
                { path: routes.locations.createLocation, component: CreateUpdateLocation },
            ];
        } else if (isSuperAdmin) {
            navs = [
                { text: "Locations Overview", path: routes.locations.overview },
                { text: "Locations Facilities", path: routes.locations.facilities },
                { text: "Locations Hazards", path: routes.locations.hazards },
            ];
            rts = [
                { path: routes.locations.overview, exact: true, component: LocationOverview },
                { path: routes.locations.facilities, exact: true, component: LocationFacilities },
                { path: routes.locations.hazards, exact: true, component: LocationHazards },
                { path: routes.locations.createLocation, component: CreateUpdateLocation },
                { path: routes.locations.createFacility, component: CreateUpdateFacility },
                { path: routes.locations.createHazard, component: CreateUpdateHazard },
                { path: routes.locations.traces, component: Traces },
            ];
        } else {
            //unauthed request
        }
        return {navs, rts};
    }, [isSuperAdmin, isRegionalAdmin]);

    return (
        <PageContainer navItems={navItems.navs}>
            <Switch>
                {navItems.rts.map((r, i) => (
                    <Route key={i} exact={r.exact} path={r.path} component={r.component} />
                ))}
                <Redirect from={routes.locations.root} to={routes.locations.overview} />
            </Switch>
        </PageContainer>
    );
}
