import put from "./util/put";
import post from "./util/post";
import get from "./util/get";
import del from "./util/del";
import ListResult from "../../../types/ListResult";
import Tag from "../../../types/Tag";

export function getTags(cursor?: string, limit?: number): Promise<ListResult<Tag>> {
    return get("v1/tags", { params: { cursor, limit } })
}

export function createTag(tag: Partial<Tag>): Promise<Tag> {
    return post("v1/tags", tag);
}

export function updateTag(id: number, tag: Partial<Tag>): Promise<Tag> {
    return put(`v1/tags/${id}`, tag);
}

export function deleteTag(id: number): Promise<ListResult<Tag>> {
    return del(`v1/tags/${id}`);
}

const TagsAPI = {
    getTags,
    createTag,
    updateTag,
    deleteTag
}

export default TagsAPI;