import { AlertState } from './../../types/Alert';
import createAction from "../helpers/createAction";
import Alert from "../../types/Alert";
import ListResult from "../../types/ListResult";
import AlertType from "../../types/AlertType";
import AlertsAPI from "./api/Alerts";
import wrapper from "../helpers/wrapper";

export enum Types {
    ALERT_RECEIVED = "ALERT_RECEIVED",
    ALERTS_RECEIVED = "ALERTS_RECEIVED",
    ALERT_TYPES_RECEIVED = "ALERT_TYPES_RECEIVED",
    ALERT_STATES_RECEIVED = "ALERT_STATES_RECEIVED",
}

const setAlert = createAction<Alert>(Types.ALERT_RECEIVED);
const setAlerts = createAction<ListResult<Alert>>(Types.ALERTS_RECEIVED);
const setAlertTypes = createAction<AlertType[]>(Types.ALERT_TYPES_RECEIVED);
const setAlertStates = createAction<AlertState[]>(Types.ALERT_STATES_RECEIVED);

export const getAlerts = wrapper(AlertsAPI.getAlerts, {
    action: setAlerts
});

export const getAlert = wrapper(AlertsAPI.getAlert, {
    action: setAlert
});

export const getAllAlerts = wrapper(AlertsAPI.getAllAlerts, {
    action: setAlerts
});

export const createAlert = wrapper(AlertsAPI.createAlert, {
    action: setAlerts
});

export const updateAlert = wrapper(AlertsAPI.updateAlert, {
    action: setAlerts
});

export const deleteAlert = wrapper(AlertsAPI.deleteAlert, {
    action: setAlerts
});

export const getAlertTypes = wrapper(AlertsAPI.getAlertTypes, {
    action: setAlertTypes
});

export const getAlertType = wrapper(AlertsAPI.getAlertType, {});

export const createAlertType = wrapper(AlertsAPI.createAlertType, {
    action: setAlertTypes
});

export const updateAlertType = wrapper(AlertsAPI.updateAlertType, {
    action: setAlertTypes
});

export const updateAllAlertTypes = wrapper(AlertsAPI.updateAllAlertTypes, {
    action: setAlertTypes
})

export const deleteAlertType = wrapper(AlertsAPI.deleteAlertType, {
    action: setAlertTypes
});

export const updateAlertStates = wrapper(AlertsAPI.updateAlertStates, {
    action: setAlertStates
});

export const getAlertStates = wrapper(AlertsAPI.getAlertStates, {
    action: setAlertStates
});
