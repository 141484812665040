import get from "./util/get";
import post from "./util/post";
import put from "./util/put";
import Maintenance from "../../../types/Maintenance";

function getMaintenance(): Promise<Maintenance> {
    return get("v1/admin/maintenance");
}

function startMaintenance(): Promise<Maintenance> {
    return post("v1/admin/maintenance/start");
}

function stopMaintenance(): Promise<Maintenance> {
    return put("v1/admin/maintenance/stop");
}

const SiteOverrideAPI = {
    getMaintenance,
    startMaintenance,
    stopMaintenance,
}

export default SiteOverrideAPI;
