import SafeswimUser from "../../types/SafeswimUser";
import { Types } from "../actions/users";
import SafeswimUsername from "../../types/SafeswimUsername";

const initialState: {
    users: { [key: string]: SafeswimUser };
    usernames: { [key: string]: SafeswimUsername };
    currentUserRoles: string | null;
} = {
    users: {},
    usernames: {},
    currentUserRoles: null,
};

export default function users(state = initialState, action: any) {
    switch (action.type) {
        case Types.USERS_RECEIVED: {
            let users: { [key: string]: SafeswimUser } = {};

            if (!action.payload) {
                return state;
            }

            action.payload.forEach((user: SafeswimUser) => {
                users[user.id] = user;
            });

            return {
                ...state,
                users: {
                    ...users,
                },
            };
        }

        case Types.USER_RECEIVED: {
            const user: SafeswimUser = action.payload;

            return {
                ...state,
                users: {
                    ...state.users,
                    [user.id]: user,
                }
            };
        }

        case Types.USERNAMES_RECEIVED: {
            const newState = { ...state };
            const usernames: { [key: string]: SafeswimUsername } = {};

            action.payload?.forEach((u: SafeswimUsername) => {
                usernames[u.id] = u;
            });
            newState.usernames = usernames;

            return newState;
        }

        case Types.USER_ROLES_RECEIVED: {
            return {
                ...state,
                currentUserRoles: action.payload,
            };
        }

        default:
            return state;
    }
}