import { Types } from "../actions/auth";

const initialState = {
    user: null,
};

export default function auth(state = initialState, action: any) {
    switch (action.type) {
        case Types.LOCAL_USER_RECEIVED: {
            return {
                user: action.payload
            };
        }

        case Types.LOG_OUT: {
            return initialState;
        }

        default:
            return state;
    }
};