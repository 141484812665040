export function formatDate(date: string) {
    return new Date(date).toLocaleTimeString("en-NZ", {
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
    });
}

export function isWithinFourHours(to: string) {
    const dateOne: any = new Date();
    const dateTwo: any = new Date(to);
    const difference = Math.ceil((dateTwo - dateOne) / 3600000); //Divide by 1000 * 60 * 60 to get hours from ms
    return difference <= 4;
}

export const compareDates = (a: Date, b: Date) => {
    return b.getTime() - a.getTime();
};
