import Alert, { AlertState } from "../../types/Alert";
import AlertType, { ALERT_TYPE } from "../../types/AlertType";
import { Types } from "../actions/alerts";

const initialState: {
    alerts: {
        items: { [key: string]: Alert };
        cursor: string | null;
    },
    alertTypes: {
        allTypes: { [key: string]: AlertType };
        hazardTypes: { [key: string]: AlertType };
        waterTypes: { [key: string]: AlertType };
    },
    alertStates: {
        [alertId: number]: Array<AlertState>;
    }
} = {
    alerts: {
        items: {},
        cursor: null
    },
    alertTypes: {
        allTypes: {},
        hazardTypes: {},
        waterTypes: {}
    },
    alertStates: {}
};

export default function alerts(state = initialState, action: any) {
    switch (action.type) {
        case Types.ALERT_RECEIVED: {
            const newState = { ...state };
            const alertItems = { ...state.alerts.items };
            const alert: Alert = action.payload;

            alertItems[alert.id] = alert;

            newState.alerts.items = alertItems;

            return newState;
        }

        case Types.ALERTS_RECEIVED: {
            const { items, cursor } = action.payload;
            const alertItems: { [key: string]: Alert } = {};
            const newState = { ...state };

            items.forEach((alert: Alert) => {
                alertItems[alert.id] = alert;
            });

            if (!newState.alerts) {
                newState.alerts = { items: {}, cursor: "" };
            }

            newState.alerts.items = alertItems;
            newState.alerts.cursor = cursor;

            return newState;
        }

        case Types.ALERT_TYPES_RECEIVED: {
            const newState = { ...state };

            action.payload.forEach((type: AlertType) => {
                newState.alertTypes.allTypes[type.id] = type;
                if (type.alertType === ALERT_TYPE.WATER_QUALITY) {
                    newState.alertTypes.waterTypes[type.id] = type;
                } else if (type.alertType === ALERT_TYPE.HAZARDS) {
                    newState.alertTypes.hazardTypes[type.id] = type;
                }
            });

            return newState;
        }

        case Types.ALERT_STATES_RECEIVED: {
            const alertStates = action.payload as AlertState[];
            const newState = { ...state };
            if (alertStates?.length) {
                const alertId = alertStates[0].alertId;
                newState.alertStates[alertId] = alertStates;
            }
            return newState;
        }

        default:
            return state;
    }
};
