import React from "react";
import { Field, useField, useFormikContext } from "formik";
import { InputLabel } from "@material-ui/core";
import styles from "./formikCheckboxGroup.module.scss";

type Props = {
    name: string;
    values: { label: string, value: string, riskValue?: string, description?: string }[];
    type: "radio" | "checkbox";
    label?: string;
    containerClassName?: string;
    disabled?: boolean;
}

export default function FormikCheckboxGroup(props: Props) {
    const { name, values, label, containerClassName, disabled, type } = props;
    const [, meta] = useField(name);
    const { isSubmitting } = useFormikContext();
    const showError = meta.touched && !!meta.error;
    const error = showError ? meta.error : null;
    const dis = disabled || isSubmitting;

    const renderInputs = () => {
        return values.map((item) => (
            <label key={item.value} className={styles.label}>
                <div className={styles.checkbox_label_wrapper}>
                    <Field name={name} type={type} value={item.value} disabled={dis} />
                    <div className={styles.checkbox_helper}>
                        <span>
                            {item.label + (item.riskValue ? ` - ${item.riskValue}` : "")}
                        </span>
                        {item.description && (
                            <span className={styles.checkbox_description}>
                                {item.description}
                            </span>
                        )}
                    </div>
                </div>
            </label>
        ));
    };

    return (
        <div className={containerClassName}>
            <InputLabel shrink htmlFor={""}>{label}</InputLabel>
            <div className={styles.checkbox_container}>
                {renderInputs()}
            </div>
            {showError && error && <div className={styles.error}>{error}</div>}
        </div>
    );
}
