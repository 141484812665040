import createAction from "../helpers/createAction";
import ListResult from "../../types/ListResult";
import Tag from "../../types/Tag";
import TagsAPI from "./api/Tags";
import wrapper from "../helpers/wrapper";

export enum Types {
    TAG_RECEIVED = "TAG_RECEIVED",
    TAGS_RECEIVED = "TAGS_RECEIVED",
}

export const setTagReceived = createAction<Tag>(Types.TAG_RECEIVED);
export const setTagsReceived = createAction<ListResult<Tag>>(Types.TAGS_RECEIVED);

export const getTags = wrapper(TagsAPI.getTags, {
    action: setTagsReceived
})

export const createTag = wrapper(TagsAPI.createTag, {
    action: setTagReceived
})

export const updateTag = wrapper(TagsAPI.updateTag, {
    action: setTagReceived
})

export const deleteTag = wrapper(TagsAPI.deleteTag, {
    action: setTagsReceived
})
