import React, { useMemo } from "react";
import PageContainer from "../widgets/pageContainer/PageContainer";
import routes from "../../constants/routes";
import { Redirect, Route, Switch } from "react-router-dom";
import CurrentAlerts from "./current/CurrentAlerts";
import PastAlerts from "./past/PastAlerts";
import CreateUpdateAlert from "./current/CreateUpdateAlert";
import useAdminState from "hooks/useAdminState";

export default function Alerts() {

    const { isRegionalAdmin, isSuperAdmin, isWatercare, isSLS } = useAdminState();
    const navItems = useMemo(() => {
        let navs:Array<{text: string, path: string}> = [];
        let rts: Array<{ path: string, exact?: boolean, component: any }> = []
        if (isWatercare || isRegionalAdmin || isSLS) {
            navs = [
                { text: "Current Alerts", path: routes.alerts.current },
                { text: "Past Alerts", path: routes.alerts.past },
            ];
            rts = [
                { path: routes.alerts.current, exact: true, component: CurrentAlerts },
                { path: routes.alerts.past, exact: true, component: PastAlerts },
                { path: routes.alerts.createCurrentAlert, component: CreateUpdateAlert },
            ];
        } else if (isSuperAdmin) {
            navs = [
                { text: "Current Alerts", path: routes.alerts.current },
                { text: "Past Alerts", path: routes.alerts.past },
            ];
            rts = [
                { path: routes.alerts.current, exact: true, component: CurrentAlerts },
                { path: routes.alerts.past, exact: true, component: PastAlerts },
                { path: routes.alerts.createCurrentAlert, component: CreateUpdateAlert },
            ];
        } else {
            // unauthed request
        }
        return {navs, rts};
    }, [isRegionalAdmin, isSuperAdmin, isWatercare, isSLS]);

    return (
        <PageContainer navItems={navItems.navs}>
            <Switch>
                {navItems.rts.map((r, i) => (
                    <Route key={i} exact={r.exact} path={r.path} component={r.component} />
                ))}
                <Redirect from={routes.alerts.root} to={routes.alerts.current} />

            </Switch>
        </PageContainer>
    );
}
