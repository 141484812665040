import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useSelector from "../../../hooks/useSelector";
import Popover, { PopoverRef } from "components/widgets/popover/Popover";
import Button from "components/widgets/button/Button";
import useMountEffect from "../../../hooks/useMountEffect";
import Options from "../../../resources/images/svg/options.svg";
import styles from "../../alerts/current/currentAlerts.module.scss";
import Table from "../../widgets/table/Table";
import { useHistory } from "react-router-dom";
import routes from "../../../constants/routes";
import Facility from "../../../types/Facility";
import { deleteFacility, getFacilities } from "../../../redux/actions/facilities";
import WarningDialog from "../../widgets/dialog/warningDialog/WarningDialog";
import { DialogRef } from "components/widgets/dialog/Dialog";
import { showError, showSuccess } from "../../../redux/actions/snackbars";


export default function LocationFacilities() {
    const [loading, setLoading] = useState<boolean>(false);
    const [editingFacility, setEditingFacility] = useState<Facility | null>(null);
    const [deleting, setDeleting] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const { items }: any = useSelector(state => state.facilities);
    const deleteDialog = useRef<DialogRef>(null);
    const popover = useRef<PopoverRef>(null);

    useMountEffect(async () => {
        if (!items?.length) {
            setLoading(true);
            try {
                await dispatch(getFacilities());
            } catch (e) {
                dispatch(showError("Error retrieving facilities"));
            } finally {
                setLoading(false);
            }
        }
    });

    const handleCreateFacility = () => {
        history.push(routes.locations.createFacility);
    };

    const handleDeleteFacility = async () => {
        try {
            setDeleting(true);
            await dispatch(deleteFacility(editingFacility!.id));
            dispatch(showSuccess("Facility successfully deleted"));
            deleteDialog.current?.hide();
        } catch (e) {
            dispatch(showError("Error deleting facility"));
        } finally {
            setDeleting(false);
        }
    };

    const handleEditFacility = (facility: Facility | null) => {
        facility && history.push(`${routes.locations.createFacility}?editing=${facility.id}`);
    };

    const onShowPopover = (facilities: Facility) => (e: any) => {
        setEditingFacility(facilities);
        popover.current?.show(e.currentTarget);
    };

    const columns = [
        {
            title: "Facility name",
            field: "title"
        },
        {
            title: "Description",
            field: "description"
        },
        {
            field: "",
            render: (facilities: Facility) => (
                <div style={{ textAlign: "right" }}>
                    <Button plain onClick={onShowPopover(facilities)}><img src={Options} alt={"Options"} /></Button>
                    <Popover popoverRef={popover} className={styles.popover}
                        anchorOrigin={{ horizontal: "right", vertical: "center" }}
                        transformOrigin={{
                            vertical: "center",
                            horizontal: "left",
                        }}>
                        <Button plain onClick={() => {
                            popover.current?.hide();
                            handleEditFacility(editingFacility);
                        }}>View/Edit</Button>
                        <Button plain onClick={() => {
                            popover.current?.hide();
                            deleteDialog.current?.show();
                        }}>Delete</Button>
                    </Popover>
                </div>
            )
        },
    ];

    return (
        <>
            <Table data={Object.values(items) as any}
                columns={columns}
                loading={loading}
                heading={"Location Facilities"}
                actionButton={{ text: "Create facility", action: handleCreateFacility }}
            />
            <WarningDialog title={"Delete facility"}
                positiveText={"Delete facility"}
                onPositivePress={handleDeleteFacility}
                negativeText={"Cancel"}
                onNegativePress={deleteDialog.current?.hide}
                loading={deleting}
                ref={deleteDialog}>
                <span>{`Are you sure you would like to delete ${editingFacility?.title}`}</span>
                <span style={{ fontWeight: "bold" }}>This action can not be undone and will be removed from all locations using this facility.</span>
            </WarningDialog>
        </>
    );
}
