import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useMountEffect from "../../hooks/useMountEffect";
import useSelector from "../../hooks/useSelector";
import Popover, { PopoverRef } from "components/widgets/popover/Popover";
import Button from "components/widgets/button/Button";
import Options from "../../resources/images/svg/options.svg";
import styles from "../alerts/current/currentAlerts.module.scss";
import Table from "../widgets/table/Table";
import SpecialEvent from "../../types/SpecialEvent";
import { deleteEvent, getEvents } from "../../redux/actions/specialEvents";
import { useHistory } from "react-router-dom";

export default function EventsOverview() {
    const [loading, setLoading] = useState<boolean>(false);
    const [editingEvents, setEditingEvents] = useState<SpecialEvent | null>(null);
    const dispatch = useDispatch();
    const specialEvents: any = useSelector(state => state.specialEvents);
    const history = useHistory();
    const [showDeleted, setShowDeleted] = useState<boolean>(false);
    const popover = useRef<PopoverRef>(null);

    useMountEffect(async () => {
        if (!specialEvents?.items?.length) {
            setLoading(true);
            try {
                await dispatch(getEvents());
            } catch (e) {
                console.log("specialEvents err", e);
            } finally {
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        if (history.location.pathname.includes("deleted")) {
            setShowDeleted(true);
        } else {
            setShowDeleted(false);
        }

    }, [history.location.pathname]);

    const handleCreateEvents = () => {
        history.push("/events/create");
    };

    const handleEditEvent = (event: number) => {
        history.push(`/events/create?editing=${event}`);
    };

    const handleDeleteEvent = async (event: number) => {
        await dispatch(deleteEvent(event));
        setLoading(true);
        await dispatch(getEvents());
        setLoading(false);
    };

    const onShowPopover = (users: SpecialEvent) => (e: any) => {
        setEditingEvents(users);
        popover.current?.show(e.currentTarget);
    };

    const columns = [
        {
            title: "Event name",
            field: "name"
        },
        {
            title: "Description",
            field: "description"
        },
        {
            title: "From",
            field: "start"
        },
        {
            title: "To",
            field: "end"
        },
        {
            title: "Status",
            field: "eventStatus"
        },

        {
            field: "",
            render: (specialEvents: SpecialEvent) => (
                <div style={{ textAlign: "right" }}>
                    {!showDeleted &&
                    <Button plain onClick={onShowPopover(specialEvents)}><img src={Options} alt={"Options"} /></Button>
                    }
                    <Popover popoverRef={popover} className={styles.popover}
                             anchorOrigin={{ horizontal: "right", vertical: "center" }}
                             transformOrigin={{
                                 vertical: "center",
                                 horizontal: "left",
                             }}>
                        <Button plain onClick={() => {
                            popover.current?.hide();
                            handleEditEvent(editingEvents!.id);
                        }}>View/Edit</Button>

                        <Button plain onClick={() => {
                            popover.current?.hide();
                            handleDeleteEvent(editingEvents!.id);
                        }}>Delete</Button>

                    </Popover>
                </div>
            )
        },

    ];

    const parseEvents = () => {
        const l: any[] = [];

        if (!specialEvents) {
            return l;
        }
        Object.values(specialEvents).map((lo: any) => {
            if (lo && lo.deleted === showDeleted) {
                l.push({
                    ...lo,
                    start: new Date(lo.start).toLocaleTimeString("en-NZ", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit"
                    }),
                    end: new Date(lo.end).toLocaleTimeString("en-NZ", {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric",
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit"
                    }),
                    description: <div dangerouslySetInnerHTML={{ __html: lo.description }} />
                });
            }
            return null;
        });

        return l;
    };


    return (
        <Table data={parseEvents()}
               columns={columns}
               loading={loading}
               heading={"Special Events"}
               actionButton={{ text: "Create event", action: handleCreateEvents }}
        />
    );
}
