import get from "./util/get";
import put from "./util/put";
import post from "./util/post";
import H2Location from "../../../types/H2Location";
import DigitalSignDTO from "../../../types/DigitalSignDTO";
import del from "./util/del";
import SafeswimLocation from "../../../types/SafeswimLocation";
import DigitalSignStatusDTO from "types/DigitalSignStatus";
import { SafeswimTrace } from "../../../types/Trace";

function createLocation(location: SafeswimLocation): Promise<SafeswimLocation[]> {
    return post("v1/admin/locations", location);
}

function getLocation(id: number): Promise<SafeswimLocation> {
    return get(`v1/locations/${id}`);
}

function updateLocation(id: number, location: SafeswimLocation): Promise<SafeswimLocation[]> {
    return put(`v1/admin/locations/${id}`, location);
}

function updateLocations( locations: SafeswimLocation[]){
    return put(`v1/admin/locations`, locations)
}

function deleteLocation(id: number): Promise<SafeswimLocation[]> {
    return del(`v1/admin/locations/${id}`);
}

function getLocations(): Promise<SafeswimLocation[]> {
    return get("v1/admin/locations");
}

function getH2Locations(): Promise<H2Location[]> {
    return get("v1/admin/moata/locations");
}

function setLocationUnavailable(id: number): Promise<SafeswimLocation[]> {
    return put(`v1/admin/locations/unavailable/${id}`);
}

function setLocationAvailable(id: number): Promise<SafeswimLocation[]> {
    return put(`v1/admin/locations/available/${id}`);
}

function createOrUpdateDigitalSign(digitalSign: DigitalSignDTO): Promise<DigitalSignDTO[]> {
    return post("v1/admin/signs", digitalSign);
}

function getDigitalSigns(): Promise<DigitalSignDTO[]> {
    return get(`v1/admin/signs`);
}

function deleteDigitalSign(id: number): Promise<DigitalSignDTO[]> {
    return del(`v1/admin/signs/${id}`);
}

function updateSLSPatrolHours(id: number, location: SafeswimLocation): Promise<SafeswimLocation[]> {
    return put(`v1/admin/locations/patrolHours/${id}`, location);
}

function getAvailableRegionBased(): Promise<string[]> {
    return get('v1/locations/region-based/available')
}

function getDigitalSignStatus(): Promise<DigitalSignStatusDTO[]> {
    return get('/v1/admin/signs/status')
}

async function getLocationTraces(locationId: number) {
    return get(`v1/admin/traces/${locationId}`);
}

async function getMoataLocationTraces(locationId: number) {
    return get(`v1/admin/moata/traces/${locationId}`);
}

async function updateLocationTraces(locationId: number, traces: SafeswimTrace[]) {
    return put(`v1/admin/traces/${locationId}`, traces);
}

const LocationAPI = {
    createLocation,
    getLocation,
    updateLocation,
    updateLocations,
    deleteLocation,
    getLocations,
    getH2Locations,
    setLocationUnavailable,
    setLocationAvailable,
    createOrUpdateDigitalSign,
    getDigitalSigns,
    deleteDigitalSign,
    updateSLSPatrolHours,
    getAvailableRegionBased,
    getDigitalSignStatus,
    getLocationTraces,
    getMoataLocationTraces,
    updateLocationTraces
}

export default LocationAPI;
