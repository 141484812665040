import React, { useState } from "react";
import * as Yup from "yup";
import FormikInput from "components/widgets/input/FormikInput";

import { Form, Formik } from "formik";
import Button from "components/widgets/button/Button";

import { useHistory } from "react-router-dom";
import styles from "./login.module.scss";
import firebase from "firebase/app";
import "firebase/auth";
import { useDispatch } from "react-redux";
import { showError } from "../../redux/actions/snackbars";

type LoginFormValues = {
    email: string;
    password: string;
};

export default function Login() {
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSubmit = async (values: LoginFormValues) => {
        setLoading(true);
        try {
            await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
        } catch (e: any) {
            dispatch(showError(e.message));
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <Formik
                initialValues={{
                    email: "",
                    password: ""
                }}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                    email: Yup.string().trim().email("Email address is not valid").required("Required"),
                    password: Yup.string().required("Required")
                })}>
                <Form className={styles.form}>
                    <h3>Login</h3>
                    <FormikInput name={"email"}
                                 label={"email"} />
                    <FormikInput name={"password"}
                                 type={"password"}
                                 label={"password"} />

                    <Button variant="contained" loading={loading} type={"submit"} disabled={loading}>Login</Button>
                    <Button onClick={() => history.push("/forgot-password")}>Forgot Password</Button>

                </Form>
            </Formik>
        </div>
    );
}