import SafeswimUser from "../../types/SafeswimUser";
import createAction from "../helpers/createAction";
import UsersAPI from "./api/Users";
import wrapper from "../helpers/wrapper";
import SafeswimUsername from "../../types/SafeswimUsername";

export enum Types {
    USER_RECEIVED = "USER_RECEIVED",
    USERS_RECEIVED = "USERS_RECEIVED",
    USERNAMES_RECEIVED = "USERNAMES_RECEIVED",
    USER_ROLES_RECEIVED = "USER_ROLES_RECEIVED",
}

export const setUserReceived = createAction<SafeswimUser>(Types.USER_RECEIVED);
export const setUsersReceived = createAction<SafeswimUser[]>(Types.USERS_RECEIVED);
export const setUsernamesReceived = createAction<SafeswimUsername[]>(Types.USERNAMES_RECEIVED);
export const setUserRolesReceived = createAction(Types.USER_ROLES_RECEIVED);

export const getUsers = wrapper(UsersAPI.getUsers, {
    action: setUsersReceived
});

export const getUsernames = wrapper(UsersAPI.getUsernames, {
    action: setUsernamesReceived
});

export const getUser = wrapper(UsersAPI.getUser, {
    action: setUserReceived
});

export const createUser = wrapper(UsersAPI.createUser, {
    action: setUserReceived
});

export const updateUser = wrapper(UsersAPI.updateUser, {
    action: setUserReceived
});

export const deleteUser = wrapper(UsersAPI.deleteUser, {
    action: setUsersReceived
});

export const enableUser = wrapper(UsersAPI.enableUser, {
    action: setUsersReceived
});

export const getUserRoles = wrapper(UsersAPI.getRoles, {
    action: setUserRolesReceived
});
