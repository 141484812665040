import React, { useRef, useState } from "react";
import useSelector from "../../../hooks/useSelector";
import SafeswimLocation from "../../../types/SafeswimLocation";
import useMountEffect from "../../../hooks/useMountEffect";
import useDispatch from "../../../hooks/useDispatch";
import { getLocations } from "../../../redux/actions/locations";
import Spinner from "../../widgets/spinner/Spinner";
import styles from "./PatrolHours.module.scss";
import {
    deleteAllPatrols,
    deletePatrolHours,
    getPatrolHours,
    updatePatrolHours,
    uploadPatrolHours
} from "../../../redux/actions/api/patrolHours";
import { PatrolHour } from "../../../types/PatrolHour";
import { showError } from "../../../redux/actions/snackbars";
import Table from "../../widgets/table/Table";
import orderBy from "lodash/orderBy";
import { format } from "date-fns";
import { EditComponentProps } from "@material-table/core";
import DateTimePicker from "../../widgets/pickers/DateTimePicker";
import Button from "../../widgets/button/Button";

type ColumnType =
    | "string"
    | "boolean"
    | "numeric"
    | "date"
    | "datetime"
    | "time"
    | "currency";

const date: ColumnType = "date";

const EditTimePicker = (props: EditComponentProps<PatrolHour>) => (
    <DateTimePicker format={"HH:mm"} openTo={"hours"}
                    value={props.value}
                    onChange={(value) => {
                        const date = value.toJSDate();
                        date.setMinutes(Math.ceil(date.getMinutes() / 30) * 30);
                        date.setSeconds(0);
                        date.setMilliseconds(0);
                        props.onChange(format(date, "HH:mm"));
                    }} error={props.error} />
);

const columns = [
    {
        title: "Date",
        field: "date",
        type: date,
        locale: "en-NZ"
    },
    {
        title: "From",
        field: "from",
        editComponent: EditTimePicker
    },
    {
        title: "To",
        field: "to",
        editComponent: EditTimePicker
    }
];

export default function PatrolHours() {
    const dispatch = useDispatch();
    const locations = useSelector<SafeswimLocation[]>((s) => s.locations.locations);
    const [location, setLocation] = useState<SafeswimLocation | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingPatrols, setLoadingPatrols] = useState<boolean>(false);
    const [patrols, setPatrols] = useState<PatrolHour[]>([]);
    const fileRef = useRef<HTMLInputElement>(null);

    useMountEffect(async () => {
        if (!locations || !locations.length) {
            await dispatch(getLocations());
        }
        setLoading(false);
    });

    const onLocationChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        try {
            const locationId = Number(e.target.value);
            const location = locations.filter((l) => l.id === locationId)[0];
            setLocation(location);
            setLoadingPatrols(true);
            const patrols = await getPatrolHours(locationId);
            setPatrols(patrols);
        } catch (e: any) {
            dispatch(showError(e.message));
        } finally {
            setLoadingPatrols(false);
        }
    };

    if (loading) {
        return <Spinner center />;
    }

    const onImportClick = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };

    const onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;

        if (!files) {
            return;
        }

        try {
            setLoadingPatrols(true);
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append("csv", file);
            }
            await uploadPatrolHours(location!.id, formData);
            const patrols = await getPatrolHours(location!.id);
            setPatrols(patrols);
        } catch (e: any) {
            dispatch(showError(e.response.data.message));
        } finally {
            setLoadingPatrols(false);
            e.target.files = null;
        }
    };

    const onDeleteAllClick = async () => {
        if (window.confirm("Are you sure you want to delete? This action cannot be undone.")) {
            try {
                setLoadingPatrols(true);
                await deleteAllPatrols(location!.id);
                setPatrols([]);
            } catch (e: any) {
                dispatch(showError(e.response.data.message));
            } finally {
                setLoadingPatrols(false);
            }
        }
    };

    const onRowAdd = async (patrol: PatrolHour) => {
        try {
            setLoadingPatrols(true);
            const patrols: PatrolHour[] = [];
            patrols.push({
                ...patrol,
                date: format(new Date(patrol.date), "yyyy-MM-dd"),
                location: location!.id
            });
            const updatedPatrols = await updatePatrolHours(location!.id, patrols);
            setPatrols(updatedPatrols);
        } catch (e: any) {
            dispatch(showError(e.response.data.message));
        } finally {
            setLoadingPatrols(false);
        }
    };

    const onRowDelete = async (patrol: PatrolHour) => {
        try {
            setLoadingPatrols(true);
            await deletePatrolHours(location!.id, patrol.id);
            const patrols = await getPatrolHours(location!.id);
            setPatrols(patrols);
        } catch (e: any) {
            dispatch(showError(e.response.data.message));
        } finally {
            setLoadingPatrols(false);
        }
    };

    const onBulkUpdate = async (changes: Record<number, { newData: any, oldData: any }>) => {
        try {
            setLoadingPatrols(true);
            const patrols: PatrolHour[] = [];
            Object.keys(changes).forEach((k) => {
                const o = changes[Number(k)];
                patrols.push({
                    ...o.newData,
                    date: format(new Date(o.newData.date), "yyyy-MM-dd")
                });
            });
            const updatedPatrols = await updatePatrolHours(location!.id, patrols);
            setPatrols(updatedPatrols);
        } catch (e: any) {
            dispatch(showError(e.response.data.message));
        } finally {
            setLoadingPatrols(false);
        }
    };

    return (
        <div className={styles.patrol_hours}>
            <div className={styles.location_select}>
                <label>Select location</label>
                <select onChange={onLocationChange}>
                    <option />
                    {orderBy(locations, ["name"]).map((l: SafeswimLocation) => <option key={l.id} value={l.id}>{l.name}</option>)}
                </select>
            </div>
            <div className={styles.button_group}>
                <Button variant={"contained"} disabled={!location || loadingPatrols} onClick={onImportClick}>
                    Import csv
                </Button>
                <Button variant={"contained"} disabled={!location || loadingPatrols} red onClick={onDeleteAllClick}>
                    Delete all
                </Button>
            </div>
            <input ref={fileRef} type={"file"} hidden accept={"text/csv"} onChange={onFileUpload} multiple={false} />
            <Table columns={columns}
                   data={orderBy(patrols, ["date"])}
                   editable={{
                       onRowAdd,
                       onRowDelete,
                       onBulkUpdate
                   }}
                   options={{ toolbar: true, addRowPosition: "first" }}
                   title={location ? `${location?.name} patrol times` : ""}
                   loading={loadingPatrols} />
        </div>
    );
}
