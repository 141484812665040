import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useHistory } from 'react-router-dom';
import styles from "./pageContainer.module.scss";
import { USER_ROLES } from "../../../types/SafeswimUser";

type Props = {
    navItems?: NavItem[],
    children: React.ReactNode
};

export type NavItem = {
    text: string,
    path: string,
    permissions?: USER_ROLES[],
}

export default function PageContainer(props: Props) {
    const history = useHistory();

    const onNavigate = (path: string) => () => {
        history.push(path);
    }

    const renderNavItem = (navItem: NavItem) => (
        <MenuItem onClick={onNavigate(navItem.path)}
                  key={navItem.path}
                  selected={history.location.pathname.includes(navItem.path)}
                  classes={{ root: styles.menu_item, selected: styles.menu_item_selected }}>{navItem.text}</MenuItem>
    )

    return (
        <div className={styles.container}>
            <MenuList classes={{ root: styles.tab_navigation }}>
                {props.navItems?.map(renderNavItem)}
            </MenuList>
            <div className={styles.content_container}>
                {props.children}
            </div>
        </div>
    );
}
