import { useCallback, useRef, useState } from "react";
import DigitalSignStatusDTO from "types/DigitalSignStatus";
import LocationAPI from "redux/actions/api/Locations";
import useDispatch from "hooks/useDispatch";
import PagingTable, {
    PagingTableRef,
} from "components/widgets/table/PagingTable";
import { showError } from "redux/actions/snackbars";
import { Column } from "@material-table/core";
import ListResult from "types/ListResult";
import { format } from "date-fns";
import useSelector from "hooks/useSelector";
import SafeswimLocation from "types/SafeswimLocation";
import useMountEffect from "hooks/useMountEffect";
import { getLocations } from "redux/actions/locations";

export default function DigitalSignStatus() {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const tableRef = useRef<PagingTableRef>(null);
    const locations = useSelector(
        (state) => state.locations.locations
    ) as SafeswimLocation[];

    useMountEffect(async () => {
        setLoading(true);
        try {
            if (!locations || !locations.length) {
                await dispatch(getLocations());
            }
        } catch (error: any) {
            dispatch(showError(error.message));
        } finally {
            setLoading(false);
        }
    });

    const getData = useCallback(async (): Promise<
        ListResult<DigitalSignStatusDTO>
    > => {
        try {
            setLoading(true);
            const status = await LocationAPI.getDigitalSignStatus();
            const result: { [locationId: number]: DigitalSignStatusDTO } = {};
            status.forEach((s) => {
                const { locationId } = s;
                const _updatedAt = result[locationId]?.updatedAt ?? s.updatedAt;
                result[locationId] = {
                    ...(result[locationId] ?? {}),
                    ...s,
                    updatedAt:
                        new Date(_updatedAt) > new Date(s.updatedAt)
                            ? _updatedAt
                            : s.updatedAt,
                };
            });
            setLoading(false);
            return Promise.resolve({
                items: Object.values(result),
                cursor: "",
            });
        } catch (error: any) {
            dispatch(showError(error.message));
            return Promise.resolve({ items: [], cursor: "" });
        }
    }, [dispatch]);

    const columns: Column<DigitalSignStatusDTO>[] = [
        {
            title: "Location",
            render: (s) =>
                locations?.find((l) => l.id === s.locationId)?.name ??
                "Unknown",
            customSort: (a: DigitalSignStatusDTO, b: DigitalSignStatusDTO) => {
                const locationA = locations?.find((l) => l.id === a.locationId);
                const locationB = locations?.find((l) => l.id === b.locationId);
                if (locationA && locationB)
                    return locationA.name.localeCompare(locationB.name);
                return 0;
            },
        },
        {
            title: "Door status",
            render: (s) => (s.isDoorOpen ? "Open" : "Close"),
        },
        {
            title: "Impact status",
            render: (s) => (s.isImpacted ? "Impacted" : "Not detected"),
        },
        {
            title: "Fan status",
            render: (s) =>
                JSON.stringify(s.fanStatus ?? {})
                    .replace(/[(^{)|(}$)]/g, "")
                    .replace(/,/g, ",\n"),
        },
        {
            title: "Backlight",
            render: (s) => s.backlight,
        },
        {
            title: "Light Sensor",
            render: (s) => s.lightSensor,
        },
        {
            title: "Internal Temp",
            render: (s) => s.internalTemp,
        },
        {
            title: "External Temp",
            render: (s) => s.externalTemp,
        },
        {
            title: "Humidity",
            render: (s) => s.humidity,
        },
        {
            title: "Fan Active Temp",
            render: (s) => s.fanActiveTemp,
        },
        {
            title: "Voltage Status",
            render: (s) =>
                JSON.stringify(s.voltageStatus ?? {})
                    .replace(/[(^{)|(}$)]/g, "")
                    .replace(/,/g, ",\n"),
        },
        {
            title: "Updated at",
            render: (s) =>
                format(new Date(s.updatedAt), "dd/MM/yyyy hh:mm:ss a"),
        },
        {
            title: "Errors",
            render: (s) => (s.errors.length ? s.errors.join(", ") : "-"),
        },
    ];

    const refresh = useCallback(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    return (
        <PagingTable<DigitalSignStatusDTO>
            tableRef={tableRef}
            columns={columns}
            heading={"Digital Sign Status"}
            getData={getData}
            loading={loading}
            actionButton={{ text: "Refresh", action: refresh }}
        />
    );
}
