import useSelector from "./useSelector";
import SafeswimUser, { USER_ROLES } from "../types/SafeswimUser";

export default function useAdminState() {
    const user = useSelector<SafeswimUser>(state => state.auth.user);

    const isSuperAdmin = user.role === USER_ROLES.SUPER_ADMIN;
    const isRegionalAdmin = user.role === USER_ROLES.REGIONAL_ADMIN;
    const isWatercare = user.role === USER_ROLES.WATERCARE;
    const isSLS = user.role === USER_ROLES.SLS;
    const assignedRegions = user.assignedRegions??[];
    return { isSuperAdmin, isRegionalAdmin, isWatercare, assignedRegions, isSLS };
}