import React, { useEffect, useState } from "react";
import styles from "./locationOrdering.module.scss";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortEnd,
    SortEvent
} from "react-sortable-hoc";
import Button from "components/widgets/button/Button";
import { useDispatch } from "react-redux";
import useSelector from "../../../hooks/useSelector";
import SafeswimLocation from "../../../types/SafeswimLocation";
import useMountEffect from "../../../hooks/useMountEffect";
import arrowMoveable from "../../../resources/images/arrowMoveable@2x.png";
import { getLocations, updateLocations } from "../../../redux/actions/locations";
import Spinner from "components/widgets/spinner/Spinner";

export default function LocationOrdering() {
    const dispatch = useDispatch();
    const [editable, setEditable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [locations, setLocations] = useState([]);
    const [snapshot, setSnapshot] = useState([]);
    const slsLocations = useSelector(state => state.locations.locations);

    useMountEffect(async () => {
        setLoading(true);
        setLocations([]);
        await dispatch(getLocations());
        setLoading(false);
    });

    useEffect(() => {
        if (locations.length === 0 && slsLocations !== null) {
            setLocations(slsLocations);
        }
    }, [slsLocations, locations]);

    const SortableItem = SortableElement(((location: SafeswimLocation) => (
        <li className={(editable ? styles.sortable_row_editable : styles.sortable_row)}>
            {editable && (
                <img src={arrowMoveable} width={20} style={{ marginRight: 5, alignSelf: "center" }} alt={""} />
            )}
            <span className={styles.column}>{location.name}</span>
            <span className={styles.column}>{location.region}</span>
            <span className={styles.column}>{location.lat}</span>
            <span className={styles.column}>{location.lng}</span>
        </li>
    )));

    const SortableList = SortableContainer(({ items }: { items: SafeswimLocation[] }) => {
        return (
            <ul className={styles.sortable_container}>
                {(items).map((location: SafeswimLocation, index: number) => (
                    <SortableItem disabled={!editable} index={index} {...location} key={`item-${location.id}`} />
                ))}
            </ul>
        );
    });

    const onSortEnd = ((sort: SortEnd, event: SortEvent) => {
        const newLocations = arrayMove(locations, sort.oldIndex, sort.newIndex);
        setLocations(newLocations);
    });

    const onEditPress = () => {
        setEditable(true);
        setSnapshot(locations);
    };

    const onSaveOrderPress = () => {
        const saveLocations: any[] = locations.map((l: SafeswimLocation, i: number) => ({
            id: l.id,
            ordinal: i
        }));

        dispatch(updateLocations(saveLocations));
        setEditable(false);
    };

    const onCancelPress = () => {
        setLocations(snapshot);
        setEditable(false);
    };

    if (loading) {
        return <Spinner center />;
    }

    return (
        <>
            <div className={styles.page_header}>
                <h1>Custom Location Ordering</h1>
                {!editable && (
                    <Button onClick={onEditPress} style={{ marginLeft: 10 }} variant="contained">Edit order</Button>
                )}
                {editable && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button onClick={onCancelPress} style={{ margin: 10 }}>Cancel</Button>
                        <Button onClick={onSaveOrderPress} style={{ marginLeft: 10 }} variant="contained">
                            Save order
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.table_header}>
                <span className={styles.column_header}>Location name</span>
                <span className={styles.column_header}>Region</span>
                <span className={styles.column_header}>Latitude</span>
                <span className={styles.column_header}>Longitude</span>
            </div>
            <SortableList onSortEnd={((sort, event) => onSortEnd(sort, event))} items={locations} />
        </>
    );
}

