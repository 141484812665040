import { FieldValidator } from "formik";
import useFormikField, { FormikFieldExtraProps } from "hooks/useFormikField";
import React, { ChangeEvent } from "react";
import Input, { InputProps } from "./Input";

export type FormikInputProps = {
    name: string;
    validate?: FieldValidator;
} & Omit<InputProps, "name" | "value" | "error" | "variant"> &
    FormikFieldExtraProps;

export default function FormikInput(props: FormikInputProps) {
    const { name, options, validate, placeholder, ...otherProps } = props;

    const { value, disabled, showError, helperText, field } =
        useFormikField(props);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e);
        field.onChange(e);
    };

    return (
        <Input
            {...otherProps}
            options={options}
            field={field}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            error={showError}
            helperText={helperText}
        />
    );
}
