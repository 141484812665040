import React from "react";
import { Form, Formik } from "formik";
import FormikInput from "components/widgets/input/FormikInput";

import Button from "components/widgets/button/Button";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import styles from "./forgotPassword.module.scss";
import firebase from "firebase/app";
import "firebase/auth";
import { useDispatch } from "react-redux";
import { showError } from "../../redux/actions/snackbars";

type ForgotPasswordValues = {
    email: string;
}

export default function ForgotPassword() {
    const history = useHistory();
    const dispatch = useDispatch();

    const onSubmit = async (values: ForgotPasswordValues) => {
        try {
            await firebase.auth().sendPasswordResetEmail(values.email);
        } catch (e: any) {
            dispatch(showError(e.message));
        }
    };

    return (
        <div className={styles.container}>
            <Formik initialValues={{ email: "" }}
                    validationSchema={Yup.object({ email: Yup.string().email("Email is not valid").required("Required") })}
                    onSubmit={onSubmit}>
                <Form className={styles.form}>
                    <h3>Forgot password</h3>
                    <FormikInput name={"email"}
                                 label={"EMAIL"} />
                    <Button variant={"contained"} type={"submit"}>Reset Password</Button>
                    <Button onClick={() => history.push("/login")}>Back to login</Button>
                </Form>
            </Formik>
        </div>
    );
}