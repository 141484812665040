import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import qs from "qs";
import useMountEffect from "../../../hooks/useMountEffect";
import {
    createAlertType,
    getAlertType,
    updateAlertType,
} from "../../../redux/actions/alerts";
import styles from "../../locations/facilities/createUpdateFacility.module.scss";
import Spinner from "components/widgets/spinner/Spinner";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "components/widgets/button/Button";
import FormikInput from "components/widgets/input/FormikInput";

import { showSuccess } from "../../../redux/actions/snackbars";
import useDispatch from "hooks/useDispatch";
import AlertType, { AlertTypeDTO } from "types/AlertType";
import FormikFileUpload from "components/widgets/formikFields/formikFileUpload/FormikFileUpload";

type FormValues = {
    title: string;
    value: string;
    description: string;
    slsId?: string;
    imageUrl?: string;
};

export default function CreateUpdateWaterSafetyAlert() {
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [waterSafetyAlertType, setWaterSafetyAlertType] =
        useState<AlertType>();
    const dispatch = useDispatch();
    const history = useHistory();
    const { editing } = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
    });

    useMountEffect(async () => {
        if (editing) {
            try {
                setLoading(true);
                const alertType = await dispatch(getAlertType(Number(editing)));
                setWaterSafetyAlertType(alertType);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        }
    });

    const onSubmit = async (values: FormValues) => {
        try {
            setSubmitting(true);
            if (editing) {
                await dispatch(
                    updateAlertType({
                        ...values,
                        id: Number(editing),
                        alertType: "hazards",
                    } as AlertTypeDTO)
                );
                dispatch(showSuccess("Alert saved"));
            } else {
                await dispatch(
                    createAlertType({
                        ...values,
                        alertType: "hazards",
                    } as AlertTypeDTO)
                );
                dispatch(showSuccess("Alert created"));
            }
            history.goBack();
        } catch (e) {
            console.log("sumbit err", e);
        } finally {
            setSubmitting(false);
        }
    };

    const initialValues: FormValues = {
        title: waterSafetyAlertType?.title || "",
        value: waterSafetyAlertType?.value || "",
        description: waterSafetyAlertType?.description || "",
        slsId: waterSafetyAlertType?.slsId ?? "",
        imageUrl: waterSafetyAlertType?.imageUrl || "",
    };

    return (
        <div className={styles.container}>
            {loading ? (
                <Spinner center />
            ) : (
                <Formik<FormValues>
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object({
                        title: Yup.string().required("Required"),
                        value: Yup.string().required("Required"),
                        description: Yup.string().required("Required"),
                        slsId: Yup.string().nullable(),
                        imageUrl: Yup.string(),
                    })}
                    enableReinitialize
                >
                    {({ handleSubmit, isValid, isSubmitting, dirty }) => {
                        return (
                            <>
                                <div className={styles.header}>
                                    <h1>
                                        {editing
                                            ? "Edit Water Safety Alert Type "
                                            : "Create Water Safety Alert Type "}
                                    </h1>
                                    <div className={styles.header_actions}>
                                        <Button
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant={"contained"}
                                            onClick={() => handleSubmit()}
                                            loading={submitting}
                                            disabled={
                                                !dirty ||
                                                !isValid ||
                                                isSubmitting
                                            }
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                                <FormikInput
                                    name={"title"}
                                    label={"Name"}
                                    className={styles.full_input}
                                />
                                <FormikInput
                                    name={"value"}
                                    label={"Value"}
                                    className={styles.full_input}
                                />
                                <FormikInput
                                    name={"description"}
                                    label={"Description"}
                                    className={styles.full_input}
                                />
                                <FormikInput
                                    name={"slsId"}
                                    label={"SLSNZ id (Optional)"}
                                    className={styles.full_input}
                                />
                                <FormikFileUpload
                                    name={"imageUrl"}
                                    label={"Alert Icon"}
                                    accept={"image/*"}
                                    actionText={
                                        "Image upload \n SVG format 1:1 ratio"
                                    }
                                />
                            </>
                        );
                    }}
                </Formik>
            )}
        </div>
    );
}
