import createAction from "../helpers/createAction";
import SpecialEventsAPI from "./api/SpecialEvents";
import wrapper from "../helpers/wrapper";

export enum Types {
    EVENTS_RECEIVED = "EVENTS_RECEIVED",
    EVENT_RECEIVED = "EVENT_RECEIVED"
}

export const setEventsReceived = createAction(Types.EVENTS_RECEIVED);
export const setEventReceived = createAction(Types.EVENT_RECEIVED)


export const getEvents = wrapper(SpecialEventsAPI.getEvents, {
    action: setEventsReceived
})

export const getEvent = wrapper(SpecialEventsAPI.getEvent, {
    action: setEventReceived
})

export const createEvent = wrapper(SpecialEventsAPI.createEvent, {
    action: setEventsReceived
})

export const updateEvent = wrapper(SpecialEventsAPI.updateEvent, {
    action: setEventsReceived
})

export const deleteEvent = wrapper(SpecialEventsAPI.deleteEvent, {
    action: setEventsReceived
})
